export const main_coins_low = {
  INITIAL_DATE: '2022-01-01',
  INITIAL_WALLET: 10000,
  RETURN: 92.07,
  VOLUME: 3524526.44,
  ORDERS: 2200,
  PAID_FEES: 2643.39,
  MIN_LEVEL: 9,
  MAXIMUM: 21224.5,
  MINIMUM: 7527.83,
  MAX_DRAWDOWN: 27.26,
  SHARPE: 1.73,
  BETA: 0.29,
  AVG_MONTHLY_RETURN: 2.31,
  TOTAL_MONTHS: 33,
  POSITIVE_MONTHS: 20,
  PERC_POSITIVE_MONTHS: 60.61,
  MONTHLY_RETURNS: [
    -5.03, 0.39, 0.89, -5.65, -1.67, -11.19, 18.45, 4.31, 3.88, 7.14, -1.14,
    -6.87, 17.77, -5.02, 7.3, 1.74, -0.84, -1.16, 3.03, -4.42, -3.44, 16.64,
    0.95, 21.34, 3.39, 17.7, 2.91, -7.2, 3.67, 0.01, 2.76, -4.69, 0.25
  ],
  MIN_ALLOCATION: {
    BTC: 0.0,
    ETH: 0.0,
    BNB: 0.31,
    SOL: 0.0,
    XRP: 0.0,
    USDT: 50.52
  },
  AVG_ALLOCATION: {
    BTC: 6.47,
    ETH: 9.71,
    BNB: 2.12,
    SOL: 7.35,
    XRP: 3.23,
    USDT: 71.11
  },
  MAX_ALLOCATION: {
    BTC: 48.92,
    ETH: 48.97,
    BNB: 22.25,
    SOL: 32.64,
    XRP: 24.5,
    USDT: 99.57
  },
  MAX_DRAWDOWN_PER_YEAR: {
    2022: 27.26,
    2023: 15.94,
    2024: 14.37
  },
  POSITIVE_MONTHS_PER_YEAR: {
    2022: {
      TOTAL_MONTHS: 12,
      POSITIVE_MONTHS: 6,
      PERC_POSITIVE_MONTHS: 50.0
    },
    2023: {
      TOTAL_MONTHS: 12,
      POSITIVE_MONTHS: 7,
      PERC_POSITIVE_MONTHS: 58.33
    },
    2024: {
      TOTAL_MONTHS: 9,
      POSITIVE_MONTHS: 7,
      PERC_POSITIVE_MONTHS: 77.78
    }
  },
  LAST_12_MONTHS_RETURN: 64.52,
  LAST_24_MONTHS_RETURN: 88.81,
  AVG_ALLOCATION_PER_YEAR: {
    2022: {
      BTC: 4.22,
      ETH: 13.55,
      BNB: 1.87,
      SOL: 4.2,
      XRP: 3.19,
      USDT: 72.97
    },
    2023: {
      BTC: 7.79,
      ETH: 5.47,
      BNB: 1.9,
      SOL: 10.4,
      XRP: 3.71,
      USDT: 70.71
    },
    2024: {
      BTC: 7.72,
      ETH: 10.24,
      BNB: 2.76,
      SOL: 7.48,
      XRP: 2.63,
      USDT: 69.16
    }
  },
  RETURN_PER_YEAR: {
    2022: 0.33,
    2023: 62.59,
    2024: 18.3
  },
  CHARTS: {
    ALL: {
      TIMESTAMP: [
        '2022-01-01',
        '2022-01-02',
        '2022-01-03',
        '2022-01-04',
        '2022-01-05',
        '2022-01-06',
        '2022-01-07',
        '2022-01-08',
        '2022-01-09',
        '2022-01-10',
        '2022-01-11',
        '2022-01-12',
        '2022-01-13',
        '2022-01-14',
        '2022-01-15',
        '2022-01-16',
        '2022-01-17',
        '2022-01-18',
        '2022-01-19',
        '2022-01-20',
        '2022-01-21',
        '2022-01-22',
        '2022-01-23',
        '2022-01-24',
        '2022-01-25',
        '2022-01-26',
        '2022-01-27',
        '2022-01-28',
        '2022-01-29',
        '2022-01-30',
        '2022-01-31',
        '2022-02-01',
        '2022-02-02',
        '2022-02-03',
        '2022-02-04',
        '2022-02-05',
        '2022-02-06',
        '2022-02-07',
        '2022-02-08',
        '2022-02-09',
        '2022-02-10',
        '2022-02-11',
        '2022-02-12',
        '2022-02-13',
        '2022-02-14',
        '2022-02-15',
        '2022-02-16',
        '2022-02-17',
        '2022-02-18',
        '2022-02-19',
        '2022-02-20',
        '2022-02-21',
        '2022-02-22',
        '2022-02-23',
        '2022-02-24',
        '2022-02-25',
        '2022-02-26',
        '2022-02-27',
        '2022-02-28',
        '2022-03-01',
        '2022-03-02',
        '2022-03-03',
        '2022-03-04',
        '2022-03-05',
        '2022-03-06',
        '2022-03-07',
        '2022-03-08',
        '2022-03-09',
        '2022-03-10',
        '2022-03-11',
        '2022-03-12',
        '2022-03-13',
        '2022-03-14',
        '2022-03-15',
        '2022-03-16',
        '2022-03-17',
        '2022-03-18',
        '2022-03-19',
        '2022-03-20',
        '2022-03-21',
        '2022-03-22',
        '2022-03-23',
        '2022-03-24',
        '2022-03-25',
        '2022-03-26',
        '2022-03-27',
        '2022-03-28',
        '2022-03-29',
        '2022-03-30',
        '2022-03-31',
        '2022-04-01',
        '2022-04-02',
        '2022-04-03',
        '2022-04-04',
        '2022-04-05',
        '2022-04-06',
        '2022-04-07',
        '2022-04-08',
        '2022-04-09',
        '2022-04-10',
        '2022-04-11',
        '2022-04-12',
        '2022-04-13',
        '2022-04-14',
        '2022-04-15',
        '2022-04-16',
        '2022-04-17',
        '2022-04-18',
        '2022-04-19',
        '2022-04-20',
        '2022-04-21',
        '2022-04-22',
        '2022-04-23',
        '2022-04-24',
        '2022-04-25',
        '2022-04-26',
        '2022-04-27',
        '2022-04-28',
        '2022-04-29',
        '2022-04-30',
        '2022-05-01',
        '2022-05-02',
        '2022-05-03',
        '2022-05-04',
        '2022-05-05',
        '2022-05-06',
        '2022-05-07',
        '2022-05-08',
        '2022-05-09',
        '2022-05-10',
        '2022-05-11',
        '2022-05-12',
        '2022-05-13',
        '2022-05-14',
        '2022-05-15',
        '2022-05-16',
        '2022-05-17',
        '2022-05-18',
        '2022-05-19',
        '2022-05-20',
        '2022-05-21',
        '2022-05-22',
        '2022-05-23',
        '2022-05-24',
        '2022-05-25',
        '2022-05-26',
        '2022-05-27',
        '2022-05-28',
        '2022-05-29',
        '2022-05-30',
        '2022-05-31',
        '2022-06-01',
        '2022-06-02',
        '2022-06-03',
        '2022-06-04',
        '2022-06-05',
        '2022-06-06',
        '2022-06-07',
        '2022-06-08',
        '2022-06-09',
        '2022-06-10',
        '2022-06-11',
        '2022-06-12',
        '2022-06-13',
        '2022-06-14',
        '2022-06-15',
        '2022-06-16',
        '2022-06-17',
        '2022-06-18',
        '2022-06-19',
        '2022-06-20',
        '2022-06-21',
        '2022-06-22',
        '2022-06-23',
        '2022-06-24',
        '2022-06-25',
        '2022-06-26',
        '2022-06-27',
        '2022-06-28',
        '2022-06-29',
        '2022-06-30',
        '2022-07-01',
        '2022-07-02',
        '2022-07-03',
        '2022-07-04',
        '2022-07-05',
        '2022-07-06',
        '2022-07-07',
        '2022-07-08',
        '2022-07-09',
        '2022-07-10',
        '2022-07-11',
        '2022-07-12',
        '2022-07-13',
        '2022-07-14',
        '2022-07-15',
        '2022-07-16',
        '2022-07-17',
        '2022-07-18',
        '2022-07-19',
        '2022-07-20',
        '2022-07-21',
        '2022-07-22',
        '2022-07-23',
        '2022-07-24',
        '2022-07-25',
        '2022-07-26',
        '2022-07-27',
        '2022-07-28',
        '2022-07-29',
        '2022-07-30',
        '2022-07-31',
        '2022-08-01',
        '2022-08-02',
        '2022-08-03',
        '2022-08-04',
        '2022-08-05',
        '2022-08-06',
        '2022-08-07',
        '2022-08-08',
        '2022-08-09',
        '2022-08-10',
        '2022-08-11',
        '2022-08-12',
        '2022-08-13',
        '2022-08-14',
        '2022-08-15',
        '2022-08-16',
        '2022-08-17',
        '2022-08-18',
        '2022-08-19',
        '2022-08-20',
        '2022-08-21',
        '2022-08-22',
        '2022-08-23',
        '2022-08-24',
        '2022-08-25',
        '2022-08-26',
        '2022-08-27',
        '2022-08-28',
        '2022-08-29',
        '2022-08-30',
        '2022-08-31',
        '2022-09-01',
        '2022-09-02',
        '2022-09-03',
        '2022-09-04',
        '2022-09-05',
        '2022-09-06',
        '2022-09-07',
        '2022-09-08',
        '2022-09-09',
        '2022-09-10',
        '2022-09-11',
        '2022-09-12',
        '2022-09-13',
        '2022-09-14',
        '2022-09-15',
        '2022-09-16',
        '2022-09-17',
        '2022-09-18',
        '2022-09-19',
        '2022-09-20',
        '2022-09-21',
        '2022-09-22',
        '2022-09-23',
        '2022-09-24',
        '2022-09-25',
        '2022-09-26',
        '2022-09-27',
        '2022-09-28',
        '2022-09-29',
        '2022-09-30',
        '2022-10-01',
        '2022-10-02',
        '2022-10-03',
        '2022-10-04',
        '2022-10-05',
        '2022-10-06',
        '2022-10-07',
        '2022-10-08',
        '2022-10-09',
        '2022-10-10',
        '2022-10-11',
        '2022-10-12',
        '2022-10-13',
        '2022-10-14',
        '2022-10-15',
        '2022-10-16',
        '2022-10-17',
        '2022-10-18',
        '2022-10-19',
        '2022-10-20',
        '2022-10-21',
        '2022-10-22',
        '2022-10-23',
        '2022-10-24',
        '2022-10-25',
        '2022-10-26',
        '2022-10-27',
        '2022-10-28',
        '2022-10-29',
        '2022-10-30',
        '2022-10-31',
        '2022-11-01',
        '2022-11-02',
        '2022-11-03',
        '2022-11-04',
        '2022-11-05',
        '2022-11-06',
        '2022-11-07',
        '2022-11-08',
        '2022-11-09',
        '2022-11-10',
        '2022-11-11',
        '2022-11-12',
        '2022-11-13',
        '2022-11-14',
        '2022-11-15',
        '2022-11-16',
        '2022-11-17',
        '2022-11-18',
        '2022-11-19',
        '2022-11-20',
        '2022-11-21',
        '2022-11-22',
        '2022-11-23',
        '2022-11-24',
        '2022-11-25',
        '2022-11-26',
        '2022-11-27',
        '2022-11-28',
        '2022-11-29',
        '2022-11-30',
        '2022-12-01',
        '2022-12-02',
        '2022-12-03',
        '2022-12-04',
        '2022-12-05',
        '2022-12-06',
        '2022-12-07',
        '2022-12-08',
        '2022-12-09',
        '2022-12-10',
        '2022-12-11',
        '2022-12-12',
        '2022-12-13',
        '2022-12-14',
        '2022-12-15',
        '2022-12-16',
        '2022-12-17',
        '2022-12-18',
        '2022-12-19',
        '2022-12-20',
        '2022-12-21',
        '2022-12-22',
        '2022-12-23',
        '2022-12-24',
        '2022-12-25',
        '2022-12-26',
        '2022-12-27',
        '2022-12-28',
        '2022-12-29',
        '2022-12-30',
        '2022-12-31',
        '2023-01-01',
        '2023-01-02',
        '2023-01-03',
        '2023-01-04',
        '2023-01-05',
        '2023-01-06',
        '2023-01-07',
        '2023-01-08',
        '2023-01-09',
        '2023-01-10',
        '2023-01-11',
        '2023-01-12',
        '2023-01-13',
        '2023-01-14',
        '2023-01-15',
        '2023-01-16',
        '2023-01-17',
        '2023-01-18',
        '2023-01-19',
        '2023-01-20',
        '2023-01-21',
        '2023-01-22',
        '2023-01-23',
        '2023-01-24',
        '2023-01-25',
        '2023-01-26',
        '2023-01-27',
        '2023-01-28',
        '2023-01-29',
        '2023-01-30',
        '2023-01-31',
        '2023-02-01',
        '2023-02-02',
        '2023-02-03',
        '2023-02-04',
        '2023-02-05',
        '2023-02-06',
        '2023-02-07',
        '2023-02-08',
        '2023-02-09',
        '2023-02-10',
        '2023-02-11',
        '2023-02-12',
        '2023-02-13',
        '2023-02-14',
        '2023-02-15',
        '2023-02-16',
        '2023-02-17',
        '2023-02-18',
        '2023-02-19',
        '2023-02-20',
        '2023-02-21',
        '2023-02-22',
        '2023-02-23',
        '2023-02-24',
        '2023-02-25',
        '2023-02-26',
        '2023-02-27',
        '2023-02-28',
        '2023-03-01',
        '2023-03-02',
        '2023-03-03',
        '2023-03-04',
        '2023-03-05',
        '2023-03-06',
        '2023-03-07',
        '2023-03-08',
        '2023-03-09',
        '2023-03-10',
        '2023-03-11',
        '2023-03-12',
        '2023-03-13',
        '2023-03-14',
        '2023-03-15',
        '2023-03-16',
        '2023-03-17',
        '2023-03-18',
        '2023-03-19',
        '2023-03-20',
        '2023-03-21',
        '2023-03-22',
        '2023-03-23',
        '2023-03-24',
        '2023-03-25',
        '2023-03-26',
        '2023-03-27',
        '2023-03-28',
        '2023-03-29',
        '2023-03-30',
        '2023-03-31',
        '2023-04-01',
        '2023-04-02',
        '2023-04-03',
        '2023-04-04',
        '2023-04-05',
        '2023-04-06',
        '2023-04-07',
        '2023-04-08',
        '2023-04-09',
        '2023-04-10',
        '2023-04-11',
        '2023-04-12',
        '2023-04-13',
        '2023-04-14',
        '2023-04-15',
        '2023-04-16',
        '2023-04-17',
        '2023-04-18',
        '2023-04-19',
        '2023-04-20',
        '2023-04-21',
        '2023-04-22',
        '2023-04-23',
        '2023-04-24',
        '2023-04-25',
        '2023-04-26',
        '2023-04-27',
        '2023-04-28',
        '2023-04-29',
        '2023-04-30',
        '2023-05-01',
        '2023-05-02',
        '2023-05-03',
        '2023-05-04',
        '2023-05-05',
        '2023-05-06',
        '2023-05-07',
        '2023-05-08',
        '2023-05-09',
        '2023-05-10',
        '2023-05-11',
        '2023-05-12',
        '2023-05-13',
        '2023-05-14',
        '2023-05-15',
        '2023-05-16',
        '2023-05-17',
        '2023-05-18',
        '2023-05-19',
        '2023-05-20',
        '2023-05-21',
        '2023-05-22',
        '2023-05-23',
        '2023-05-24',
        '2023-05-25',
        '2023-05-26',
        '2023-05-27',
        '2023-05-28',
        '2023-05-29',
        '2023-05-30',
        '2023-05-31',
        '2023-06-01',
        '2023-06-02',
        '2023-06-03',
        '2023-06-04',
        '2023-06-05',
        '2023-06-06',
        '2023-06-07',
        '2023-06-08',
        '2023-06-09',
        '2023-06-10',
        '2023-06-11',
        '2023-06-12',
        '2023-06-13',
        '2023-06-14',
        '2023-06-15',
        '2023-06-16',
        '2023-06-17',
        '2023-06-18',
        '2023-06-19',
        '2023-06-20',
        '2023-06-21',
        '2023-06-22',
        '2023-06-23',
        '2023-06-24',
        '2023-06-25',
        '2023-06-26',
        '2023-06-27',
        '2023-06-28',
        '2023-06-29',
        '2023-06-30',
        '2023-07-01',
        '2023-07-02',
        '2023-07-03',
        '2023-07-04',
        '2023-07-05',
        '2023-07-06',
        '2023-07-07',
        '2023-07-08',
        '2023-07-09',
        '2023-07-10',
        '2023-07-11',
        '2023-07-12',
        '2023-07-13',
        '2023-07-14',
        '2023-07-15',
        '2023-07-16',
        '2023-07-17',
        '2023-07-18',
        '2023-07-19',
        '2023-07-20',
        '2023-07-21',
        '2023-07-22',
        '2023-07-23',
        '2023-07-24',
        '2023-07-25',
        '2023-07-26',
        '2023-07-27',
        '2023-07-28',
        '2023-07-29',
        '2023-07-30',
        '2023-07-31',
        '2023-08-01',
        '2023-08-02',
        '2023-08-03',
        '2023-08-04',
        '2023-08-05',
        '2023-08-06',
        '2023-08-07',
        '2023-08-08',
        '2023-08-09',
        '2023-08-10',
        '2023-08-11',
        '2023-08-12',
        '2023-08-13',
        '2023-08-14',
        '2023-08-15',
        '2023-08-16',
        '2023-08-17',
        '2023-08-18',
        '2023-08-19',
        '2023-08-20',
        '2023-08-21',
        '2023-08-22',
        '2023-08-23',
        '2023-08-24',
        '2023-08-25',
        '2023-08-26',
        '2023-08-27',
        '2023-08-28',
        '2023-08-29',
        '2023-08-30',
        '2023-08-31',
        '2023-09-01',
        '2023-09-02',
        '2023-09-03',
        '2023-09-04',
        '2023-09-05',
        '2023-09-06',
        '2023-09-07',
        '2023-09-08',
        '2023-09-09',
        '2023-09-10',
        '2023-09-11',
        '2023-09-12',
        '2023-09-13',
        '2023-09-14',
        '2023-09-15',
        '2023-09-16',
        '2023-09-17',
        '2023-09-18',
        '2023-09-19',
        '2023-09-20',
        '2023-09-21',
        '2023-09-22',
        '2023-09-23',
        '2023-09-24',
        '2023-09-25',
        '2023-09-26',
        '2023-09-27',
        '2023-09-28',
        '2023-09-29',
        '2023-09-30',
        '2023-10-01',
        '2023-10-02',
        '2023-10-03',
        '2023-10-04',
        '2023-10-05',
        '2023-10-06',
        '2023-10-07',
        '2023-10-08',
        '2023-10-09',
        '2023-10-10',
        '2023-10-11',
        '2023-10-12',
        '2023-10-13',
        '2023-10-14',
        '2023-10-15',
        '2023-10-16',
        '2023-10-17',
        '2023-10-18',
        '2023-10-19',
        '2023-10-20',
        '2023-10-21',
        '2023-10-22',
        '2023-10-23',
        '2023-10-24',
        '2023-10-25',
        '2023-10-26',
        '2023-10-27',
        '2023-10-28',
        '2023-10-29',
        '2023-10-30',
        '2023-10-31',
        '2023-11-01',
        '2023-11-02',
        '2023-11-03',
        '2023-11-04',
        '2023-11-05',
        '2023-11-06',
        '2023-11-07',
        '2023-11-08',
        '2023-11-09',
        '2023-11-10',
        '2023-11-11',
        '2023-11-12',
        '2023-11-13',
        '2023-11-14',
        '2023-11-15',
        '2023-11-16',
        '2023-11-17',
        '2023-11-18',
        '2023-11-19',
        '2023-11-20',
        '2023-11-21',
        '2023-11-22',
        '2023-11-23',
        '2023-11-24',
        '2023-11-25',
        '2023-11-26',
        '2023-11-27',
        '2023-11-28',
        '2023-11-29',
        '2023-11-30',
        '2023-12-01',
        '2023-12-02',
        '2023-12-03',
        '2023-12-04',
        '2023-12-05',
        '2023-12-06',
        '2023-12-07',
        '2023-12-08',
        '2023-12-09',
        '2023-12-10',
        '2023-12-11',
        '2023-12-12',
        '2023-12-13',
        '2023-12-14',
        '2023-12-15',
        '2023-12-16',
        '2023-12-17',
        '2023-12-18',
        '2023-12-19',
        '2023-12-20',
        '2023-12-21',
        '2023-12-22',
        '2023-12-23',
        '2023-12-24',
        '2023-12-25',
        '2023-12-26',
        '2023-12-27',
        '2023-12-28',
        '2023-12-29',
        '2023-12-30',
        '2023-12-31',
        '2024-01-01',
        '2024-01-02',
        '2024-01-03',
        '2024-01-04',
        '2024-01-05',
        '2024-01-06',
        '2024-01-07',
        '2024-01-08',
        '2024-01-09',
        '2024-01-10',
        '2024-01-11',
        '2024-01-12',
        '2024-01-13',
        '2024-01-14',
        '2024-01-15',
        '2024-01-16',
        '2024-01-17',
        '2024-01-18',
        '2024-01-19',
        '2024-01-20',
        '2024-01-21',
        '2024-01-22',
        '2024-01-23',
        '2024-01-24',
        '2024-01-25',
        '2024-01-26',
        '2024-01-27',
        '2024-01-28',
        '2024-01-29',
        '2024-01-30',
        '2024-01-31',
        '2024-02-01',
        '2024-02-02',
        '2024-02-03',
        '2024-02-04',
        '2024-02-05',
        '2024-02-06',
        '2024-02-07',
        '2024-02-08',
        '2024-02-09',
        '2024-02-10',
        '2024-02-11',
        '2024-02-12',
        '2024-02-13',
        '2024-02-14',
        '2024-02-15',
        '2024-02-16',
        '2024-02-17',
        '2024-02-18',
        '2024-02-19',
        '2024-02-20',
        '2024-02-21',
        '2024-02-22',
        '2024-02-23',
        '2024-02-24',
        '2024-02-25',
        '2024-02-26',
        '2024-02-27',
        '2024-02-28',
        '2024-02-29',
        '2024-03-01',
        '2024-03-02',
        '2024-03-03',
        '2024-03-04',
        '2024-03-05',
        '2024-03-06',
        '2024-03-07',
        '2024-03-08',
        '2024-03-09',
        '2024-03-10',
        '2024-03-11',
        '2024-03-12',
        '2024-03-13',
        '2024-03-14',
        '2024-03-15',
        '2024-03-16',
        '2024-03-17',
        '2024-03-18',
        '2024-03-19',
        '2024-03-20',
        '2024-03-21',
        '2024-03-22',
        '2024-03-23',
        '2024-03-24',
        '2024-03-25',
        '2024-03-26',
        '2024-03-27',
        '2024-03-28',
        '2024-03-29',
        '2024-03-30',
        '2024-03-31',
        '2024-04-01',
        '2024-04-02',
        '2024-04-03',
        '2024-04-04',
        '2024-04-05',
        '2024-04-06',
        '2024-04-07',
        '2024-04-08',
        '2024-04-09',
        '2024-04-10',
        '2024-04-11',
        '2024-04-12',
        '2024-04-13',
        '2024-04-14',
        '2024-04-15',
        '2024-04-16',
        '2024-04-17',
        '2024-04-18',
        '2024-04-19',
        '2024-04-20',
        '2024-04-21',
        '2024-04-22',
        '2024-04-23',
        '2024-04-24',
        '2024-04-25',
        '2024-04-26',
        '2024-04-27',
        '2024-04-28',
        '2024-04-29',
        '2024-04-30',
        '2024-05-01',
        '2024-05-02',
        '2024-05-03',
        '2024-05-04',
        '2024-05-05',
        '2024-05-06',
        '2024-05-07',
        '2024-05-08',
        '2024-05-09',
        '2024-05-10',
        '2024-05-11',
        '2024-05-12',
        '2024-05-13',
        '2024-05-14',
        '2024-05-15',
        '2024-05-16',
        '2024-05-17',
        '2024-05-18',
        '2024-05-19',
        '2024-05-20',
        '2024-05-21',
        '2024-05-22',
        '2024-05-23',
        '2024-05-24',
        '2024-05-25',
        '2024-05-26',
        '2024-05-27',
        '2024-05-28',
        '2024-05-29',
        '2024-05-30',
        '2024-05-31',
        '2024-06-01',
        '2024-06-02',
        '2024-06-03',
        '2024-06-04',
        '2024-06-05',
        '2024-06-06',
        '2024-06-07',
        '2024-06-08',
        '2024-06-09',
        '2024-06-10',
        '2024-06-11',
        '2024-06-12',
        '2024-06-13',
        '2024-06-14',
        '2024-06-15',
        '2024-06-16',
        '2024-06-17',
        '2024-06-18',
        '2024-06-19',
        '2024-06-20',
        '2024-06-21',
        '2024-06-22',
        '2024-06-23',
        '2024-06-24',
        '2024-06-25',
        '2024-06-26',
        '2024-06-27',
        '2024-06-28',
        '2024-06-29',
        '2024-06-30',
        '2024-07-01',
        '2024-07-02',
        '2024-07-03',
        '2024-07-04',
        '2024-07-05',
        '2024-07-06',
        '2024-07-07',
        '2024-07-08',
        '2024-07-09',
        '2024-07-10',
        '2024-07-11',
        '2024-07-12',
        '2024-07-13',
        '2024-07-14',
        '2024-07-15',
        '2024-07-16',
        '2024-07-17',
        '2024-07-18',
        '2024-07-19',
        '2024-07-20',
        '2024-07-21',
        '2024-07-22',
        '2024-07-23',
        '2024-07-24',
        '2024-07-25',
        '2024-07-26',
        '2024-07-27',
        '2024-07-28',
        '2024-07-29',
        '2024-07-30',
        '2024-07-31',
        '2024-08-01',
        '2024-08-02',
        '2024-08-03',
        '2024-08-04',
        '2024-08-05',
        '2024-08-06',
        '2024-08-07',
        '2024-08-08',
        '2024-08-09',
        '2024-08-10',
        '2024-08-11',
        '2024-08-12',
        '2024-08-13',
        '2024-08-14',
        '2024-08-15',
        '2024-08-16',
        '2024-08-17',
        '2024-08-18',
        '2024-08-19',
        '2024-08-20',
        '2024-08-21',
        '2024-08-22',
        '2024-08-23',
        '2024-08-24',
        '2024-08-25',
        '2024-08-26',
        '2024-08-27',
        '2024-08-28',
        '2024-08-29',
        '2024-08-30',
        '2024-08-31',
        '2024-09-01',
        '2024-09-02',
        '2024-09-03',
        '2024-09-04',
        '2024-09-05',
        '2024-09-06',
        '2024-09-07',
        '2024-09-08',
        '2024-09-09',
        '2024-09-10',
        '2024-09-11',
        '2024-09-12',
        '2024-09-13',
        '2024-09-14',
        '2024-09-15',
        '2024-09-16',
        '2024-09-17',
        '2024-09-18',
        '2024-09-19',
        '2024-09-20',
        '2024-09-21',
        '2024-09-22',
        '2024-09-23',
        '2024-09-24',
        '2024-09-25',
        '2024-09-26',
        '2024-09-27',
        '2024-09-28',
        '2024-09-29',
        '2024-09-30'
      ],
      BTC: [
        10000.0, 10264.8, 10224.69, 10000.21, 9987.66, 9398.52, 9343.11,
        8988.44, 9068.35, 9132.01, 9002.11, 9241.87, 9486.34, 9216.19, 9358.16,
        9355.32, 9345.06, 9104.28, 9191.64, 9082.58, 8907.4, 7814.79, 7545.94,
        7683.0, 7869.29, 7960.54, 7899.58, 7941.96, 8147.44, 8252.39, 8175.75,
        8311.34, 8373.02, 8007.23, 7991.26, 8793.69, 8996.67, 8999.03, 9535.25,
        9533.93, 9641.0, 9511.14, 9121.12, 9125.56, 9167.97, 9234.29, 9556.92,
        9522.59, 8808.83, 8641.86, 8658.17, 8398.69, 8123.27, 8204.21, 8145.98,
        8193.98, 8530.24, 8469.88, 8182.82, 9309.67, 9554.24, 9534.9, 9194.05,
        8439.49, 8539.06, 8432.87, 8267.85, 8341.59, 9064.22, 8534.11, 8420.44,
        8460.53, 8161.71, 8509.02, 8539.52, 8845.89, 8868.57, 9019.89, 9077.73,
        8965.54, 8897.17, 9202.07, 9193.31, 9509.28, 9585.12, 9599.11, 10085.7,
        10286.41, 10241.08, 10192.05, 9907.16, 10032.77, 9977.03, 10103.47,
        10097.31, 9921.58, 9414.59, 9440.2, 9152.7, 9205.15, 9111.8, 8604.29,
        8617.61, 8931.59, 8621.02, 8755.98, 8742.65, 8654.84, 8854.71, 8956.76,
        8972.4, 8743.98, 8592.35, 8615.1, 8557.11, 8729.91, 8269.92, 8454.55,
        8610.46, 8353.79, 8276.28, 8288.44, 8349.98, 8165.77, 8585.99, 7840.99,
        7789.19, 7582.95, 7416.17, 6726.51, 6661.01, 6245.13, 6236.14, 6449.91,
        6542.91, 6727.12, 6495.12, 6567.17, 6269.59, 6531.2, 6307.85, 6364.39,
        6550.76, 6320.46, 6405.79, 6444.72, 6384.86, 6236.45, 6273.17, 6367.11,
        6873.77, 6895.59, 6436.42, 6595.62, 6432.79, 6449.59, 6496.04, 6793.08,
        6763.75, 6558.32, 6505.76, 6313.3, 6195.95, 5841.03, 4887.36, 4673.38,
        4862.81, 4406.4, 4425.04, 4151.49, 4477.84, 4433.36, 4521.5, 4349.77,
        4542.01, 4611.08, 4637.12, 4574.9, 4503.63, 4394.06, 4373.64, 4088.23,
        4176.09, 4182.74, 4175.95, 4362.83, 4421.59, 4438.58, 4679.7, 4711.05,
        4679.56, 4509.13, 4328.99, 4192.26, 4294.31, 4435.94, 4520.16, 4607.98,
        4544.26, 4715.27, 5085.1, 5043.4, 4980.23, 4917.31, 4861.65, 4902.36,
        4686.24, 4567.66, 4949.14, 5184.66, 5180.54, 5128.2, 5058.2, 5012.19,
        4998.37, 4961.86, 4894.86, 5029.2, 5014.1, 5029.46, 5152.53, 5013.39,
        5158.88, 5169.81, 5244.66, 5276.65, 5248.87, 5195.87, 5160.0, 5051.58,
        5051.05, 4538.41, 4573.29, 4666.25, 4581.86, 4646.67, 4650.62, 4668.48,
        4459.95, 4331.76, 4306.62, 4371.53, 4307.31, 4360.96, 4344.37, 4325.15,
        4282.78, 4291.44, 4285.13, 4091.39, 4184.01, 4177.29, 4618.89, 4707.8,
        4681.38, 4830.16, 4375.92, 4391.49, 4268.8, 4268.75, 4354.73, 4207.74,
        4241.55, 4102.52, 4000.46, 4197.07, 4183.13, 4086.45, 4065.26, 4140.99,
        4128.4, 4216.14, 4209.73, 4194.32, 4181.21, 4147.97, 4223.34, 4392.25,
        4368.62, 4306.07, 4229.98, 4189.1, 4197.13, 4139.24, 4119.73, 4144.67,
        4204.31, 4145.63, 4124.27, 4176.41, 4222.31, 4180.4, 4141.42, 4118.41,
        4152.02, 4152.66, 4239.34, 4189.19, 4341.12, 4504.21, 4385.75, 4463.73,
        4491.38, 4454.35, 4431.17, 4430.95, 4351.18, 4369.7, 4582.31, 4608.04,
        4573.91, 4427.26, 3994.14, 3434.81, 3856.43, 3661.2, 3642.75, 3556.78,
        3550.76, 3652.44, 3606.82, 3615.76, 3605.98, 3621.66, 3526.18, 3405.73,
        3501.94, 3572.83, 3594.46, 3576.43, 3553.96, 3585.92, 3509.7, 3569.17,
        3710.39, 3670.79, 3687.38, 3656.95, 3700.7, 3666.19, 3672.65, 3644.09,
        3721.98, 3707.07, 3708.22, 3700.25, 3712.49, 3846.4, 3859.41, 3751.85,
        3618.48, 3621.91, 3626.74, 3552.12, 3650.02, 3627.44, 3634.69, 3633.5,
        3640.63, 3639.75, 3643.41, 3611.44, 3574.03, 3595.99, 3588.14, 3574.62,
        3592.22, 3613.05, 3608.44, 3640.8, 3643.22, 3669.29, 3664.53, 3670.33,
        3722.62, 3770.93, 3798.69, 4092.42, 4294.83, 4541.61, 4524.59, 4584.01,
        4594.07, 4501.77, 4557.9, 4888.92, 4924.54, 4906.53, 4960.38, 4923.64,
        4961.86, 4977.35, 4978.31, 4971.69, 5142.25, 4918.07, 5015.87, 5131.13,
        5096.87, 5067.62, 5063.7, 4972.1, 4950.43, 5026.01, 4961.11, 4709.86,
        4667.38, 4722.19, 4715.7, 4682.35, 4800.77, 5225.7, 5191.77, 5339.85,
        5328.63, 5299.17, 5345.34, 5276.01, 5214.69, 5163.93, 5002.47, 5002.22,
        5079.62, 5080.85, 5011.44, 5091.63, 5076.27, 4829.61, 4826.37, 4853.85,
        4853.99, 4783.02, 4717.35, 4401.76, 4348.46, 4399.59, 4695.77, 5236.42,
        5344.01, 5271.04, 5405.29, 5953.08, 5822.64, 6082.81, 6048.04, 6065.13,
        5887.28, 6104.71, 5906.5, 5938.8, 6067.1, 5863.65, 5889.26, 6142.65,
        6036.44, 6181.78, 6170.45, 6081.28, 6015.11, 6098.9, 6096.31, 6060.09,
        6048.86, 6044.92, 6138.74, 6403.28, 6548.2, 6456.8, 6555.19, 6585.32,
        6567.07, 6562.75, 6383.44, 6571.37, 6291.24, 6112.28, 5899.58, 6026.64,
        5970.05, 5942.39, 6108.85, 6122.92, 6358.1, 6349.48, 6318.17, 6356.7,
        6062.71, 6217.83, 6297.93, 6227.13, 6402.35, 6239.08, 6218.33, 5983.32,
        5967.42, 5987.06, 5821.21, 5773.8, 5818.2, 5820.54, 5909.13, 5841.66,
        5914.23, 5818.59, 5814.14, 5856.58, 5788.96, 5814.33, 5884.19, 5701.06,
        5723.97, 5774.07, 5799.45, 6077.95, 5991.14, 6001.81, 5881.83, 5816.26,
        5898.9, 5857.49, 5892.66, 5563.05, 5875.04, 5685.34, 5743.43, 5732.25,
        5598.63, 5603.1, 5610.48, 5591.02, 5416.84, 5545.44, 5705.81, 5749.0,
        5712.68, 5789.0, 6104.57, 6515.91, 6475.5, 6630.51, 6602.63, 6599.5,
        6540.36, 6618.01, 6514.05, 6579.69, 6594.27, 6616.11, 6632.05, 6733.62,
        6668.55, 6585.66, 6527.32, 6556.92, 6539.7, 6528.78, 6566.28, 6617.61,
        6570.9, 6774.4, 6540.37, 6552.89, 6560.59, 6514.23, 6452.11, 6463.6,
        6450.88, 6474.91, 6449.59, 6494.06, 6309.65, 6319.43, 6377.75, 6318.53,
        6337.94, 6351.0, 6308.51, 6320.19, 6329.72, 6315.0, 6325.66, 6287.22,
        6294.36, 6296.4, 6313.96, 6440.93, 6405.0, 6375.37, 6368.78, 6367.04,
        6343.87, 6369.53, 6314.48, 6255.29, 5799.02, 5641.22, 5637.33, 5670.97,
        5654.2, 5584.1, 5724.31, 5651.55, 5637.33, 5632.18, 5644.96, 5638.93,
        5980.12, 5894.16, 5637.32, 5574.65, 5595.68, 5614.95, 5576.96, 5575.51,
        5571.17, 5695.75, 5605.24, 5605.85, 5593.7, 5441.44, 5619.16, 5678.04,
        5755.72, 5793.62, 5746.82, 5732.1, 5814.35, 5883.0, 5862.77, 5753.31,
        5752.09, 5747.29, 5731.85, 5686.63, 5659.75, 5697.94, 5844.39, 5817.61,
        5844.24, 6057.62, 5962.0, 5929.47, 6004.89, 5936.19, 6052.16, 6047.79,
        6040.86, 5968.96, 5938.93, 5795.31, 5789.02, 5805.21, 5809.44, 5864.12,
        6168.65, 6148.6, 6132.73, 6198.95, 6417.92, 6490.41, 6499.56, 7208.6,
        7390.29, 7487.84, 7405.89, 7314.75, 7379.2, 7508.6, 7458.0, 7477.59,
        7633.62, 7527.28, 7492.53, 7607.6, 7566.79, 7598.02, 7634.01, 7742.54,
        7908.06, 8081.82, 7982.33, 8039.4, 7890.46, 7704.53, 8158.62, 7836.43,
        7907.23, 7898.83, 8001.46, 8097.85, 7871.05, 8102.2, 8071.71, 8153.32,
        8162.6, 8147.07, 8023.73, 8192.04, 8176.8, 8155.89, 8374.38, 8513.98,
        8667.97, 9076.89, 9582.07, 9469.87, 9333.38, 9576.69, 9498.72, 9473.47,
        8933.15, 8921.01, 9295.81, 9319.32, 9100.81, 9154.93, 9026.53, 9198.16,
        9159.77, 9436.29, 9509.38, 9477.05, 9465.33, 9299.04, 9451.11, 9189.27,
        9374.01, 9223.04, 9035.87, 9116.29, 9143.38, 9418.61, 9732.73, 9249.79,
        9589.13, 9513.27, 9494.06, 9490.64, 10169.43, 10008.02, 10148.38,
        10039.61, 9197.92, 9301.93, 9146.22, 9223.46, 9348.52, 9243.83, 8947.95,
        9019.87, 9040.31, 9008.25, 8618.66, 8539.88, 8628.01, 8645.38, 9067.3,
        9121.23, 9051.66, 9327.99, 9374.87, 9229.53, 9288.18, 9347.78, 9310.73,
        9211.26, 9196.79, 9347.22, 9555.08, 9795.66, 10228.42, 10348.13,
        10415.23, 10823.12, 10715.02, 11206.38, 11174.35, 11246.5, 11188.65,
        11303.17, 11237.53, 11287.78, 11145.51, 11133.59, 10989.05, 11155.35,
        11186.81, 11826.79, 12309.97, 13258.11, 13247.1, 13521.04, 13346.85,
        13594.03, 14697.88, 13717.48, 14262.42, 14518.96, 14735.31, 14792.2,
        14803.79, 15674.15, 15397.39, 15784.65, 15479.0, 14952.16, 14358.61,
        14762.55, 14646.58, 13552.17, 14644.4, 14148.96, 13636.12, 14009.55,
        14486.3, 15213.04, 15199.89, 15024.83, 15329.45, 15104.42, 15085.58,
        15355.85, 15083.6, 14240.66, 14310.77, 14699.6, 14632.84, 14941.71,
        14972.87, 15560.55, 15000.05, 15260.21, 15178.69, 14508.39, 13911.23,
        14135.58, 13730.89, 13800.7, 13347.91, 13739.5, 13850.44, 13980.49,
        14070.69, 14502.9, 14358.99, 13868.51, 13978.2, 13809.21, 13687.63,
        13664.05, 13823.94, 13109.87, 12523.67, 12836.86, 13584.29, 13764.21,
        13817.45, 13738.62, 13599.12, 13252.94, 13692.06, 13167.04, 13179.15,
        13269.16, 13581.61, 13351.99, 14305.65, 14154.76, 14447.43, 14482.14,
        14359.15, 15057.42, 15194.04, 14960.16, 14645.38, 14883.73, 14957.51,
        14834.39, 15057.6, 14846.38, 14643.99, 14781.36, 14607.1, 14662.47,
        14697.64, 14938.79, 15267.41, 15393.67, 15340.91, 15037.57, 15002.76,
        15066.85, 15036.91, 14574.79, 14788.05, 14474.35, 14283.51, 14323.75,
        14444.06, 14422.42, 14124.69, 14028.63, 14061.93, 13881.28, 13916.06,
        13773.74, 12992.85, 13395.52, 13182.31, 13337.11, 13061.88, 13192.14,
        13609.95, 13625.98, 13434.47, 13050.63, 12557.92, 12251.16, 12572.55,
        12202.35, 12264.87, 12535.66, 12486.92, 12406.4, 12504.51, 12866.4,
        13168.64, 13922.09, 13981.22, 13905.98, 13854.67, 14436.53, 14521.51,
        14715.82, 14634.25, 14262.74, 14126.43, 14203.45, 14697.21, 14847.37,
        14715.43, 14546.05, 14328.52, 14059.78, 14104.0, 13282.15, 13118.53,
        12704.4, 11834.59, 12160.11, 11961.85, 13486.83, 13147.47, 13183.53,
        12765.02, 12777.06, 13108.86, 12762.85, 12466.72, 12758.65, 12833.86,
        12841.61, 12818.03, 12845.5, 13236.81, 13076.39, 13866.12, 13811.81,
        13952.03, 13625.53, 12875.57, 12808.17, 12811.75, 12817.27, 12771.07,
        12400.0, 12841.61, 12520.3, 12577.86, 12139.41, 11607.0, 11674.08,
        11798.71, 12379.88, 12466.39, 12434.41, 12580.53, 13113.81, 12983.76,
        12858.99, 12543.03, 13034.19, 13130.42, 13618.39, 13670.37, 13681.2,
        13822.1, 13678.75, 13973.21, 13688.06, 14082.79, 14252.35, 14211.25,
        14210.03
      ],
      ETH: [
        10000.0, 10222.13, 10412.02, 10206.08, 10381.89, 9613.79, 9328.99,
        8654.98, 8482.74, 8648.54, 8331.17, 8792.1, 9147.69, 8832.72, 9013.28,
        9078.87, 9108.21, 8684.36, 8648.02, 8506.16, 8326.52, 6924.57, 6472.31,
        6652.19, 6562.41, 6630.65, 6632.29, 6480.53, 6842.15, 7072.71, 7006.31,
        7290.08, 7584.0, 7287.52, 7236.27, 8040.33, 8213.04, 8131.16, 8573.16,
        8470.42, 8843.35, 8475.59, 7872.17, 7930.95, 7894.34, 8000.62, 8502.46,
        8531.21, 7886.81, 7547.27, 7461.67, 7214.46, 7122.62, 7084.44, 7172.89,
        6980.58, 7593.6, 7603.91, 7154.31, 7881.01, 8090.9, 8053.9, 7708.99,
        7104.32, 7263.04, 7151.6, 6811.92, 6986.48, 7389.86, 7106.23, 6992.0,
        7037.89, 6813.58, 6992.95, 7131.6, 7472.14, 7669.03, 7974.72, 7985.36,
        7825.41, 7910.53, 8159.99, 8172.94, 8460.84, 8429.62, 8525.8, 8894.5,
        9169.89, 9211.28, 9220.38, 8948.66, 9388.18, 9430.2, 9641.62, 9594.54,
        9368.27, 8673.78, 8802.61, 8679.33, 8833.67, 8765.91, 8161.05, 8198.1,
        8494.87, 8200.45, 8250.27, 8326.44, 8284.6, 8338.06, 8427.84, 8393.2,
        8115.13, 8047.73, 8039.4, 7967.38, 8185.65, 7675.36, 7820.73, 7966.48,
        7668.89, 7565.39, 7635.49, 7784.91, 7575.43, 7957.34, 7444.66, 7331.75,
        7080.49, 6907.78, 6238.37, 6311.25, 5668.11, 5234.12, 5600.33, 5592.25,
        5794.98, 5531.0, 5662.25, 5299.21, 5458.12, 5313.52, 5371.78, 5536.71,
        5380.78, 5393.79, 5345.59, 4952.42, 4738.93, 4853.72, 4925.32, 5438.78,
        5323.63, 4946.4, 4989.22, 4849.95, 4893.23, 4938.7, 5046.75, 4920.18,
        4896.57, 4865.94, 4557.2, 4211.19, 3961.7, 3246.78, 3201.13, 3312.17,
        2902.21, 2949.36, 2695.75, 3105.25, 3012.57, 3094.94, 2888.61, 3105.27,
        3346.63, 3379.55, 3302.92, 3270.45, 3123.44, 3016.87, 2784.76, 2896.12,
        2912.04, 2926.64, 3109.0, 3132.72, 3238.65, 3345.35, 3349.68, 3308.53,
        3178.91, 2988.72, 2837.07, 2955.09, 3229.73, 3397.64, 3694.22, 3700.26,
        4119.47, 4252.37, 4152.0, 4286.95, 4188.15, 4194.11, 4366.86, 4031.39,
        3838.12, 4422.74, 4748.72, 4738.14, 4621.39, 4582.66, 4430.35, 4481.11,
        4412.35, 4343.74, 4666.28, 4660.97, 4637.12, 4837.29, 4634.64, 5032.3,
        5095.14, 5254.43, 5382.34, 5257.42, 5155.42, 5091.82, 4975.53, 5078.3,
        4406.82, 4280.23, 4402.88, 4318.93, 4499.42, 4517.4, 4608.94, 4213.5,
        4037.37, 3983.59, 4191.14, 4204.34, 4266.79, 4315.64, 4299.3, 4230.34,
        4261.6, 4369.48, 4295.63, 4448.52, 4439.66, 4677.29, 4851.16, 4749.07,
        4640.82, 4317.77, 4458.1, 4012.37, 3896.03, 3985.98, 3647.18, 3769.54,
        3620.89, 3383.6, 3600.26, 3617.24, 3575.65, 3511.59, 3610.88, 3617.87,
        3639.71, 3615.88, 3597.41, 3571.02, 3509.03, 3578.51, 3696.92, 3687.09,
        3674.53, 3627.33, 3568.41, 3581.53, 3515.07, 3484.28, 3521.3, 3515.64,
        3524.75, 3457.35, 3558.86, 3620.86, 3570.5, 3499.16, 3489.89, 3540.04,
        3577.71, 3714.43, 3662.28, 3966.21, 4266.38, 4127.6, 4239.73, 4382.21,
        4317.44, 4259.34, 4293.83, 4111.55, 4165.16, 4487.37, 4440.2, 4357.23,
        4241.36, 3586.78, 3040.2, 3601.89, 3482.89, 3429.27, 3341.17, 3318.65,
        3388.99, 3307.65, 3282.41, 3291.49, 3330.02, 3108.81, 3013.88, 3094.59,
        3194.52, 3273.65, 3262.2, 3267.81, 3308.42, 3184.82, 3327.25, 3533.27,
        3475.03, 3510.61, 3378.49, 3469.83, 3424.97, 3408.06, 3346.88, 3493.53,
        3438.66, 3452.24, 3433.71, 3461.29, 3590.17, 3568.31, 3436.65, 3206.33,
        3225.69, 3230.41, 3162.27, 3306.35, 3290.51, 3307.55, 3314.35, 3314.73,
        3311.3, 3314.73, 3294.68, 3225.34, 3258.26, 3259.32, 3253.85, 3267.01,
        3305.31, 3298.73, 3409.23, 3402.27, 3453.75, 3435.88, 3453.24, 3593.14,
        3629.97, 3670.4, 3869.56, 3953.38, 4219.16, 4236.81, 4297.89, 4282.03,
        4165.63, 4220.36, 4493.58, 4431.07, 4430.38, 4427.01, 4249.36, 4314.07,
        4357.13, 4333.08, 4256.27, 4469.31, 4254.25, 4325.96, 4455.35, 4503.64,
        4535.11, 4569.91, 4445.5, 4441.65, 4529.94, 4490.74, 4183.61, 4103.41,
        4178.96, 4116.61, 4057.42, 4228.62, 4531.21, 4477.65, 4620.85, 4597.03,
        4585.0, 4605.05, 4496.83, 4446.33, 4480.38, 4365.56, 4329.74, 4443.52,
        4436.86, 4386.16, 4506.05, 4484.98, 4262.43, 4247.7, 4258.68, 4268.99,
        4225.2, 4204.29, 3896.76, 3873.94, 3970.62, 4255.21, 4570.72, 4628.12,
        4483.65, 4550.01, 4820.67, 4812.48, 4909.87, 4753.51, 4879.35, 4696.99,
        4919.44, 4741.98, 4727.2, 4831.25, 4650.12, 4816.02, 4870.54, 4854.08,
        4967.98, 4959.64, 4878.4, 4913.49, 5078.55, 5195.41, 5085.18, 5080.83,
        5034.91, 5075.33, 5179.08, 5152.32, 5203.73, 5460.43, 5725.35, 5699.48,
        5784.3, 5657.13, 5691.98, 5347.87, 5285.31, 5019.0, 5106.95, 5064.21,
        5002.6, 5080.23, 5073.14, 5182.84, 5152.24, 5177.94, 5147.64, 4967.02,
        5097.42, 5186.97, 5090.24, 5409.3, 5155.78, 5188.71, 5024.38, 5015.9,
        5011.71, 4868.25, 4902.99, 4901.3, 4896.38, 4959.38, 4959.97, 4951.59,
        4920.15, 4929.88, 4946.21, 4909.9, 4947.02, 5044.29, 4894.64, 4916.13,
        4967.83, 4978.11, 5186.62, 5147.21, 5180.31, 5085.16, 5079.8, 5185.15,
        5144.87, 5172.72, 4915.83, 5121.07, 4977.16, 5030.89, 5001.0, 4770.89,
        4770.81, 4741.27, 4719.18, 4488.27, 4530.46, 4679.3, 4707.35, 4685.29,
        4714.96, 4855.9, 5149.9, 5088.12, 5142.11, 5100.18, 5168.77, 5056.76,
        5130.31, 4971.95, 5036.65, 5262.43, 5230.2, 5282.73, 5318.87, 5281.42,
        5192.25, 5082.92, 5080.83, 5062.06, 5077.81, 5103.85, 5111.24, 5090.63,
        5416.47, 5240.18, 5246.14, 5259.16, 5194.97, 5171.87, 5136.13, 5153.32,
        5149.52, 5125.25, 5123.51, 5026.21, 5058.77, 5093.88, 5050.04, 5103.13,
        5113.26, 5048.21, 5049.74, 5035.92, 5012.2, 4999.17, 4974.12, 5000.61,
        4978.31, 4971.78, 5052.46, 5047.48, 5036.71, 5023.13, 5031.4, 5002.76,
        5020.79, 4973.49, 4929.53, 4611.54, 4521.98, 4533.02, 4580.27, 4534.81,
        4405.79, 4559.53, 4500.45, 4499.06, 4484.35, 4508.51, 4490.94, 4705.74,
        4628.33, 4487.37, 4424.89, 4452.44, 4449.62, 4418.44, 4438.65, 4438.29,
        4489.66, 4449.94, 4448.82, 4405.22, 4210.62, 4344.34, 4370.9, 4433.92,
        4486.97, 4450.18, 4408.56, 4466.72, 4466.54, 4414.82, 4313.8, 4338.03,
        4326.58, 4323.18, 4316.87, 4319.4, 4347.47, 4489.03, 4534.65, 4556.14,
        4718.28, 4519.04, 4508.89, 4484.43, 4395.16, 4474.58, 4447.96, 4437.02,
        4289.7, 4265.62, 4258.2, 4188.86, 4214.87, 4233.63, 4230.36, 4357.59,
        4249.43, 4256.02, 4254.96, 4374.87, 4435.14, 4533.42, 4856.53, 4878.91,
        4876.68, 4916.15, 4833.76, 4833.41, 4910.3, 4921.73, 4920.86, 5014.27,
        4885.22, 4981.2, 5053.58, 5129.09, 5176.74, 5112.6, 5144.1, 5696.3,
        5662.6, 5562.77, 5605.77, 5599.95, 5404.45, 5584.01, 5352.96, 5338.57,
        5323.9, 5388.21, 5505.81, 5283.73, 5616.79, 5619.65, 5641.46, 5665.8,
        5649.08, 5495.63, 5573.95, 5519.35, 5558.25, 5672.96, 5869.85, 5997.91,
        6060.75, 6206.55, 6076.31, 6391.93, 6430.09, 6401.69, 6423.4, 6047.75,
        5952.07, 6149.49, 6297.08, 6068.72, 6063.11, 6035.75, 6019.02, 5924.06,
        5962.98, 6106.12, 6291.75, 6246.7, 6146.52, 6215.5, 6060.42, 6432.49,
        6412.01, 6206.42, 6223.28, 6187.78, 6349.46, 6416.99, 5999.06, 6174.8,
        6114.28, 6086.26, 6028.98, 6358.77, 6367.67, 6929.92, 7114.41, 6796.64,
        7013.08, 6824.49, 6838.48, 7054.92, 6878.27, 6720.28, 6775.7, 6723.87,
        6697.56, 6312.5, 6056.24, 6042.55, 6042.63, 6169.39, 6165.18, 6118.75,
        6287.5, 6433.12, 6217.65, 6248.39, 6280.59, 6252.06, 6224.21, 6241.81,
        6478.79, 6597.93, 6594.48, 6787.44, 6789.54, 6801.48, 7222.27, 7170.07,
        7540.39, 7652.97, 7586.69, 7577.14, 7834.11, 8055.84, 8147.07, 7981.54,
        8127.59, 7950.83, 8128.9, 8439.08, 8663.72, 8834.19, 9086.29, 9038.09,
        9338.94, 9259.49, 9449.42, 9815.72, 9538.34, 10373.19, 10491.76,
        10545.81, 10611.63, 10401.14, 11065.12, 10744.14, 10834.77, 10547.79,
        10145.72, 9678.37, 9916.38, 9581.32, 8735.28, 9576.28, 9474.93, 8941.77,
        9186.32, 9361.6, 9824.6, 9806.3, 9561.84, 9702.04, 9580.17, 9538.04,
        9873.7, 9532.08, 8972.08, 9027.08, 9009.99, 9016.42, 9189.85, 9305.73,
        10122.43, 9571.49, 9624.56, 9535.94, 8756.12, 8211.71, 8586.76, 8505.53,
        8415.15, 8183.9, 8351.9, 8350.51, 8544.08, 8583.82, 8755.68, 8746.49,
        8520.15, 8595.22, 8520.69, 8827.49, 8951.05, 8746.49, 8188.04, 8077.73,
        8153.5, 8448.88, 8452.69, 8519.57, 8383.02, 8260.2, 8076.7, 8308.35,
        7903.65, 7932.02, 7975.48, 7998.06, 7860.2, 8196.85, 7996.07, 8408.04,
        8481.3, 8370.13, 9825.17, 10374.22, 10186.93, 10366.6, 10166.34,
        10167.1, 10440.21, 10575.67, 10508.05, 10280.48, 10193.71, 10256.11,
        10396.14, 10288.53, 10278.23, 10375.82, 10474.75, 10369.13, 10051.22,
        10001.26, 10085.71, 9995.65, 9522.26, 9715.12, 9471.09, 9479.88,
        9712.75, 9870.47, 9585.93, 9467.01, 9675.7, 9573.75, 9584.74, 9527.43,
        9317.73, 9065.46, 9257.15, 9195.13, 9388.04, 9178.76, 9185.14, 9386.39,
        9367.04, 9315.77, 8982.01, 8508.97, 8098.89, 8335.63, 7990.79, 8227.7,
        8322.98, 8435.11, 8440.66, 8506.76, 8682.59, 8829.4, 9446.48, 9326.59,
        9235.49, 9339.97, 9543.32, 9559.25, 9611.73, 9379.67, 9472.72, 9056.16,
        8609.48, 8921.26, 8909.1, 8867.05, 9056.53, 8919.58, 8822.46, 8729.62,
        8102.0, 7914.01, 7404.79, 6672.02, 6733.53, 6410.0, 7365.34, 7068.11,
        7089.79, 6984.23, 7334.47, 7381.17, 7263.15, 7008.41, 7061.31, 7096.62,
        7199.2, 7134.46, 7037.07, 7156.17, 7138.73, 7507.31, 7485.39, 7537.73,
        7308.03, 6692.7, 6888.69, 6866.28, 6871.5, 6846.69, 6545.62, 6923.23,
        6674.21, 6699.01, 6451.37, 6010.18, 6160.75, 6238.62, 6412.44, 6502.75,
        6365.19, 6422.32, 6655.73, 6574.48, 6382.68, 6214.09, 6359.21, 6364.54,
        6724.28, 6938.03, 7006.88, 7045.08, 7198.52, 7252.49, 6997.41, 7175.04,
        7359.45, 7276.48, 7224.31
      ],
      BNB: [
        10000.0, 10275.72, 10387.17, 10023.47, 9974.58, 9305.83, 9317.56,
        8760.27, 8506.07, 8609.7, 8273.37, 9026.21, 9485.73, 9325.38, 9587.41,
        9696.91, 9763.4, 9256.94, 9247.16, 9159.17, 8756.35, 7465.78, 6941.73,
        7260.46, 7195.94, 7471.64, 7291.75, 7573.33, 7508.81, 7622.21, 7356.27,
        7354.32, 7543.99, 7217.44, 7217.44, 7737.58, 8101.3, 8046.54, 8502.15,
        8023.08, 8277.28, 8201.02, 7706.29, 7884.24, 7815.8, 7888.15, 8410.25,
        8371.14, 7919.44, 7774.74, 7800.16, 7524.44, 7104.02, 7252.64, 7254.59,
        6937.82, 7326.94, 7303.49, 7102.07, 7692.61, 7970.28, 8007.43, 7868.6,
        7338.68, 7520.53, 7442.32, 7469.69, 7436.45, 7661.32, 7280.02, 7303.49,
        7332.81, 7082.52, 7227.22, 7276.11, 7487.29, 7653.5, 7735.63, 7802.11,
        7686.74, 7755.18, 7940.95, 7948.76, 8093.47, 8025.03, 8103.25, 8388.73,
        8498.24, 8470.87, 8664.45, 8437.62, 8705.52, 8629.25, 8818.92, 8756.35,
        8779.82, 8275.32, 8537.35, 8246.0, 8289.01, 8214.7, 7747.36, 8066.1,
        8283.15, 8103.25, 8142.35, 8152.13, 8093.47, 8169.73, 8228.4, 8201.02,
        7915.53, 7960.5, 7903.79, 7831.44, 7894.01, 7545.96, 7622.21, 7983.96,
        7690.66, 7538.13, 7598.74, 7600.71, 7487.29, 7862.73, 7356.27, 7430.59,
        7088.39, 6978.88, 6028.55, 6138.06, 5295.27, 5215.1, 5784.12, 5766.52,
        6052.01, 5840.83, 5960.11, 5725.46, 5958.16, 5917.09, 6120.46, 6224.09,
        6261.25, 6415.72, 6437.23, 6063.74, 5934.69, 6007.04, 5981.62, 6296.44,
        6300.35, 5883.84, 6024.64, 5827.14, 5897.54, 5860.39, 5789.99, 5658.97,
        5668.76, 5658.97, 5621.82, 5342.2, 5039.11, 4301.91, 4266.72, 4518.96,
        4098.56, 4227.61, 3887.37, 4211.97, 4219.79, 4342.98, 4227.61, 4470.08,
        4710.6, 4669.53, 4608.91, 4583.5, 4440.75, 4323.43, 4170.91, 4247.16,
        4280.41, 4274.54, 4501.36, 4560.03, 4665.63, 4710.6, 4732.11, 4751.66,
        4579.58, 4417.29, 4344.94, 4429.01, 4636.29, 4693.0, 4904.18, 4892.45,
        5078.22, 5281.58, 5043.02, 5172.08, 5164.25, 5066.48, 5117.32, 4898.32,
        4829.88, 5263.98, 5434.1, 5809.54, 5658.97, 5543.61, 5531.87, 5565.12,
        5864.29, 6079.39, 6184.98, 6198.68, 6341.41, 6337.51, 6378.57, 6400.08,
        6317.95, 6353.15, 6360.97, 6233.86, 6212.36, 6179.11, 5977.71, 5915.14,
        5482.99, 5520.14, 5909.27, 5866.24, 5844.74, 5799.76, 5897.54, 5529.92,
        5408.69, 5461.47, 5561.21, 5531.87, 5482.99, 5434.1, 5426.29, 5426.29,
        5424.32, 5396.95, 5187.72, 5434.1, 5494.72, 5727.42, 5817.36, 5739.14,
        5705.91, 5420.42, 5469.3, 5281.58, 5365.67, 5461.47, 5207.27, 5305.05,
        5222.92, 5136.88, 5371.53, 5404.77, 5400.86, 5334.37, 5371.53, 5332.42,
        5502.54, 5512.32, 5514.27, 5543.61, 5584.67, 5588.59, 5782.17, 5746.97,
        5555.34, 5526.01, 5402.82, 5426.29, 5326.55, 5297.22, 5295.27, 5340.24,
        5262.02, 5248.34, 5334.37, 5379.35, 5342.2, 5314.82, 5260.07, 5275.72,
        5285.49, 5396.95, 5350.02, 5578.8, 5694.17, 5592.49, 5838.87, 5917.09,
        6106.76, 6423.55, 6359.01, 6253.43, 6501.76, 6888.93, 6840.05, 6824.4,
        6507.63, 6175.21, 5263.98, 5979.66, 5647.24, 5533.82, 5406.72, 5391.09,
        5387.17, 5316.77, 5250.29, 5344.15, 5338.29, 5170.12, 4966.76, 5211.19,
        5795.86, 5877.98, 5868.21, 6069.61, 6138.06, 5727.42, 5809.54, 5876.03,
        5725.46, 5707.86, 5684.39, 5696.13, 5635.51, 5647.24, 5555.34, 5664.84,
        5590.54, 5629.64, 5582.72, 5410.64, 5312.87, 5238.57, 5029.33, 4501.36,
        4696.91, 4913.96, 4698.86, 4908.1, 4822.06, 4802.5, 4812.28, 4779.03,
        4749.71, 4743.84, 4814.23, 4759.48, 4804.46, 4794.68, 4816.2, 4786.86,
        4810.33, 4810.33, 5039.11, 5023.47, 5080.17, 5103.64, 5314.82, 5346.11,
        5412.59, 5482.99, 5610.09, 5731.32, 5967.93, 5921.01, 5860.39, 5881.89,
        5676.57, 5746.97, 5928.82, 5889.71, 5897.54, 5979.66, 5911.23, 5962.06,
        5954.24, 6005.08, 5960.11, 6220.18, 5983.58, 6112.63, 6200.63, 6386.39,
        6464.61, 6472.43, 6415.72, 6386.39, 6499.8, 6405.95, 5948.38, 5967.93,
        6077.43, 6181.08, 5696.13, 5788.04, 6179.11, 6010.95, 6132.19, 6184.98,
        6120.46, 6134.14, 6083.3, 6077.43, 6032.46, 5885.81, 5897.54, 6028.55,
        5956.2, 5919.04, 5905.36, 5870.16, 5655.07, 5633.56, 5651.16, 5625.74,
        5623.77, 5619.87, 5400.86, 5416.5, 5355.89, 5563.16, 6046.15, 6044.19,
        5973.79, 6468.52, 6583.88, 6493.93, 6619.08, 6576.07, 6558.47, 6286.66,
        6419.63, 6290.58, 6292.53, 6427.45, 6036.37, 6104.81, 6140.01, 6173.25,
        6198.68, 6165.43, 6118.5, 6008.99, 6081.34, 6151.74, 6108.73, 6079.39,
        6073.53, 6122.41, 6169.34, 6341.41, 6245.6, 6341.41, 6443.1, 6527.18,
        6838.09, 6662.1, 6705.12, 6349.23, 6204.54, 6269.06, 6495.9, 6462.65,
        6480.25, 6591.71, 6450.92, 6503.72, 6335.55, 6302.31, 6603.45, 6425.5,
        6312.08, 6388.35, 6321.86, 6396.17, 6310.13, 6310.13, 6128.28, 6091.13,
        6165.43, 5993.36, 6008.99, 6093.08, 6098.94, 6138.06, 6083.3, 6132.19,
        6069.61, 6042.24, 6065.71, 5997.26, 6050.06, 6128.28, 5977.71, 5952.29,
        5999.22, 5995.31, 6136.1, 6079.39, 6096.99, 5987.49, 5977.71, 6012.91,
        5989.44, 5983.58, 5375.44, 5516.24, 5048.88, 5144.7, 5105.6, 4679.31,
        4597.18, 4513.1, 4720.38, 4620.65, 4624.56, 4677.36, 4802.5, 4757.53,
        4745.8, 4781.0, 4884.63, 4696.91, 4784.9, 4616.74, 4675.4, 4618.7,
        4648.03, 4515.06, 4558.08, 4712.55, 4843.57, 4825.97, 4818.15, 4747.75,
        4673.45, 4569.81, 4605.01, 4616.74, 4581.54, 4767.3, 4865.08, 4767.3,
        4974.58, 4816.2, 4917.87, 4782.95, 4765.35, 4704.73, 4714.51, 4749.71,
        4763.4, 4728.2, 4730.15, 4663.67, 4648.03, 4677.36, 4698.86, 4736.02,
        4736.02, 4728.2, 4724.28, 4818.15, 4718.42, 4714.51, 4722.33, 4753.62,
        4753.62, 4730.15, 4796.63, 4767.3, 4716.46, 4677.36, 4702.78, 4698.86,
        4700.82, 4634.33, 4534.61, 4323.43, 4227.61, 4235.43, 4243.26, 4123.97,
        4080.96, 4227.61, 4254.99, 4262.81, 4231.52, 4268.68, 4272.59, 4440.75,
        4364.49, 4243.26, 4168.94, 4190.46, 4186.54, 4202.19, 4192.41, 4206.11,
        4249.12, 4194.37, 4194.37, 4159.17, 4032.07, 4139.62, 4159.17, 4159.17,
        4196.32, 4204.14, 4233.48, 4237.39, 4245.21, 4182.64, 4122.02, 4131.79,
        4110.29, 4102.46, 4104.42, 4137.66, 4141.57, 4204.14, 4204.14, 4206.11,
        4282.36, 4194.37, 4174.81, 4174.81, 4125.93, 4178.73, 4149.39, 4129.84,
        4026.21, 4082.91, 4034.02, 4014.47, 4028.16, 4037.94, 4094.64, 4208.06,
        4131.79, 4122.02, 4112.24, 4159.17, 4200.24, 4260.86, 4495.5, 4436.84,
        4352.76, 4376.23, 4376.23, 4411.42, 4454.44, 4460.31, 4423.16, 4440.75,
        4509.19, 4503.33, 4649.98, 4779.03, 4984.35, 4812.28, 4820.1, 4855.3,
        4913.96, 4910.05, 4859.21, 4722.33, 4739.93, 4943.3, 4749.71, 4784.9,
        4775.13, 4782.95, 4972.63, 4399.69, 4620.65, 4573.71, 4550.26, 4565.9,
        4544.39, 4429.01, 4487.68, 4446.61, 4444.66, 4462.26, 4470.08, 4470.08,
        4534.61, 4507.23, 4495.5, 4540.48, 4659.76, 4675.4, 4687.13, 4786.86,
        4986.31, 4955.03, 4960.9, 4800.55, 4792.73, 4714.51, 4700.82, 4929.6,
        5070.4, 5307.0, 5320.69, 5314.82, 5156.43, 5228.78, 5750.88, 6212.36,
        6360.97, 6079.39, 6169.34, 6077.43, 6132.19, 6098.94, 6190.85, 6290.58,
        6188.89, 6010.95, 5895.58, 5981.62, 5877.98, 5995.31, 6008.99, 5772.39,
        5915.14, 5930.78, 6184.98, 6173.25, 6077.43, 6112.63, 6126.32, 6196.71,
        6253.43, 5989.44, 5813.46, 5725.46, 5713.72, 5909.27, 5979.66, 5950.33,
        6063.74, 6052.01, 5891.67, 5860.39, 5891.67, 5866.24, 5977.71, 5874.07,
        5932.74, 6022.68, 6239.73, 6325.78, 6327.73, 6272.98, 6421.58, 6347.28,
        6527.18, 6869.38, 7029.72, 6902.62, 6908.49, 6908.49, 6935.87, 7332.81,
        7524.44, 7319.12, 7469.69, 7631.99, 7851.0, 7743.45, 8056.31, 7747.36,
        7944.86, 7995.7, 8114.98, 8193.2, 7665.23, 8341.8, 9184.59, 9374.26,
        9544.39, 10179.9, 10211.19, 10492.77, 12031.68, 11808.77, 11937.82,
        11460.7, 11271.03, 10827.14, 9960.9, 10874.07, 10817.36, 10590.54,
        10915.14, 11157.61, 11509.58, 11351.2, 11247.56, 11439.18, 12088.39,
        11808.77, 11845.92, 11300.35, 10907.31, 11012.91, 11376.61, 11353.15,
        11499.8, 11380.53, 11533.05, 11396.17, 11888.93, 11857.65, 11628.86,
        10850.61, 11071.57, 10895.58, 10574.89, 10520.14, 10774.34, 10885.81,
        11122.41, 11345.33, 11828.32, 11912.4, 11853.74, 11990.62, 11699.25,
        11624.95, 11742.28, 11626.9, 11298.4, 10944.46, 11003.13, 11482.21,
        11441.15, 11525.23, 11552.6, 11366.83, 11493.93, 11707.08, 11458.75,
        11589.75, 11638.64, 11576.07, 11069.61, 11431.36, 11122.41, 11349.24,
        11302.31, 11255.38, 11578.02, 12090.34, 12025.82, 11693.4, 11773.57,
        11759.88, 11750.1, 11808.77, 11785.3, 11664.06, 11634.73, 11626.9,
        11754.01, 11773.57, 12280.02, 13343.77, 13605.79, 13916.7, 13394.6,
        13349.63, 13167.78, 12086.43, 11800.94, 12127.49, 11765.74, 11763.78,
        11896.75, 11941.73, 11853.74, 11523.27, 11695.35, 11572.15, 11462.65,
        11542.83, 11341.41, 11030.51, 11288.62, 11198.68, 11345.33, 11093.08,
        11132.19, 11433.32, 11308.18, 11339.46, 10893.63, 10256.16, 9708.65,
        10332.42, 9636.29, 9994.13, 10082.13, 10240.52, 10263.98, 10420.42,
        10434.1, 10651.16, 11380.53, 11194.76, 11130.23, 11188.89, 11605.4,
        11601.48, 11742.28, 11560.42, 11392.26, 11216.26, 11186.94, 11378.57,
        11419.63, 11386.39, 11290.58, 11388.35, 11314.04, 11259.29, 10606.18,
        10322.65, 9849.43, 9139.62, 9526.79, 9309.74, 10148.62, 9958.93,
        10238.57, 9910.05, 10058.67, 10258.12, 10248.34, 10170.12, 10168.17,
        10586.62, 10486.9, 10928.82, 11218.23, 11143.92, 11468.52, 11587.8,
        11286.66, 11302.31, 10727.42, 10383.27, 10508.41, 10484.94, 10471.25,
        10434.1, 9947.2, 10320.69, 10238.57, 9990.22, 9814.23, 9466.18, 9614.78,
        9798.6, 10152.52, 10179.9, 10363.71, 10602.27, 10913.18, 10805.63,
        10960.11, 10369.57, 10664.84, 10799.77, 11067.66, 11110.68, 11491.98,
        11505.67, 11855.69, 11886.98, 11480.25, 11654.28, 11906.53, 11744.23,
        11677.75
      ],
      SOL: [
        10000.0, 10427.68, 10402.38, 10005.89, 9937.06, 8917.0, 8940.53,
        8045.77, 8486.39, 8319.9, 7936.95, 8250.49, 8948.76, 8628.16, 8644.04,
        8735.22, 8695.81, 8183.43, 8286.96, 8061.06, 7768.11, 6486.27, 5483.26,
        5653.87, 5419.74, 5500.91, 5393.85, 5196.78, 5315.61, 5611.51, 5466.79,
        5875.65, 6465.09, 5952.71, 5917.99, 6470.39, 6749.81, 6635.1, 6946.88,
        6652.74, 6763.34, 6359.79, 5622.68, 5655.63, 5514.45, 5696.81, 6038.59,
        6033.89, 5533.86, 5285.6, 5333.26, 5470.91, 5015.0, 5005.59, 5093.24,
        5129.13, 5573.27, 5336.79, 5029.12, 5787.4, 5759.16, 5945.65, 5631.51,
        5203.24, 5297.96, 5043.24, 4838.52, 4809.1, 5169.13, 4906.76, 4775.58,
        4815.58, 4605.57, 4752.63, 4900.88, 5055.59, 5205.01, 5229.13, 5438.56,
        5260.9, 5219.14, 5382.67, 5549.74, 6022.71, 5766.22, 5962.7, 6203.31,
        6442.74, 6517.44, 7066.3, 7348.08, 8034.01, 7896.35, 8092.25, 7771.64,
        7567.51, 6775.69, 6973.35, 6502.74, 6525.1, 6556.27, 5879.17, 6001.53,
        6194.48, 5914.47, 5943.88, 6005.06, 5999.18, 6026.24, 6345.08, 6263.31,
        5971.53, 5927.41, 5965.65, 5854.47, 5923.88, 5668.57, 5762.1, 5760.34,
        5533.86, 5021.48, 5241.49, 5151.48, 5049.12, 5417.97, 4934.41, 4829.69,
        4579.68, 4461.44, 3858.46, 3832.0, 2810.75, 2543.1, 2949.0, 3073.12,
        3396.09, 3197.25, 3326.67, 2993.71, 3021.95, 2925.46, 2957.82, 3089.01,
        2899.0, 2919.0, 2864.28, 2588.97, 2431.91, 2588.97, 2638.39, 2793.69,
        2710.16, 2373.08, 2410.73, 2253.66, 2254.25, 2303.67, 2501.33, 2323.67,
        2294.84, 2350.13, 2217.19, 2036.0, 1841.87, 1598.91, 1678.33, 2007.77,
        1780.69, 1819.52, 1863.05, 2040.71, 2050.71, 2156.01, 2051.88, 2220.13,
        2484.27, 2471.91, 2338.96, 2276.6, 2076.0, 2008.36, 1911.29, 1937.17,
        1970.12, 1958.94, 2146.01, 2117.77, 2165.43, 2253.07, 2266.02, 2246.61,
        2163.66, 1972.46, 1942.47, 2008.36, 2161.89, 2224.25, 2341.91, 2304.85,
        2412.49, 2663.1, 2496.03, 2516.62, 2414.85, 2350.72, 2416.61, 2223.66,
        2113.65, 2344.85, 2547.2, 2514.85, 2597.8, 2501.33, 2433.67, 2422.5,
        2281.9, 2268.96, 2377.2, 2379.55, 2397.79, 2483.68, 2374.84, 2468.38,
        2508.38, 2633.68, 2736.05, 2654.27, 2546.04, 2533.09, 2374.26, 2394.85,
        2129.54, 2064.24, 2151.89, 2050.13, 2086.6, 2083.65, 2075.41, 1900.11,
        1847.17, 1841.28, 1886.58, 1869.53, 1878.93, 1850.11, 1833.63, 1829.52,
        1875.99, 1894.23, 1832.47, 1924.82, 1972.46, 2041.3, 2076.59, 2048.95,
        2193.66, 1964.24, 2006.0, 1943.65, 1887.76, 1976.58, 1840.11, 1932.46,
        1865.99, 1802.46, 1904.82, 1985.41, 1969.53, 1899.52, 1966.59, 1931.88,
        1956.0, 1988.36, 1930.71, 1915.4, 1895.4, 1933.05, 2000.71, 2003.65,
        1961.88, 1947.18, 1911.88, 1924.82, 1886.58, 1840.7, 1831.29, 1801.87,
        1761.28, 1744.81, 1780.69, 1824.23, 1775.4, 1716.57, 1661.27, 1658.33,
        1647.75, 1717.16, 1673.04, 1823.05, 1844.23, 1804.22, 1891.88, 1926.0,
        1935.41, 1918.35, 1904.82, 1797.75, 1817.75, 1993.05, 2173.65, 1954.82,
        1730.69, 1383.02, 836.52, 1057.71, 940.06, 877.11, 804.75, 807.69,
        835.93, 842.4, 805.93, 774.75, 757.69, 724.74, 694.16, 721.81, 830.05,
        858.29, 831.81, 827.11, 847.11, 785.34, 789.46, 817.11, 794.75, 807.1,
        783.57, 802.98, 815.34, 828.87, 797.11, 804.16, 797.7, 798.87, 792.99,
        782.4, 810.64, 835.93, 823.57, 734.16, 731.22, 731.81, 693.57, 722.4,
        707.69, 693.57, 692.39, 671.8, 668.86, 657.69, 649.45, 571.8, 538.86,
        577.68, 585.32, 586.5, 663.57, 786.51, 797.11, 790.05, 786.51, 767.1,
        806.52, 959.47, 958.3, 952.41, 964.17, 1063.0, 1389.5, 1361.84, 1391.26,
        1364.2, 1244.19, 1260.66, 1483.62, 1459.49, 1424.2, 1434.21, 1352.44,
        1423.61, 1430.09, 1418.31, 1403.61, 1545.39, 1397.72, 1414.21, 1467.15,
        1445.97, 1453.03, 1448.32, 1387.14, 1353.02, 1391.26, 1361.84, 1192.43,
        1176.54, 1222.42, 1269.49, 1202.42, 1276.55, 1398.31, 1318.31, 1363.61,
        1387.73, 1482.44, 1543.03, 1461.85, 1418.31, 1401.85, 1341.84, 1306.55,
        1356.56, 1335.38, 1302.43, 1318.31, 1297.73, 1257.73, 1218.89, 1238.31,
        1218.31, 1179.48, 1080.65, 1007.71, 1064.18, 1055.95, 1166.54, 1208.31,
        1228.9, 1131.83, 1154.78, 1246.54, 1266.54, 1294.2, 1318.9, 1321.84,
        1261.25, 1296.55, 1208.9, 1196.55, 1237.14, 1165.36, 1202.42, 1242.42,
        1201.83, 1258.89, 1245.37, 1208.9, 1203.01, 1232.43, 1230.66, 1211.25,
        1205.36, 1173.01, 1197.72, 1225.95, 1364.79, 1393.03, 1434.79, 1453.62,
        1418.9, 1495.39, 1458.32, 1455.38, 1345.96, 1307.72, 1249.49, 1285.96,
        1263.6, 1256.55, 1276.55, 1247.72, 1308.9, 1368.32, 1355.97, 1361.25,
        1292.43, 1309.49, 1304.19, 1274.2, 1344.2, 1288.9, 1303.6, 1214.78,
        1212.43, 1237.73, 1186.54, 1218.31, 1229.49, 1232.43, 1241.83, 1214.78,
        1234.19, 1203.01, 1197.72, 1190.66, 1156.54, 1153.01, 1178.9, 1131.83,
        1131.24, 1140.06, 1198.31, 1232.43, 1210.66, 1249.49, 1221.83, 1209.48,
        1254.19, 1243.6, 1299.48, 1175.96, 1199.48, 1093.01, 1113.6, 1026.53,
        925.93, 912.41, 894.17, 877.7, 847.69, 868.87, 902.99, 925.35, 904.76,
        939.47, 971.82, 1015.36, 977.71, 1002.41, 973.59, 1001.23, 957.71,
        980.06, 941.24, 1053.0, 1125.95, 1084.77, 1147.71, 1121.83, 1144.77,
        1107.71, 1194.19, 1264.78, 1288.9, 1258.89, 1251.25, 1294.79, 1293.02,
        1516.56, 1548.92, 1608.33, 1623.62, 1566.56, 1500.09, 1552.45, 1500.67,
        1514.8, 1481.26, 1451.85, 1377.15, 1362.43, 1490.09, 1478.32, 1464.79,
        1485.97, 1417.73, 1398.9, 1392.44, 1365.96, 1336.55, 1341.84, 1333.02,
        1367.73, 1355.38, 1417.73, 1432.44, 1447.73, 1445.38, 1470.09, 1428.91,
        1475.39, 1402.43, 1343.61, 1294.2, 1258.89, 1281.84, 1282.43, 1250.07,
        1196.55, 1266.54, 1231.84, 1197.72, 1195.37, 1223.6, 1207.13, 1285.37,
        1225.95, 1164.18, 1133.01, 1147.13, 1147.71, 1137.72, 1189.48, 1152.42,
        1172.42, 1153.01, 1146.54, 1078.89, 1033.59, 1059.48, 1083.59, 1114.19,
        1126.54, 1121.83, 1105.36, 1165.36, 1180.07, 1193.6, 1150.07, 1145.36,
        1141.83, 1147.71, 1139.48, 1114.19, 1121.24, 1167.13, 1193.01, 1260.07,
        1434.21, 1373.03, 1385.96, 1364.79, 1338.9, 1383.02, 1370.67, 1367.14,
        1299.48, 1303.02, 1291.84, 1255.37, 1281.84, 1295.96, 1286.55, 1420.08,
        1410.67, 1390.08, 1459.49, 1607.74, 1748.93, 1721.28, 1906.59, 1783.64,
        1892.47, 1933.05, 1857.75, 1860.7, 1938.94, 2056.0, 2231.89, 2488.97,
        2354.84, 2337.79, 2494.85, 2407.79, 2428.96, 2533.09, 2544.27, 2670.74,
        3330.79, 3423.73, 3357.85, 3073.12, 3363.14, 3830.23, 3416.68, 3454.33,
        3431.97, 3571.98, 3320.19, 3048.41, 3411.97, 3343.72, 3345.49, 3450.21,
        3401.96, 3220.2, 3401.96, 3480.2, 3486.09, 3536.1, 3697.86, 3749.64,
        3584.33, 3555.51, 3690.22, 3994.94, 4325.55, 4346.73, 4363.79, 4102.01,
        3984.94, 4194.36, 4454.38, 4393.79, 4352.02, 4247.31, 4384.97, 4284.37,
        4802.05, 5563.27, 5709.75, 6400.97, 6586.28, 7043.95, 6568.63, 6306.85,
        6103.3, 6175.66, 5989.18, 5913.88, 6358.61, 6352.14, 5812.7, 6163.89,
        5836.23, 5494.45, 5252.66, 5765.05, 5855.06, 6075.06, 5900.35, 5310.31,
        5657.4, 5686.81, 5542.09, 5773.29, 5953.3, 5568.57, 5496.8, 5477.98,
        5382.08, 4939.71, 4863.82, 5147.36, 5133.83, 5429.73, 5511.5, 5579.16,
        5936.23, 6086.24, 5747.4, 5713.28, 5902.11, 5762.1, 5633.87, 5573.86,
        5733.28, 5939.17, 6037.41, 6346.26, 6435.08, 6326.26, 6563.92, 6590.97,
        6889.82, 6589.81, 6453.91, 6425.68, 6635.69, 6592.15, 6403.32, 6154.48,
        6057.41, 5872.7, 6125.07, 6089.19, 6451.55, 6380.38, 6813.93, 7273.96,
        7686.34, 7547.5, 7703.39, 7693.99, 7171.01, 7687.51, 8514.62, 8476.97,
        8526.39, 8343.44, 8698.16, 8727.58, 9615.87, 10172.37, 10832.4,
        10648.28, 11833.05, 11656.57, 9956.48, 11172.42, 10489.45, 10018.82,
        10280.02, 10881.23, 11234.19, 11309.49, 10998.89, 11173.01, 11308.9,
        11540.69, 11801.88, 11344.21, 10830.06, 10950.07, 10723.58, 10259.43,
        10506.51, 10504.74, 10655.92, 10235.32, 10233.55, 10162.36, 8912.88,
        8295.19, 8859.94, 8232.26, 8094.01, 7916.94, 8405.79, 8451.09, 8775.22,
        8762.29, 9296.43, 9134.66, 8642.87, 8546.39, 8189.31, 8275.78, 8181.07,
        8096.95, 7493.39, 7846.35, 8161.66, 8434.02, 8588.75, 8539.33, 9094.07,
        8825.23, 8325.79, 8997.59, 8578.15, 8576.98, 8425.79, 8642.87, 8415.79,
        9169.95, 9345.25, 9921.17, 10119.42, 10084.13, 10918.29, 10500.62,
        10441.79, 10367.09, 9838.82, 9878.83, 9633.51, 10068.24, 9943.53,
        9979.41, 9835.88, 9774.11, 9807.05, 9605.86, 9732.93, 10048.24,
        10187.66, 10032.95, 9594.1, 9324.09, 9525.27, 9375.26, 8793.46, 9153.48,
        8716.4, 8431.09, 8557.56, 8847.59, 8460.5, 8096.95, 7924.59, 7878.12,
        7931.65, 7898.7, 7601.62, 7659.87, 8053.42, 8082.83, 8836.4, 8237.54,
        8239.31, 8649.93, 8635.21, 9101.72, 8315.78, 7792.23, 7896.94, 8463.44,
        7826.93, 8253.43, 8314.61, 8328.14, 8045.77, 8164.01, 8365.2, 8658.16,
        9340.56, 9407.61, 9177.01, 9410.56, 9929.41, 10199.42, 10821.23,
        10501.8, 10171.78, 10434.15, 10135.31, 10779.46, 10923.59, 10790.64,
        10783.58, 10549.45, 10196.49, 9851.76, 8988.76, 8402.85, 8279.31,
        7795.17, 8537.56, 8579.33, 9500.57, 9162.9, 9098.19, 8364.61, 8478.74,
        8653.45, 8491.68, 8452.86, 8212.26, 8325.79, 8505.8, 8446.97, 8426.38,
        8406.38, 8424.03, 8932.29, 9357.62, 9448.79, 9261.73, 8675.81, 8444.03,
        8225.19, 8100.48, 7964.59, 7595.16, 7929.88, 7617.51, 7886.94, 7622.21,
        7336.91, 7459.86, 7612.22, 7909.29, 7999.29, 7799.28, 8021.65, 8198.72,
        8068.71, 7758.11, 7689.87, 7748.11, 7732.22, 8378.14, 8655.22, 8738.75,
        8558.15, 8494.62, 9021.12, 8775.22, 9197.02, 9298.2, 9219.37, 9354.67
      ],
      XRP: [
        10000.0, 10213.33, 10315.77, 10045.8, 10002.42, 9181.63, 9445.58,
        9180.43, 9082.81, 9141.86, 8848.98, 9257.56, 9610.7, 9258.77, 9369.65,
        9419.07, 9390.15, 9152.71, 9121.37, 8959.87, 8842.95, 7636.49, 7064.0,
        7344.83, 7331.57, 7500.3, 7425.58, 7259.25, 7318.31, 7444.86, 7246.0,
        7337.59, 7572.62, 7262.87, 7295.41, 7720.86, 8039.05, 8096.9, 9948.18,
        10386.89, 10800.29, 10178.38, 9071.96, 9973.49, 9787.88, 9675.79,
        9985.53, 10121.73, 9363.63, 9462.46, 9852.96, 9533.57, 8754.97, 8557.31,
        8498.25, 8184.89, 9080.39, 9046.65, 8692.3, 9384.12, 9484.16, 9269.62,
        9053.88, 8595.88, 9081.6, 8921.3, 8720.02, 8665.79, 9174.4, 8934.56,
        9693.87, 9533.57, 9157.53, 9310.6, 9223.82, 9463.66, 9572.14, 9597.44,
        9824.04, 9752.92, 10078.35, 10116.91, 10063.88, 10169.94, 9930.1,
        10021.69, 10277.21, 10646.02, 10289.26, 10354.35, 9874.65, 9987.95,
        10009.64, 10194.05, 9983.13, 9910.81, 9249.12, 9492.59, 9117.76,
        9162.35, 9116.55, 8453.66, 8525.97, 8726.04, 8722.43, 9399.79, 9387.73,
        9243.11, 9234.67, 9304.57, 9086.42, 8763.41, 8673.01, 8580.21, 8452.46,
        8398.22, 7785.95, 7800.42, 7743.76, 7366.52, 7294.2, 7279.74, 7405.09,
        7278.53, 7781.13, 7118.23, 7280.95, 6929.01, 6880.8, 6074.49, 6088.95,
        4945.16, 4559.48, 5183.8, 5146.44, 5347.72, 5129.57, 5260.94, 4959.63,
        5031.94, 4963.24, 5011.45, 5081.35, 4911.41, 4934.31, 4919.86, 4770.4,
        4640.23, 4637.83, 4678.8, 5021.09, 5123.54, 4788.48, 4881.28, 4710.13,
        4727.01, 4780.04, 4840.3, 4901.78, 4849.94, 4828.25, 4649.87, 4400.39,
        4196.69, 3700.14, 3803.78, 4111.13, 3751.96, 3871.28, 3737.49, 3933.95,
        3888.16, 3999.04, 3923.11, 4023.14, 4424.49, 4410.03, 4342.54, 4270.22,
        4078.58, 3988.19, 3897.79, 3773.65, 3811.02, 3847.18, 3952.03, 3942.39,
        3999.04, 4120.76, 4121.97, 4155.72, 3941.19, 3780.89, 3756.78, 3862.84,
        3970.11, 4059.3, 4244.91, 4208.75, 4337.71, 4508.86, 4353.38, 4383.52,
        4334.1, 4329.28, 4330.48, 4146.08, 4044.83, 4317.22, 4513.68, 4476.32,
        4688.44, 4607.69, 4553.46, 4522.12, 4458.24, 4459.45, 4530.56, 4507.66,
        4508.86, 4552.25, 4431.72, 4582.38, 4569.12, 4558.27, 4547.43, 4536.58,
        4512.48, 4524.53, 4542.61, 4551.04, 4046.04, 4068.94, 4159.34, 4108.71,
        4171.39, 4166.57, 4197.9, 4096.66, 4031.57, 3984.57, 3986.98, 3964.09,
        3973.72, 4003.86, 3986.98, 3966.49, 3980.96, 4001.45, 3892.97, 4071.35,
        4120.76, 4261.78, 4332.89, 4267.81, 4297.94, 4012.3, 4124.39, 3933.95,
        4275.04, 4523.32, 4401.6, 4645.05, 5018.68, 4723.4, 5927.45, 6354.1,
        5869.59, 5922.62, 5615.29, 5406.78, 5417.62, 5769.55, 5758.71, 5700.86,
        5453.78, 5553.82, 5787.63, 5961.19, 5929.85, 6251.65, 6191.4, 6432.45,
        6013.02, 5904.54, 5865.98, 5861.16, 5888.88, 5805.71, 5767.15, 5767.15,
        5632.16, 5483.91, 5418.83, 5526.1, 5611.67, 5650.24, 5499.58, 5542.97,
        5644.21, 5571.9, 5711.7, 5645.41, 5530.92, 5573.1, 5611.67, 5447.75,
        5480.29, 6025.07, 5999.76, 5765.94, 5569.48, 4822.22, 3993.01, 4781.25,
        4607.69, 4414.85, 4095.46, 4489.58, 4686.03, 4545.01, 4625.77, 4610.1,
        4631.8, 4389.54, 4370.26, 4557.07, 4579.97, 4843.93, 4940.34, 4757.14,
        4865.62, 4705.32, 4802.94, 4947.57, 4784.87, 4753.53, 4670.36, 4688.44,
        4694.47, 4684.83, 4614.92, 4752.32, 4692.06, 4655.9, 4583.59, 4661.92,
        4749.91, 4649.87, 4547.43, 4244.91, 4243.7, 4220.8, 4078.58, 4252.14,
        4168.98, 4201.52, 4264.19, 4237.67, 4167.77, 4414.85, 4467.88, 4302.76,
        4114.74, 4146.08, 4106.31, 4080.99, 4201.52, 4148.49, 4179.82, 4089.43,
        4170.18, 4144.87, 4124.39, 4217.19, 4232.85, 4481.14, 4526.93, 4624.57,
        4757.14, 4647.47, 4666.75, 4687.24, 4601.67, 4740.27, 4941.55, 4861.99,
        4825.85, 5141.62, 4922.26, 4986.14, 4936.73, 4945.16, 4902.97, 4976.5,
        4737.86, 4909.0, 4994.58, 4951.19, 4965.65, 4993.37, 4807.77, 4778.84,
        4858.38, 4794.51, 4538.99, 4589.61, 4620.94, 4528.14, 4471.5, 4571.54,
        4821.02, 4667.95, 4764.37, 4757.14, 4667.95, 4786.06, 4717.37, 4758.35,
        4687.24, 4541.4, 4542.61, 4540.2, 4576.35, 4549.83, 4619.75, 4560.69,
        4523.32, 4479.93, 4438.95, 4473.9, 4595.64, 4665.54, 4507.66, 4466.68,
        4358.2, 4414.85, 4505.24, 4505.24, 4328.07, 4397.97, 4565.51, 4510.06,
        4719.78, 4536.58, 5565.87, 5072.92, 5368.2, 5092.2, 5352.54, 5427.26,
        5749.07, 6287.81, 6569.84, 6440.88, 6495.12, 6163.67, 6270.94, 6010.6,
        6073.28, 6111.85, 6054.0, 6181.75, 6093.77, 6103.42, 6214.3, 6229.96,
        6075.69, 6174.53, 6340.84, 6280.59, 6287.81, 6180.55, 6375.8, 5981.68,
        5699.65, 5374.23, 5681.57, 5577.92, 5559.84, 5649.03, 5527.3, 5600.82,
        5752.68, 5759.91, 5704.47, 5593.59, 5603.24, 5602.03, 5545.37, 5634.57,
        5510.42, 5473.07, 5131.98, 5141.62, 5191.04, 5058.45, 5185.01, 5115.1,
        5134.38, 5165.72, 5256.12, 5383.88, 5569.48, 5645.41, 5664.7, 5526.1,
        5564.66, 5611.67, 5470.65, 5469.44, 5632.16, 5687.6, 5828.61, 5945.52,
        6289.02, 6255.28, 6134.75, 6322.76, 6261.3, 6524.04, 6091.36, 6399.9,
        6243.22, 6339.65, 6479.45, 6140.77, 6235.99, 6320.36, 6256.48, 5768.36,
        5781.61, 5705.68, 5797.28, 5870.8, 5961.19, 5929.85, 6052.79, 5957.57,
        5979.27, 5865.98, 5912.98, 5782.81, 5810.53, 5604.43, 5715.32, 5735.81,
        5696.04, 5845.48, 5890.08, 5891.29, 5761.12, 5639.38, 5638.19, 5665.9,
        5650.24, 5750.28, 5740.63, 5677.96, 9764.98, 8658.55, 8605.52, 9093.65,
        8888.76, 9269.62, 9850.55, 9645.65, 9285.28, 9021.33, 8859.83, 8481.38,
        8570.57, 8665.79, 8605.52, 8580.21, 8588.65, 8454.86, 8422.32, 8482.59,
        8277.69, 8036.64, 7676.27, 7595.51, 7536.46, 7497.89, 7765.45, 7723.28,
        7626.85, 7625.65, 7571.41, 7549.72, 7624.44, 7337.59, 7079.67, 6150.42,
        6105.82, 6251.65, 6536.1, 6316.74, 6239.61, 6364.95, 6240.81, 6344.47,
        6320.36, 6299.87, 6303.49, 6533.69, 6362.55, 6169.7, 6000.96, 6011.81,
        6076.89, 6123.9, 6093.77, 6057.61, 6088.95, 6088.95, 6072.08, 6009.41,
        5700.86, 5816.56, 5835.84, 5925.03, 6060.02, 6029.89, 5937.09, 6090.16,
        6191.4, 6278.17, 6126.31, 6197.42, 6111.85, 6109.44, 6084.13, 6031.1,
        6033.51, 6126.31, 6281.79, 6222.73, 6330.0, 6168.5, 6363.74, 6428.83,
        6316.74, 6344.47, 6289.02, 6232.38, 6058.82, 6015.43, 5878.03, 5840.66,
        5862.37, 5861.16, 5875.62, 6013.02, 5920.22, 5893.69, 6129.93, 6269.73,
        6295.05, 6292.64, 6631.31, 6774.74, 6684.34, 6675.9, 6575.87, 6580.7,
        6715.68, 6970.0, 7209.84, 7349.65, 7277.33, 7385.8, 7429.2, 7857.06,
        8697.12, 8222.25, 8284.92, 7996.87, 7970.36, 8020.97, 8013.74, 7882.37,
        7587.08, 7814.87, 7413.52, 7421.96, 7355.67, 7505.13, 7385.8, 6996.51,
        7394.24, 7477.4, 7478.61, 7479.81, 7456.92, 7244.79, 7356.88, 7332.77,
        7297.82, 7373.75, 7446.07, 7538.87, 7507.53, 7471.37, 7659.39, 7730.5,
        8055.92, 8088.47, 7986.02, 7473.79, 7446.07, 7587.08, 7626.85, 7459.32,
        7467.76, 7389.42, 7374.96, 7311.08, 7455.71, 7517.18, 7524.4, 7466.55,
        7355.67, 7759.43, 7482.23, 7624.44, 7635.3, 7465.35, 7487.05, 7368.93,
        7555.74, 7560.56, 7027.85, 7083.28, 6918.16, 6847.05, 6636.13, 6990.48,
        6891.65, 7324.33, 7260.45, 6838.62, 6945.89, 7055.56, 6970.0, 6970.0,
        6874.78, 6661.45, 6589.13, 6630.11, 6608.41, 6377.0, 6210.68, 6223.94,
        6204.65, 6415.58, 6395.08, 6309.51, 6463.78, 6145.59, 6051.58, 6093.77,
        6158.85, 6269.73, 6072.08, 6120.29, 6119.08, 6173.32, 6197.42, 6338.44,
        6316.74, 6326.39, 6402.32, 6339.65, 6498.74, 6759.07, 6809.69, 6632.52,
        6750.64, 6821.75, 6802.46, 6601.18, 6551.77, 6437.27, 6577.07, 6560.2,
        6627.7, 7071.23, 6904.91, 7041.1, 7239.97, 7658.19, 7577.44, 7853.44,
        7098.96, 7356.88, 7611.19, 7449.68, 7493.07, 7233.94, 8747.75, 8282.52,
        8268.05, 8065.56, 7631.67, 7305.06, 7472.58, 7629.27, 7066.41, 7385.8,
        7694.34, 7333.98, 7501.51, 7632.88, 7753.4, 7630.47, 7405.09, 7534.05,
        7575.03, 7472.58, 7575.03, 7374.96, 7094.13, 6930.21, 7108.59, 7072.44,
        7161.63, 7142.34, 7459.32, 7502.71, 7438.84, 7330.36, 6568.64, 5732.2,
        6082.92, 6016.63, 5988.91, 5991.33, 6061.23, 6094.97, 6343.26, 6337.23,
        6733.76, 6589.13, 6337.23, 6339.65, 6316.74, 6234.78, 6186.58, 6214.3,
        6022.66, 6203.45, 6261.3, 6427.63, 6377.0, 6375.8, 6532.48, 6369.77,
        6227.56, 6279.38, 6046.76, 6097.39, 6019.04, 6078.1, 6033.51, 6242.02,
        6210.68, 6316.74, 6272.15, 6145.59, 6446.91, 6486.69, 6351.69, 6348.08,
        6454.14, 6526.46, 6383.03, 6454.14, 6383.03, 6323.97, 6263.71, 6256.48,
        6267.33, 6193.8, 6280.59, 6332.41, 6334.82, 6290.23, 6035.92, 5946.73,
        6005.78, 5986.51, 5788.84, 5920.22, 5776.79, 5722.55, 5933.48, 5897.32,
        6109.44, 5920.22, 5944.32, 5896.11, 5906.96, 5873.21, 5784.02, 5692.42,
        5733.39, 5680.36, 5734.6, 5685.19, 5691.22, 5747.86, 5755.1, 5844.29,
        5627.33, 5356.16, 5122.33, 5412.8, 5069.3, 5199.47, 5248.89, 5282.63,
        5395.93, 5717.73, 6310.71, 6364.95, 6455.35, 6950.71, 7632.88, 6859.1,
        6877.18, 7186.94, 7202.6, 7320.72, 7217.07, 7348.44, 7189.34, 7268.9,
        7241.17, 7217.07, 7261.66, 7596.72, 7642.52, 7212.24, 6760.27, 6691.58,
        6366.16, 5978.07, 6117.87, 7395.44, 7371.34, 7007.35, 7074.85, 6654.21,
        6819.33, 6942.27, 6851.88, 6756.66, 6800.05, 6816.92, 6886.83, 7195.37,
        7168.86, 7238.76, 7203.81, 7362.91, 7348.44, 7267.69, 7096.54, 6827.77,
        6873.57, 6755.46, 6825.36, 6838.62, 6573.46, 6843.44, 6785.59, 6747.01,
        6566.23, 6268.54, 6321.57, 6348.08, 6499.94, 6531.28, 6454.14, 6789.2,
        6898.88, 7180.91, 6945.89, 7010.97, 7056.77, 7007.35, 7106.18, 7078.46,
        7214.66, 7104.98, 7056.77, 7139.93, 7050.74, 7120.65, 7088.1, 7421.96,
        7718.45
      ],
      USDT: [
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000
      ],
      HODLIE: [
        10000, 10001.08, 9988.64, 9877.33, 9960.83, 9595.98, 9600.69, 9597.95,
        9596.73, 9619.63, 9492.47, 9508.71, 9683.0, 9518.82, 9527.37, 9561.64,
        9561.46, 9336.52, 9357.21, 9356.79, 9354.99, 9348.03, 9344.74, 9346.7,
        9346.29, 9360.85, 9327.62, 9208.93, 9253.27, 9356.68, 9311.91, 9496.87,
        9764.05, 9500.73, 9483.31, 9821.84, 9927.46, 9878.91, 10177.5, 10235.48,
        10337.06, 10188.11, 10097.13, 10098.09, 10097.7, 10098.06, 10240.56,
        10258.9, 9873.89, 9857.35, 9857.44, 9855.82, 9826.87, 9827.67, 9678.27,
        9572.43, 9763.44, 9631.68, 9376.13, 9533.71, 9645.9, 9636.78, 9466.34,
        9425.39, 9426.54, 9425.95, 9360.95, 9346.43, 9320.75, 9040.31, 8970.69,
        8912.9, 8826.0, 8861.82, 8750.49, 8898.68, 8992.61, 9168.92, 9175.47,
        9084.91, 9134.08, 9083.1, 9108.12, 9240.1, 9113.37, 9214.61, 9392.15,
        9484.24, 9515.56, 9520.89, 9618.58, 9917.53, 9860.71, 9944.32, 9942.29,
        9827.52, 9726.18, 9727.59, 9725.98, 9726.22, 9669.66, 9635.05, 9636.95,
        9638.26, 9588.51, 9761.5, 9758.41, 9721.59, 9635.22, 9709.69, 9659.72,
        9524.38, 9482.97, 9473.2, 9472.75, 9473.13, 9215.05, 9199.83, 9198.48,
        9141.83, 9074.65, 9031.55, 9031.52, 8916.85, 9137.74, 8847.35, 8861.52,
        8859.44, 8858.85, 8853.23, 8854.28, 8847.37, 8846.56, 8850.94, 8850.93,
        9114.61, 8945.82, 9063.18, 8762.2, 8728.15, 8672.21, 8703.89, 8765.54,
        8785.6, 8832.4, 8839.71, 8723.74, 8674.11, 8674.71, 8720.61, 9016.39,
        8922.78, 8601.71, 8684.11, 8683.82, 8684.31, 8657.56, 8564.05, 8368.48,
        8246.3, 8241.75, 8129.43, 8090.09, 8087.85, 8082.05, 8082.01, 8085.09,
        7735.94, 7795.67, 7853.66, 8063.58, 8081.9, 8219.09, 8046.93, 8225.43,
        8345.12, 8348.27, 8267.71, 8229.15, 7943.23, 7925.74, 7924.43, 7925.01,
        7925.29, 7976.34, 8219.5, 8253.49, 8391.12, 8526.6, 8532.92, 8502.98,
        8355.51, 8355.48, 8354.78, 8355.43, 8357.02, 8543.58, 8919.33, 8925.71,
        9420.35, 9574.22, 9465.34, 9434.12, 9377.94, 9344.48, 9509.19, 9306.98,
        9306.38, 9310.08, 9613.78, 9607.16, 9488.79, 9386.47, 9385.82, 9372.94,
        9349.54, 9439.09, 9488.46, 9494.85, 9518.86, 9720.86, 9577.49, 9912.41,
        9973.35, 10125.98, 10247.28, 10130.09, 10014.36, 10044.51, 10042.84,
        10027.52, 9975.2, 9975.49, 9978.61, 9957.41, 9944.88, 9929.95, 10029.88,
        9744.1, 9737.92, 9738.21, 9739.04, 9717.39, 9791.0, 9845.98, 9827.46,
        9726.96, 9732.02, 9667.48, 9553.36, 9359.4, 9362.72, 9614.97, 9759.61,
        9728.17, 9878.11, 9550.34, 9515.8, 9513.96, 9514.16, 9644.49, 9582.81,
        9717.51, 9907.08, 9758.37, 10343.73, 10527.08, 10321.03, 10342.06,
        10288.91, 10233.35, 10197.62, 10193.44, 10170.6, 10144.78, 10037.21,
        10005.49, 10178.78, 10151.83, 10162.6, 10296.31, 10271.14, 10369.4,
        10199.5, 10173.25, 10173.22, 10173.59, 10107.79, 10044.67, 10133.31,
        10164.24, 10090.14, 9990.69, 9981.85, 9982.0, 9974.49, 10163.38,
        10092.88, 10504.15, 10890.6, 10715.15, 10860.96, 11040.23, 10962.03,
        10896.45, 10906.82, 10867.09, 10933.45, 11097.36, 11209.5, 10836.52,
        10594.38, 10620.71, 10612.92, 10620.15, 10616.65, 10612.1, 10477.08,
        10444.9, 10506.85, 10432.54, 10463.35, 10460.89, 10485.74, 10344.62,
        10317.92, 10320.02, 10521.73, 10647.12, 10529.81, 10514.51, 10585.03,
        10432.72, 10433.36, 10772.77, 10684.04, 10737.39, 10633.94, 10625.21,
        10655.3, 10712.99, 10602.2, 10585.18, 10515.65, 10528.23, 10500.32,
        10497.62, 10671.05, 10636.98, 10568.57, 10180.86, 10122.63, 10124.76,
        10122.26, 10213.23, 10188.23, 10213.66, 10262.55, 10243.11, 10200.63,
        10189.76, 10251.34, 10165.53, 10064.33, 10040.26, 10032.59, 10025.18,
        10432.71, 11034.38, 11077.11, 11045.22, 11026.29, 11029.45, 11063.51,
        11720.77, 11703.18, 11675.37, 11729.45, 12001.18, 12479.51, 12401.31,
        12470.42, 12384.81, 12191.16, 12178.61, 12314.87, 12221.26, 12121.25,
        12140.2, 12028.47, 12071.75, 12059.23, 12060.79, 11985.85, 12122.94,
        11768.7, 11815.27, 11785.36, 11736.45, 11777.39, 11821.54, 11721.02,
        11712.45, 11713.68, 11639.32, 11245.74, 11267.15, 11268.04, 11268.87,
        11076.77, 11231.91, 11576.14, 11512.7, 11605.29, 11599.23, 11566.01,
        11725.15, 11517.2, 11411.66, 11374.53, 11288.2, 11296.98, 11298.1,
        11297.44, 11222.12, 11372.1, 11329.49, 11031.72, 11013.18, 11013.3,
        11021.53, 10942.78, 11002.52, 10912.31, 10825.18, 10824.66, 10827.04,
        10985.85, 11088.44, 11013.88, 11153.85, 11703.89, 11577.81, 11830.63,
        11691.95, 11687.17, 11396.98, 11560.05, 11419.01, 11420.41, 11496.39,
        11667.74, 11934.27, 12073.18, 12015.23, 12041.72, 12002.56, 11900.61,
        11811.22, 11809.79, 11943.39, 11819.04, 11804.74, 11785.46, 11792.83,
        12041.54, 12175.85, 12278.31, 12388.61, 12461.3, 12434.51, 12527.53,
        12367.87, 12299.43, 12051.97, 12050.48, 12050.75, 12052.71, 12040.22,
        12046.66, 12068.68, 12074.01, 12299.63, 12290.43, 12235.78, 12250.97,
        12038.05, 11993.9, 12018.71, 11903.85, 12213.32, 11931.08, 11929.66,
        11927.78, 11927.41, 11928.06, 11926.24, 11942.04, 11936.84, 11946.07,
        11994.0, 11926.27, 11953.57, 12055.3, 12101.6, 12111.18, 12037.48,
        12071.73, 12188.37, 12009.49, 12006.37, 12015.2, 12042.43, 12138.17,
        12026.85, 12162.19, 12147.46, 12092.75, 12189.35, 12133.85, 12283.18,
        12024.02, 12043.41, 11967.83, 11967.68, 11961.31, 11715.07, 11714.16,
        11713.22, 11498.29, 11311.5, 11309.14, 11276.8, 11320.91, 11285.23,
        11376.46, 11628.8, 12009.98, 11970.65, 12111.31, 12084.43, 12082.4,
        11892.02, 11855.84, 11778.22, 11746.44, 12006.46, 11868.06, 12087.46,
        12062.61, 12077.59, 11916.49, 11992.42, 12231.4, 12312.94, 12205.81,
        12181.46, 12207.66, 12151.88, 12833.35, 12493.33, 12475.51, 12648.12,
        12566.95, 12696.38, 12888.86, 12824.35, 12710.98, 12685.54, 12674.27,
        12496.48, 12496.29, 12496.92, 12481.42, 12445.17, 12498.92, 12400.02,
        12370.05, 12388.87, 12291.06, 12286.45, 12259.32, 12266.88, 12280.86,
        12241.25, 12413.59, 12451.69, 12490.78, 12477.07, 12472.66, 12371.16,
        12448.83, 12251.26, 12256.89, 12253.89, 12252.5, 12252.5, 12252.38,
        12174.54, 12140.96, 12148.85, 11990.08, 11985.63, 11981.58, 11946.39,
        11954.97, 12203.78, 12082.54, 11823.87, 11724.09, 11728.79, 11728.58,
        11702.65, 11687.29, 11561.62, 11521.0, 11441.28, 11441.69, 11428.67,
        11420.5, 11390.05, 11363.35, 11418.07, 11395.26, 11372.08, 11326.8,
        11289.08, 11341.71, 11383.26, 11270.14, 11306.99, 11294.44, 11270.01,
        11242.56, 11228.23, 11156.91, 11334.33, 11390.63, 11416.87, 11904.62,
        11732.96, 11770.91, 11717.17, 11654.0, 11670.61, 11664.99, 11658.22,
        11560.72, 11561.29, 11560.67, 11560.33, 11560.44, 11560.49, 11551.36,
        11941.81, 11906.0, 11852.3, 12044.33, 12439.17, 12794.74, 12733.74,
        13181.53, 13025.32, 13108.27, 13038.34, 12873.6, 12888.79, 12975.33,
        12960.41, 13316.16, 13801.55, 13579.44, 13542.29, 13831.97, 13735.52,
        13872.77, 13687.56, 13713.77, 13955.54, 14382.8, 14515.07, 14426.87,
        14033.03, 13876.11, 14523.75, 14006.39, 14056.85, 14015.55, 14022.11,
        13939.99, 13656.36, 13781.76, 13784.11, 13810.49, 13860.59, 13732.03,
        13504.5, 13505.21, 13438.93, 13443.22, 13503.09, 13662.1, 13807.7,
        13960.97, 14341.24, 14257.44, 14157.42, 14568.6, 14594.64, 14620.01,
        14438.65, 14563.65, 14540.07, 14656.44, 14596.03, 14554.79, 14491.77,
        14491.48, 14397.65, 14689.51, 15431.23, 15560.15, 16178.13, 16324.91,
        16695.01, 16334.51, 16610.25, 16696.0, 16293.26, 16296.62, 16311.92,
        16440.49, 16580.74, 16177.1, 16466.84, 16378.86, 16256.31, 16234.55,
        16656.03, 16524.47, 16875.64, 17099.89, 16725.75, 16984.11, 16848.62,
        16888.17, 16983.42, 16873.73, 16514.32, 16518.75, 16537.44, 16570.99,
        16492.54, 16490.15, 16488.96, 16377.42, 16691.45, 16772.48, 16665.22,
        17011.96, 17158.27, 16864.65, 16857.04, 16837.11, 16775.71, 16620.59,
        16478.58, 16687.09, 16838.72, 16837.36, 17175.73, 17274.08, 17328.16,
        17662.79, 17559.19, 18002.96, 18138.76, 18064.36, 17983.26, 17986.65,
        18185.93, 18287.07, 18113.57, 18267.52, 18153.8, 18294.79, 18636.02,
        18884.25, 19138.2, 19855.13, 19849.33, 19719.18, 19602.4, 19650.94,
        19620.62, 18779.17, 19588.54, 19708.47, 19722.72, 19764.33, 19697.7,
        19828.73, 19536.8, 19541.2, 19905.85, 20340.88, 20237.86, 20954.97,
        20858.08, 20146.81, 20155.71, 20312.83, 20224.96, 20438.37, 20424.81,
        20454.09, 20427.9, 20311.95, 20514.4, 20428.51, 20274.72, 20426.25,
        20162.31, 19711.45, 19712.29, 19670.49, 19665.36, 19814.5, 19833.47,
        20593.84, 20038.96, 20097.92, 20041.25, 19972.39, 19674.84, 19660.76,
        19659.05, 19656.17, 19600.1, 19579.35, 19646.18, 19896.57, 19892.04,
        20285.71, 20170.67, 19741.35, 19790.01, 19707.15, 19706.54, 19804.08,
        19583.9, 18955.06, 18952.43, 18935.62, 19147.05, 19260.52, 19251.12,
        19197.81, 19028.94, 18834.01, 18854.59, 18466.18, 18514.04, 18413.72,
        18417.49, 18230.76, 18867.09, 18727.82, 19102.06, 19227.44, 19207.22,
        19720.09, 20154.85, 19977.3, 20153.33, 19892.62, 19890.78, 19799.83,
        19937.47, 19874.88, 19787.92, 19650.66, 19650.48, 19651.34, 19559.92,
        19604.85, 19972.69, 20054.33, 20153.49, 19979.28, 19968.83, 19967.57,
        19959.36, 19571.05, 19573.71, 19570.65, 19502.19, 19629.4, 19785.84,
        19585.86, 19431.75, 19445.99, 19299.94, 19310.33, 19222.43, 19220.8,
        19218.04, 19175.85, 19200.64, 19782.75, 19337.6, 19337.73, 19652.21,
        19655.74, 19840.02, 19270.45, 19238.03, 19233.15, 19239.24, 18784.85,
        19126.53, 19118.36, 19245.73, 19252.74, 19179.02, 19659.96, 19702.54,
        20075.16, 20117.0, 20279.71, 19766.04, 19870.1, 20048.58, 20449.5,
        20250.63, 20043.87, 20143.34, 20032.54, 20211.48, 20301.12, 20149.27,
        20150.07, 20168.86, 20195.48, 19917.77, 19879.43, 19876.61, 19872.15,
        19866.12, 19870.27, 19935.58, 20310.17, 20122.99, 20121.22, 19930.28,
        19953.21, 20020.54, 19865.95, 19707.74, 19697.2, 19792.33, 19651.83,
        19593.78, 19587.99, 19635.73, 19512.5, 19564.62, 19517.27, 19557.81,
        19426.76, 19289.9, 19290.61, 19290.35, 19290.21, 19249.1, 19163.14,
        19268.74, 19077.27, 19024.16, 18828.97, 18813.81, 18815.19, 18817.02,
        18885.95, 18898.03, 18876.73, 18987.11, 19069.31, 18989.48, 18829.71,
        18592.57, 18628.59, 18533.82, 18701.91, 18788.23, 18882.34, 18934.08,
        19138.89, 19209.23, 18987.71, 19283.36, 19354.54, 19316.7, 19431.07
      ]
    },
    2022: {
      TIMESTAMP: [
        '2022-01-01',
        '2022-01-02',
        '2022-01-03',
        '2022-01-04',
        '2022-01-05',
        '2022-01-06',
        '2022-01-07',
        '2022-01-08',
        '2022-01-09',
        '2022-01-10',
        '2022-01-11',
        '2022-01-12',
        '2022-01-13',
        '2022-01-14',
        '2022-01-15',
        '2022-01-16',
        '2022-01-17',
        '2022-01-18',
        '2022-01-19',
        '2022-01-20',
        '2022-01-21',
        '2022-01-22',
        '2022-01-23',
        '2022-01-24',
        '2022-01-25',
        '2022-01-26',
        '2022-01-27',
        '2022-01-28',
        '2022-01-29',
        '2022-01-30',
        '2022-01-31',
        '2022-02-01',
        '2022-02-02',
        '2022-02-03',
        '2022-02-04',
        '2022-02-05',
        '2022-02-06',
        '2022-02-07',
        '2022-02-08',
        '2022-02-09',
        '2022-02-10',
        '2022-02-11',
        '2022-02-12',
        '2022-02-13',
        '2022-02-14',
        '2022-02-15',
        '2022-02-16',
        '2022-02-17',
        '2022-02-18',
        '2022-02-19',
        '2022-02-20',
        '2022-02-21',
        '2022-02-22',
        '2022-02-23',
        '2022-02-24',
        '2022-02-25',
        '2022-02-26',
        '2022-02-27',
        '2022-02-28',
        '2022-03-01',
        '2022-03-02',
        '2022-03-03',
        '2022-03-04',
        '2022-03-05',
        '2022-03-06',
        '2022-03-07',
        '2022-03-08',
        '2022-03-09',
        '2022-03-10',
        '2022-03-11',
        '2022-03-12',
        '2022-03-13',
        '2022-03-14',
        '2022-03-15',
        '2022-03-16',
        '2022-03-17',
        '2022-03-18',
        '2022-03-19',
        '2022-03-20',
        '2022-03-21',
        '2022-03-22',
        '2022-03-23',
        '2022-03-24',
        '2022-03-25',
        '2022-03-26',
        '2022-03-27',
        '2022-03-28',
        '2022-03-29',
        '2022-03-30',
        '2022-03-31',
        '2022-04-01',
        '2022-04-02',
        '2022-04-03',
        '2022-04-04',
        '2022-04-05',
        '2022-04-06',
        '2022-04-07',
        '2022-04-08',
        '2022-04-09',
        '2022-04-10',
        '2022-04-11',
        '2022-04-12',
        '2022-04-13',
        '2022-04-14',
        '2022-04-15',
        '2022-04-16',
        '2022-04-17',
        '2022-04-18',
        '2022-04-19',
        '2022-04-20',
        '2022-04-21',
        '2022-04-22',
        '2022-04-23',
        '2022-04-24',
        '2022-04-25',
        '2022-04-26',
        '2022-04-27',
        '2022-04-28',
        '2022-04-29',
        '2022-04-30',
        '2022-05-01',
        '2022-05-02',
        '2022-05-03',
        '2022-05-04',
        '2022-05-05',
        '2022-05-06',
        '2022-05-07',
        '2022-05-08',
        '2022-05-09',
        '2022-05-10',
        '2022-05-11',
        '2022-05-12',
        '2022-05-13',
        '2022-05-14',
        '2022-05-15',
        '2022-05-16',
        '2022-05-17',
        '2022-05-18',
        '2022-05-19',
        '2022-05-20',
        '2022-05-21',
        '2022-05-22',
        '2022-05-23',
        '2022-05-24',
        '2022-05-25',
        '2022-05-26',
        '2022-05-27',
        '2022-05-28',
        '2022-05-29',
        '2022-05-30',
        '2022-05-31',
        '2022-06-01',
        '2022-06-02',
        '2022-06-03',
        '2022-06-04',
        '2022-06-05',
        '2022-06-06',
        '2022-06-07',
        '2022-06-08',
        '2022-06-09',
        '2022-06-10',
        '2022-06-11',
        '2022-06-12',
        '2022-06-13',
        '2022-06-14',
        '2022-06-15',
        '2022-06-16',
        '2022-06-17',
        '2022-06-18',
        '2022-06-19',
        '2022-06-20',
        '2022-06-21',
        '2022-06-22',
        '2022-06-23',
        '2022-06-24',
        '2022-06-25',
        '2022-06-26',
        '2022-06-27',
        '2022-06-28',
        '2022-06-29',
        '2022-06-30',
        '2022-07-01',
        '2022-07-02',
        '2022-07-03',
        '2022-07-04',
        '2022-07-05',
        '2022-07-06',
        '2022-07-07',
        '2022-07-08',
        '2022-07-09',
        '2022-07-10',
        '2022-07-11',
        '2022-07-12',
        '2022-07-13',
        '2022-07-14',
        '2022-07-15',
        '2022-07-16',
        '2022-07-17',
        '2022-07-18',
        '2022-07-19',
        '2022-07-20',
        '2022-07-21',
        '2022-07-22',
        '2022-07-23',
        '2022-07-24',
        '2022-07-25',
        '2022-07-26',
        '2022-07-27',
        '2022-07-28',
        '2022-07-29',
        '2022-07-30',
        '2022-07-31',
        '2022-08-01',
        '2022-08-02',
        '2022-08-03',
        '2022-08-04',
        '2022-08-05',
        '2022-08-06',
        '2022-08-07',
        '2022-08-08',
        '2022-08-09',
        '2022-08-10',
        '2022-08-11',
        '2022-08-12',
        '2022-08-13',
        '2022-08-14',
        '2022-08-15',
        '2022-08-16',
        '2022-08-17',
        '2022-08-18',
        '2022-08-19',
        '2022-08-20',
        '2022-08-21',
        '2022-08-22',
        '2022-08-23',
        '2022-08-24',
        '2022-08-25',
        '2022-08-26',
        '2022-08-27',
        '2022-08-28',
        '2022-08-29',
        '2022-08-30',
        '2022-08-31',
        '2022-09-01',
        '2022-09-02',
        '2022-09-03',
        '2022-09-04',
        '2022-09-05',
        '2022-09-06',
        '2022-09-07',
        '2022-09-08',
        '2022-09-09',
        '2022-09-10',
        '2022-09-11',
        '2022-09-12',
        '2022-09-13',
        '2022-09-14',
        '2022-09-15',
        '2022-09-16',
        '2022-09-17',
        '2022-09-18',
        '2022-09-19',
        '2022-09-20',
        '2022-09-21',
        '2022-09-22',
        '2022-09-23',
        '2022-09-24',
        '2022-09-25',
        '2022-09-26',
        '2022-09-27',
        '2022-09-28',
        '2022-09-29',
        '2022-09-30',
        '2022-10-01',
        '2022-10-02',
        '2022-10-03',
        '2022-10-04',
        '2022-10-05',
        '2022-10-06',
        '2022-10-07',
        '2022-10-08',
        '2022-10-09',
        '2022-10-10',
        '2022-10-11',
        '2022-10-12',
        '2022-10-13',
        '2022-10-14',
        '2022-10-15',
        '2022-10-16',
        '2022-10-17',
        '2022-10-18',
        '2022-10-19',
        '2022-10-20',
        '2022-10-21',
        '2022-10-22',
        '2022-10-23',
        '2022-10-24',
        '2022-10-25',
        '2022-10-26',
        '2022-10-27',
        '2022-10-28',
        '2022-10-29',
        '2022-10-30',
        '2022-10-31',
        '2022-11-01',
        '2022-11-02',
        '2022-11-03',
        '2022-11-04',
        '2022-11-05',
        '2022-11-06',
        '2022-11-07',
        '2022-11-08',
        '2022-11-09',
        '2022-11-10',
        '2022-11-11',
        '2022-11-12',
        '2022-11-13',
        '2022-11-14',
        '2022-11-15',
        '2022-11-16',
        '2022-11-17',
        '2022-11-18',
        '2022-11-19',
        '2022-11-20',
        '2022-11-21',
        '2022-11-22',
        '2022-11-23',
        '2022-11-24',
        '2022-11-25',
        '2022-11-26',
        '2022-11-27',
        '2022-11-28',
        '2022-11-29',
        '2022-11-30',
        '2022-12-01',
        '2022-12-02',
        '2022-12-03',
        '2022-12-04',
        '2022-12-05',
        '2022-12-06',
        '2022-12-07',
        '2022-12-08',
        '2022-12-09',
        '2022-12-10',
        '2022-12-11',
        '2022-12-12',
        '2022-12-13',
        '2022-12-14',
        '2022-12-15',
        '2022-12-16',
        '2022-12-17',
        '2022-12-18',
        '2022-12-19',
        '2022-12-20',
        '2022-12-21',
        '2022-12-22',
        '2022-12-23',
        '2022-12-24',
        '2022-12-25',
        '2022-12-26',
        '2022-12-27',
        '2022-12-28',
        '2022-12-29',
        '2022-12-30',
        '2022-12-31'
      ],
      BTC: [
        10000.0, 10264.8, 10224.69, 10000.21, 9987.66, 9398.52, 9343.11,
        8988.44, 9068.35, 9132.01, 9002.11, 9241.87, 9486.34, 9216.19, 9358.16,
        9355.32, 9345.06, 9104.28, 9191.64, 9082.58, 8907.4, 7814.79, 7545.94,
        7683.0, 7869.29, 7960.54, 7899.58, 7941.96, 8147.44, 8252.39, 8175.75,
        8311.34, 8373.02, 8007.23, 7991.26, 8793.69, 8996.67, 8999.03, 9535.25,
        9533.93, 9641.0, 9511.14, 9121.12, 9125.56, 9167.97, 9234.29, 9556.92,
        9522.59, 8808.83, 8641.86, 8658.17, 8398.69, 8123.27, 8204.21, 8145.98,
        8193.98, 8530.24, 8469.88, 8182.82, 9309.67, 9554.24, 9534.9, 9194.05,
        8439.49, 8539.06, 8432.87, 8267.85, 8341.59, 9064.22, 8534.11, 8420.44,
        8460.53, 8161.71, 8509.02, 8539.52, 8845.89, 8868.57, 9019.89, 9077.73,
        8965.54, 8897.17, 9202.07, 9193.31, 9509.28, 9585.12, 9599.11, 10085.7,
        10286.41, 10241.08, 10192.05, 9907.16, 10032.77, 9977.03, 10103.47,
        10097.31, 9921.58, 9414.59, 9440.2, 9152.7, 9205.15, 9111.8, 8604.29,
        8617.61, 8931.59, 8621.02, 8755.98, 8742.65, 8654.84, 8854.71, 8956.76,
        8972.4, 8743.98, 8592.35, 8615.1, 8557.11, 8729.91, 8269.92, 8454.55,
        8610.46, 8353.79, 8276.28, 8288.44, 8349.98, 8165.77, 8585.99, 7840.99,
        7789.19, 7582.95, 7416.17, 6726.51, 6661.01, 6245.13, 6236.14, 6449.91,
        6542.91, 6727.12, 6495.12, 6567.17, 6269.59, 6531.2, 6307.85, 6364.39,
        6550.76, 6320.46, 6405.79, 6444.72, 6384.86, 6236.45, 6273.17, 6367.11,
        6873.77, 6895.59, 6436.42, 6595.62, 6432.79, 6449.59, 6496.04, 6793.08,
        6763.75, 6558.32, 6505.76, 6313.3, 6195.95, 5841.03, 4887.36, 4673.38,
        4862.81, 4406.4, 4425.04, 4151.49, 4477.84, 4433.36, 4521.5, 4349.77,
        4542.01, 4611.08, 4637.12, 4574.9, 4503.63, 4394.06, 4373.64, 4088.23,
        4176.09, 4182.74, 4175.95, 4362.83, 4421.59, 4438.58, 4679.7, 4711.05,
        4679.56, 4509.13, 4328.99, 4192.26, 4294.31, 4435.94, 4520.16, 4607.98,
        4544.26, 4715.27, 5085.1, 5043.4, 4980.23, 4917.31, 4861.65, 4902.36,
        4686.24, 4567.66, 4949.14, 5184.66, 5180.54, 5128.2, 5058.2, 5012.19,
        4998.37, 4961.86, 4894.86, 5029.2, 5014.1, 5029.46, 5152.53, 5013.39,
        5158.88, 5169.81, 5244.66, 5276.65, 5248.87, 5195.87, 5160.0, 5051.58,
        5051.05, 4538.41, 4573.29, 4666.25, 4581.86, 4646.67, 4650.62, 4668.48,
        4459.95, 4331.76, 4306.62, 4371.53, 4307.31, 4360.96, 4344.37, 4325.15,
        4282.78, 4291.44, 4285.13, 4091.39, 4184.01, 4177.29, 4618.89, 4707.8,
        4681.38, 4830.16, 4375.92, 4391.49, 4268.8, 4268.75, 4354.73, 4207.74,
        4241.55, 4102.52, 4000.46, 4197.07, 4183.13, 4086.45, 4065.26, 4140.99,
        4128.4, 4216.14, 4209.73, 4194.32, 4181.21, 4147.97, 4223.34, 4392.25,
        4368.62, 4306.07, 4229.98, 4189.1, 4197.13, 4139.24, 4119.73, 4144.67,
        4204.31, 4145.63, 4124.27, 4176.41, 4222.31, 4180.4, 4141.42, 4118.41,
        4152.02, 4152.66, 4239.34, 4189.19, 4341.12, 4504.21, 4385.75, 4463.73,
        4491.38, 4454.35, 4431.17, 4430.95, 4351.18, 4369.7, 4582.31, 4608.04,
        4573.91, 4427.26, 3994.14, 3434.81, 3856.43, 3661.2, 3642.75, 3556.78,
        3550.76, 3652.44, 3606.82, 3615.76, 3605.98, 3621.66, 3526.18, 3405.73,
        3501.94, 3572.83, 3594.46, 3576.43, 3553.96, 3585.92, 3509.7, 3569.17,
        3710.39, 3670.79, 3687.38, 3656.95, 3700.7, 3666.19, 3672.65, 3644.09,
        3721.98, 3707.07, 3708.22, 3700.25, 3712.49, 3846.4, 3859.41, 3751.85,
        3618.48, 3621.91, 3626.74, 3552.12, 3650.02, 3627.44, 3634.69, 3633.5,
        3640.63, 3639.75, 3643.41, 3611.44, 3574.03, 3595.99, 3588.14
      ],
      ETH: [
        10000.0, 10222.13, 10412.02, 10206.08, 10381.89, 9613.79, 9328.99,
        8654.98, 8482.74, 8648.54, 8331.17, 8792.1, 9147.69, 8832.72, 9013.28,
        9078.87, 9108.21, 8684.36, 8648.02, 8506.16, 8326.52, 6924.57, 6472.31,
        6652.19, 6562.41, 6630.65, 6632.29, 6480.53, 6842.15, 7072.71, 7006.31,
        7290.08, 7584.0, 7287.52, 7236.27, 8040.33, 8213.04, 8131.16, 8573.16,
        8470.42, 8843.35, 8475.59, 7872.17, 7930.95, 7894.34, 8000.62, 8502.46,
        8531.21, 7886.81, 7547.27, 7461.67, 7214.46, 7122.62, 7084.44, 7172.89,
        6980.58, 7593.6, 7603.91, 7154.31, 7881.01, 8090.9, 8053.9, 7708.99,
        7104.32, 7263.04, 7151.6, 6811.92, 6986.48, 7389.86, 7106.23, 6992.0,
        7037.89, 6813.58, 6992.95, 7131.6, 7472.14, 7669.03, 7974.72, 7985.36,
        7825.41, 7910.53, 8159.99, 8172.94, 8460.84, 8429.62, 8525.8, 8894.5,
        9169.89, 9211.28, 9220.38, 8948.66, 9388.18, 9430.2, 9641.62, 9594.54,
        9368.27, 8673.78, 8802.61, 8679.33, 8833.67, 8765.91, 8161.05, 8198.1,
        8494.87, 8200.45, 8250.27, 8326.44, 8284.6, 8338.06, 8427.84, 8393.2,
        8115.13, 8047.73, 8039.4, 7967.38, 8185.65, 7675.36, 7820.73, 7966.48,
        7668.89, 7565.39, 7635.49, 7784.91, 7575.43, 7957.34, 7444.66, 7331.75,
        7080.49, 6907.78, 6238.37, 6311.25, 5668.11, 5234.12, 5600.33, 5592.25,
        5794.98, 5531.0, 5662.25, 5299.21, 5458.12, 5313.52, 5371.78, 5536.71,
        5380.78, 5393.79, 5345.59, 4952.42, 4738.93, 4853.72, 4925.32, 5438.78,
        5323.63, 4946.4, 4989.22, 4849.95, 4893.23, 4938.7, 5046.75, 4920.18,
        4896.57, 4865.94, 4557.2, 4211.19, 3961.7, 3246.78, 3201.13, 3312.17,
        2902.21, 2949.36, 2695.75, 3105.25, 3012.57, 3094.94, 2888.61, 3105.27,
        3346.63, 3379.55, 3302.92, 3270.45, 3123.44, 3016.87, 2784.76, 2896.12,
        2912.04, 2926.64, 3109.0, 3132.72, 3238.65, 3345.35, 3349.68, 3308.53,
        3178.91, 2988.72, 2837.07, 2955.09, 3229.73, 3397.64, 3694.22, 3700.26,
        4119.47, 4252.37, 4152.0, 4286.95, 4188.15, 4194.11, 4366.86, 4031.39,
        3838.12, 4422.74, 4748.72, 4738.14, 4621.39, 4582.66, 4430.35, 4481.11,
        4412.35, 4343.74, 4666.28, 4660.97, 4637.12, 4837.29, 4634.64, 5032.3,
        5095.14, 5254.43, 5382.34, 5257.42, 5155.42, 5091.82, 4975.53, 5078.3,
        4406.82, 4280.23, 4402.88, 4318.93, 4499.42, 4517.4, 4608.94, 4213.5,
        4037.37, 3983.59, 4191.14, 4204.34, 4266.79, 4315.64, 4299.3, 4230.34,
        4261.6, 4369.48, 4295.63, 4448.52, 4439.66, 4677.29, 4851.16, 4749.07,
        4640.82, 4317.77, 4458.1, 4012.37, 3896.03, 3985.98, 3647.18, 3769.54,
        3620.89, 3383.6, 3600.26, 3617.24, 3575.65, 3511.59, 3610.88, 3617.87,
        3639.71, 3615.88, 3597.41, 3571.02, 3509.03, 3578.51, 3696.92, 3687.09,
        3674.53, 3627.33, 3568.41, 3581.53, 3515.07, 3484.28, 3521.3, 3515.64,
        3524.75, 3457.35, 3558.86, 3620.86, 3570.5, 3499.16, 3489.89, 3540.04,
        3577.71, 3714.43, 3662.28, 3966.21, 4266.38, 4127.6, 4239.73, 4382.21,
        4317.44, 4259.34, 4293.83, 4111.55, 4165.16, 4487.37, 4440.2, 4357.23,
        4241.36, 3586.78, 3040.2, 3601.89, 3482.89, 3429.27, 3341.17, 3318.65,
        3388.99, 3307.65, 3282.41, 3291.49, 3330.02, 3108.81, 3013.88, 3094.59,
        3194.52, 3273.65, 3262.2, 3267.81, 3308.42, 3184.82, 3327.25, 3533.27,
        3475.03, 3510.61, 3378.49, 3469.83, 3424.97, 3408.06, 3346.88, 3493.53,
        3438.66, 3452.24, 3433.71, 3461.29, 3590.17, 3568.31, 3436.65, 3206.33,
        3225.69, 3230.41, 3162.27, 3306.35, 3290.51, 3307.55, 3314.35, 3314.73,
        3311.3, 3314.73, 3294.68, 3225.34, 3258.26, 3259.32
      ],
      BNB: [
        10000.0, 10275.72, 10387.17, 10023.47, 9974.58, 9305.83, 9317.56,
        8760.27, 8506.07, 8609.7, 8273.37, 9026.21, 9485.73, 9325.38, 9587.41,
        9696.91, 9763.4, 9256.94, 9247.16, 9159.17, 8756.35, 7465.78, 6941.73,
        7260.46, 7195.94, 7471.64, 7291.75, 7573.33, 7508.81, 7622.21, 7356.27,
        7354.32, 7543.99, 7217.44, 7217.44, 7737.58, 8101.3, 8046.54, 8502.15,
        8023.08, 8277.28, 8201.02, 7706.29, 7884.24, 7815.8, 7888.15, 8410.25,
        8371.14, 7919.44, 7774.74, 7800.16, 7524.44, 7104.02, 7252.64, 7254.59,
        6937.82, 7326.94, 7303.49, 7102.07, 7692.61, 7970.28, 8007.43, 7868.6,
        7338.68, 7520.53, 7442.32, 7469.69, 7436.45, 7661.32, 7280.02, 7303.49,
        7332.81, 7082.52, 7227.22, 7276.11, 7487.29, 7653.5, 7735.63, 7802.11,
        7686.74, 7755.18, 7940.95, 7948.76, 8093.47, 8025.03, 8103.25, 8388.73,
        8498.24, 8470.87, 8664.45, 8437.62, 8705.52, 8629.25, 8818.92, 8756.35,
        8779.82, 8275.32, 8537.35, 8246.0, 8289.01, 8214.7, 7747.36, 8066.1,
        8283.15, 8103.25, 8142.35, 8152.13, 8093.47, 8169.73, 8228.4, 8201.02,
        7915.53, 7960.5, 7903.79, 7831.44, 7894.01, 7545.96, 7622.21, 7983.96,
        7690.66, 7538.13, 7598.74, 7600.71, 7487.29, 7862.73, 7356.27, 7430.59,
        7088.39, 6978.88, 6028.55, 6138.06, 5295.27, 5215.1, 5784.12, 5766.52,
        6052.01, 5840.83, 5960.11, 5725.46, 5958.16, 5917.09, 6120.46, 6224.09,
        6261.25, 6415.72, 6437.23, 6063.74, 5934.69, 6007.04, 5981.62, 6296.44,
        6300.35, 5883.84, 6024.64, 5827.14, 5897.54, 5860.39, 5789.99, 5658.97,
        5668.76, 5658.97, 5621.82, 5342.2, 5039.11, 4301.91, 4266.72, 4518.96,
        4098.56, 4227.61, 3887.37, 4211.97, 4219.79, 4342.98, 4227.61, 4470.08,
        4710.6, 4669.53, 4608.91, 4583.5, 4440.75, 4323.43, 4170.91, 4247.16,
        4280.41, 4274.54, 4501.36, 4560.03, 4665.63, 4710.6, 4732.11, 4751.66,
        4579.58, 4417.29, 4344.94, 4429.01, 4636.29, 4693.0, 4904.18, 4892.45,
        5078.22, 5281.58, 5043.02, 5172.08, 5164.25, 5066.48, 5117.32, 4898.32,
        4829.88, 5263.98, 5434.1, 5809.54, 5658.97, 5543.61, 5531.87, 5565.12,
        5864.29, 6079.39, 6184.98, 6198.68, 6341.41, 6337.51, 6378.57, 6400.08,
        6317.95, 6353.15, 6360.97, 6233.86, 6212.36, 6179.11, 5977.71, 5915.14,
        5482.99, 5520.14, 5909.27, 5866.24, 5844.74, 5799.76, 5897.54, 5529.92,
        5408.69, 5461.47, 5561.21, 5531.87, 5482.99, 5434.1, 5426.29, 5426.29,
        5424.32, 5396.95, 5187.72, 5434.1, 5494.72, 5727.42, 5817.36, 5739.14,
        5705.91, 5420.42, 5469.3, 5281.58, 5365.67, 5461.47, 5207.27, 5305.05,
        5222.92, 5136.88, 5371.53, 5404.77, 5400.86, 5334.37, 5371.53, 5332.42,
        5502.54, 5512.32, 5514.27, 5543.61, 5584.67, 5588.59, 5782.17, 5746.97,
        5555.34, 5526.01, 5402.82, 5426.29, 5326.55, 5297.22, 5295.27, 5340.24,
        5262.02, 5248.34, 5334.37, 5379.35, 5342.2, 5314.82, 5260.07, 5275.72,
        5285.49, 5396.95, 5350.02, 5578.8, 5694.17, 5592.49, 5838.87, 5917.09,
        6106.76, 6423.55, 6359.01, 6253.43, 6501.76, 6888.93, 6840.05, 6824.4,
        6507.63, 6175.21, 5263.98, 5979.66, 5647.24, 5533.82, 5406.72, 5391.09,
        5387.17, 5316.77, 5250.29, 5344.15, 5338.29, 5170.12, 4966.76, 5211.19,
        5795.86, 5877.98, 5868.21, 6069.61, 6138.06, 5727.42, 5809.54, 5876.03,
        5725.46, 5707.86, 5684.39, 5696.13, 5635.51, 5647.24, 5555.34, 5664.84,
        5590.54, 5629.64, 5582.72, 5410.64, 5312.87, 5238.57, 5029.33, 4501.36,
        4696.91, 4913.96, 4698.86, 4908.1, 4822.06, 4802.5, 4812.28, 4779.03,
        4749.71, 4743.84, 4814.23, 4759.48, 4804.46, 4794.68
      ],
      SOL: [
        10000.0, 10427.68, 10402.38, 10005.89, 9937.06, 8917.0, 8940.53,
        8045.77, 8486.39, 8319.9, 7936.95, 8250.49, 8948.76, 8628.16, 8644.04,
        8735.22, 8695.81, 8183.43, 8286.96, 8061.06, 7768.11, 6486.27, 5483.26,
        5653.87, 5419.74, 5500.91, 5393.85, 5196.78, 5315.61, 5611.51, 5466.79,
        5875.65, 6465.09, 5952.71, 5917.99, 6470.39, 6749.81, 6635.1, 6946.88,
        6652.74, 6763.34, 6359.79, 5622.68, 5655.63, 5514.45, 5696.81, 6038.59,
        6033.89, 5533.86, 5285.6, 5333.26, 5470.91, 5015.0, 5005.59, 5093.24,
        5129.13, 5573.27, 5336.79, 5029.12, 5787.4, 5759.16, 5945.65, 5631.51,
        5203.24, 5297.96, 5043.24, 4838.52, 4809.1, 5169.13, 4906.76, 4775.58,
        4815.58, 4605.57, 4752.63, 4900.88, 5055.59, 5205.01, 5229.13, 5438.56,
        5260.9, 5219.14, 5382.67, 5549.74, 6022.71, 5766.22, 5962.7, 6203.31,
        6442.74, 6517.44, 7066.3, 7348.08, 8034.01, 7896.35, 8092.25, 7771.64,
        7567.51, 6775.69, 6973.35, 6502.74, 6525.1, 6556.27, 5879.17, 6001.53,
        6194.48, 5914.47, 5943.88, 6005.06, 5999.18, 6026.24, 6345.08, 6263.31,
        5971.53, 5927.41, 5965.65, 5854.47, 5923.88, 5668.57, 5762.1, 5760.34,
        5533.86, 5021.48, 5241.49, 5151.48, 5049.12, 5417.97, 4934.41, 4829.69,
        4579.68, 4461.44, 3858.46, 3832.0, 2810.75, 2543.1, 2949.0, 3073.12,
        3396.09, 3197.25, 3326.67, 2993.71, 3021.95, 2925.46, 2957.82, 3089.01,
        2899.0, 2919.0, 2864.28, 2588.97, 2431.91, 2588.97, 2638.39, 2793.69,
        2710.16, 2373.08, 2410.73, 2253.66, 2254.25, 2303.67, 2501.33, 2323.67,
        2294.84, 2350.13, 2217.19, 2036.0, 1841.87, 1598.91, 1678.33, 2007.77,
        1780.69, 1819.52, 1863.05, 2040.71, 2050.71, 2156.01, 2051.88, 2220.13,
        2484.27, 2471.91, 2338.96, 2276.6, 2076.0, 2008.36, 1911.29, 1937.17,
        1970.12, 1958.94, 2146.01, 2117.77, 2165.43, 2253.07, 2266.02, 2246.61,
        2163.66, 1972.46, 1942.47, 2008.36, 2161.89, 2224.25, 2341.91, 2304.85,
        2412.49, 2663.1, 2496.03, 2516.62, 2414.85, 2350.72, 2416.61, 2223.66,
        2113.65, 2344.85, 2547.2, 2514.85, 2597.8, 2501.33, 2433.67, 2422.5,
        2281.9, 2268.96, 2377.2, 2379.55, 2397.79, 2483.68, 2374.84, 2468.38,
        2508.38, 2633.68, 2736.05, 2654.27, 2546.04, 2533.09, 2374.26, 2394.85,
        2129.54, 2064.24, 2151.89, 2050.13, 2086.6, 2083.65, 2075.41, 1900.11,
        1847.17, 1841.28, 1886.58, 1869.53, 1878.93, 1850.11, 1833.63, 1829.52,
        1875.99, 1894.23, 1832.47, 1924.82, 1972.46, 2041.3, 2076.59, 2048.95,
        2193.66, 1964.24, 2006.0, 1943.65, 1887.76, 1976.58, 1840.11, 1932.46,
        1865.99, 1802.46, 1904.82, 1985.41, 1969.53, 1899.52, 1966.59, 1931.88,
        1956.0, 1988.36, 1930.71, 1915.4, 1895.4, 1933.05, 2000.71, 2003.65,
        1961.88, 1947.18, 1911.88, 1924.82, 1886.58, 1840.7, 1831.29, 1801.87,
        1761.28, 1744.81, 1780.69, 1824.23, 1775.4, 1716.57, 1661.27, 1658.33,
        1647.75, 1717.16, 1673.04, 1823.05, 1844.23, 1804.22, 1891.88, 1926.0,
        1935.41, 1918.35, 1904.82, 1797.75, 1817.75, 1993.05, 2173.65, 1954.82,
        1730.69, 1383.02, 836.52, 1057.71, 940.06, 877.11, 804.75, 807.69,
        835.93, 842.4, 805.93, 774.75, 757.69, 724.74, 694.16, 721.81, 830.05,
        858.29, 831.81, 827.11, 847.11, 785.34, 789.46, 817.11, 794.75, 807.1,
        783.57, 802.98, 815.34, 828.87, 797.11, 804.16, 797.7, 798.87, 792.99,
        782.4, 810.64, 835.93, 823.57, 734.16, 731.22, 731.81, 693.57, 722.4,
        707.69, 693.57, 692.39, 671.8, 668.86, 657.69, 649.45, 571.8, 538.86,
        577.68
      ],
      XRP: [
        10000.0, 10213.33, 10315.77, 10045.8, 10002.42, 9181.63, 9445.58,
        9180.43, 9082.81, 9141.86, 8848.98, 9257.56, 9610.7, 9258.77, 9369.65,
        9419.07, 9390.15, 9152.71, 9121.37, 8959.87, 8842.95, 7636.49, 7064.0,
        7344.83, 7331.57, 7500.3, 7425.58, 7259.25, 7318.31, 7444.86, 7246.0,
        7337.59, 7572.62, 7262.87, 7295.41, 7720.86, 8039.05, 8096.9, 9948.18,
        10386.89, 10800.29, 10178.38, 9071.96, 9973.49, 9787.88, 9675.79,
        9985.53, 10121.73, 9363.63, 9462.46, 9852.96, 9533.57, 8754.97, 8557.31,
        8498.25, 8184.89, 9080.39, 9046.65, 8692.3, 9384.12, 9484.16, 9269.62,
        9053.88, 8595.88, 9081.6, 8921.3, 8720.02, 8665.79, 9174.4, 8934.56,
        9693.87, 9533.57, 9157.53, 9310.6, 9223.82, 9463.66, 9572.14, 9597.44,
        9824.04, 9752.92, 10078.35, 10116.91, 10063.88, 10169.94, 9930.1,
        10021.69, 10277.21, 10646.02, 10289.26, 10354.35, 9874.65, 9987.95,
        10009.64, 10194.05, 9983.13, 9910.81, 9249.12, 9492.59, 9117.76,
        9162.35, 9116.55, 8453.66, 8525.97, 8726.04, 8722.43, 9399.79, 9387.73,
        9243.11, 9234.67, 9304.57, 9086.42, 8763.41, 8673.01, 8580.21, 8452.46,
        8398.22, 7785.95, 7800.42, 7743.76, 7366.52, 7294.2, 7279.74, 7405.09,
        7278.53, 7781.13, 7118.23, 7280.95, 6929.01, 6880.8, 6074.49, 6088.95,
        4945.16, 4559.48, 5183.8, 5146.44, 5347.72, 5129.57, 5260.94, 4959.63,
        5031.94, 4963.24, 5011.45, 5081.35, 4911.41, 4934.31, 4919.86, 4770.4,
        4640.23, 4637.83, 4678.8, 5021.09, 5123.54, 4788.48, 4881.28, 4710.13,
        4727.01, 4780.04, 4840.3, 4901.78, 4849.94, 4828.25, 4649.87, 4400.39,
        4196.69, 3700.14, 3803.78, 4111.13, 3751.96, 3871.28, 3737.49, 3933.95,
        3888.16, 3999.04, 3923.11, 4023.14, 4424.49, 4410.03, 4342.54, 4270.22,
        4078.58, 3988.19, 3897.79, 3773.65, 3811.02, 3847.18, 3952.03, 3942.39,
        3999.04, 4120.76, 4121.97, 4155.72, 3941.19, 3780.89, 3756.78, 3862.84,
        3970.11, 4059.3, 4244.91, 4208.75, 4337.71, 4508.86, 4353.38, 4383.52,
        4334.1, 4329.28, 4330.48, 4146.08, 4044.83, 4317.22, 4513.68, 4476.32,
        4688.44, 4607.69, 4553.46, 4522.12, 4458.24, 4459.45, 4530.56, 4507.66,
        4508.86, 4552.25, 4431.72, 4582.38, 4569.12, 4558.27, 4547.43, 4536.58,
        4512.48, 4524.53, 4542.61, 4551.04, 4046.04, 4068.94, 4159.34, 4108.71,
        4171.39, 4166.57, 4197.9, 4096.66, 4031.57, 3984.57, 3986.98, 3964.09,
        3973.72, 4003.86, 3986.98, 3966.49, 3980.96, 4001.45, 3892.97, 4071.35,
        4120.76, 4261.78, 4332.89, 4267.81, 4297.94, 4012.3, 4124.39, 3933.95,
        4275.04, 4523.32, 4401.6, 4645.05, 5018.68, 4723.4, 5927.45, 6354.1,
        5869.59, 5922.62, 5615.29, 5406.78, 5417.62, 5769.55, 5758.71, 5700.86,
        5453.78, 5553.82, 5787.63, 5961.19, 5929.85, 6251.65, 6191.4, 6432.45,
        6013.02, 5904.54, 5865.98, 5861.16, 5888.88, 5805.71, 5767.15, 5767.15,
        5632.16, 5483.91, 5418.83, 5526.1, 5611.67, 5650.24, 5499.58, 5542.97,
        5644.21, 5571.9, 5711.7, 5645.41, 5530.92, 5573.1, 5611.67, 5447.75,
        5480.29, 6025.07, 5999.76, 5765.94, 5569.48, 4822.22, 3993.01, 4781.25,
        4607.69, 4414.85, 4095.46, 4489.58, 4686.03, 4545.01, 4625.77, 4610.1,
        4631.8, 4389.54, 4370.26, 4557.07, 4579.97, 4843.93, 4940.34, 4757.14,
        4865.62, 4705.32, 4802.94, 4947.57, 4784.87, 4753.53, 4670.36, 4688.44,
        4694.47, 4684.83, 4614.92, 4752.32, 4692.06, 4655.9, 4583.59, 4661.92,
        4749.91, 4649.87, 4547.43, 4244.91, 4243.7, 4220.8, 4078.58, 4252.14,
        4168.98, 4201.52, 4264.19, 4237.67, 4167.77, 4414.85, 4467.88, 4302.76,
        4114.74, 4146.08
      ],
      USDT: [
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0
      ],
      HODLIE: [
        10000.0, 10001.08, 9988.64, 9877.33, 9960.83, 9595.98, 9600.69, 9597.95,
        9596.73, 9619.63, 9492.47, 9508.71, 9683.0, 9518.82, 9527.37, 9561.64,
        9561.46, 9336.52, 9357.21, 9356.79, 9354.99, 9348.03, 9344.74, 9346.7,
        9346.29, 9360.85, 9327.62, 9208.93, 9253.27, 9356.68, 9311.91, 9496.87,
        9764.05, 9500.73, 9483.31, 9821.84, 9927.46, 9878.91, 10177.5, 10235.48,
        10337.06, 10188.11, 10097.13, 10098.09, 10097.7, 10098.06, 10240.56,
        10258.9, 9873.89, 9857.35, 9857.44, 9855.82, 9826.87, 9827.67, 9678.27,
        9572.43, 9763.44, 9631.68, 9376.13, 9533.71, 9645.9, 9636.78, 9466.34,
        9425.39, 9426.54, 9425.95, 9360.95, 9346.43, 9320.75, 9040.31, 8970.69,
        8912.9, 8826.0, 8861.82, 8750.49, 8898.68, 8992.61, 9168.92, 9175.47,
        9084.91, 9134.08, 9083.1, 9108.12, 9240.1, 9113.37, 9214.61, 9392.15,
        9484.24, 9515.56, 9520.89, 9618.58, 9917.53, 9860.71, 9944.32, 9942.29,
        9827.52, 9726.18, 9727.59, 9725.98, 9726.22, 9669.66, 9635.05, 9636.95,
        9638.26, 9588.51, 9761.5, 9758.41, 9721.59, 9635.22, 9709.69, 9659.72,
        9524.38, 9482.97, 9473.2, 9472.75, 9473.13, 9215.05, 9199.83, 9198.48,
        9141.83, 9074.65, 9031.55, 9031.52, 8916.85, 9137.74, 8847.35, 8861.52,
        8859.44, 8858.85, 8853.23, 8854.28, 8847.37, 8846.56, 8850.94, 8850.93,
        9114.61, 8945.82, 9063.18, 8762.2, 8728.15, 8672.21, 8703.89, 8765.54,
        8785.6, 8832.4, 8839.71, 8723.74, 8674.11, 8674.71, 8720.61, 9016.39,
        8922.78, 8601.71, 8684.11, 8683.82, 8684.31, 8657.56, 8564.05, 8368.48,
        8246.3, 8241.75, 8129.43, 8090.09, 8087.85, 8082.05, 8082.01, 8085.09,
        7735.94, 7795.67, 7853.66, 8063.58, 8081.9, 8219.09, 8046.93, 8225.43,
        8345.12, 8348.27, 8267.71, 8229.15, 7943.23, 7925.74, 7924.43, 7925.01,
        7925.29, 7976.34, 8219.5, 8253.49, 8391.12, 8526.6, 8532.92, 8502.98,
        8355.51, 8355.48, 8354.78, 8355.43, 8357.02, 8543.58, 8919.33, 8925.71,
        9420.35, 9574.22, 9465.34, 9434.12, 9377.94, 9344.48, 9509.19, 9306.98,
        9306.38, 9310.08, 9613.78, 9607.16, 9488.79, 9386.47, 9385.82, 9372.94,
        9349.54, 9439.09, 9488.46, 9494.85, 9518.86, 9720.86, 9577.49, 9912.41,
        9973.35, 10125.98, 10247.28, 10130.09, 10014.36, 10044.51, 10042.84,
        10027.52, 9975.2, 9975.49, 9978.61, 9957.41, 9944.88, 9929.95, 10029.88,
        9744.1, 9737.92, 9738.21, 9739.04, 9717.39, 9791.0, 9845.98, 9827.46,
        9726.96, 9732.02, 9667.48, 9553.36, 9359.4, 9362.72, 9614.97, 9759.61,
        9728.17, 9878.11, 9550.34, 9515.8, 9513.96, 9514.16, 9644.49, 9582.81,
        9717.51, 9907.08, 9758.37, 10343.73, 10527.08, 10321.03, 10342.06,
        10288.91, 10233.35, 10197.62, 10193.44, 10170.6, 10144.78, 10037.21,
        10005.49, 10178.78, 10151.83, 10162.6, 10296.31, 10271.14, 10369.4,
        10199.5, 10173.25, 10173.22, 10173.59, 10107.79, 10044.67, 10133.31,
        10164.24, 10090.14, 9990.69, 9981.85, 9982.0, 9974.49, 10163.38,
        10092.88, 10504.15, 10890.6, 10715.15, 10860.96, 11040.23, 10962.03,
        10896.45, 10906.82, 10867.09, 10933.45, 11097.36, 11209.5, 10836.52,
        10594.38, 10620.71, 10612.92, 10620.15, 10616.65, 10612.1, 10477.08,
        10444.9, 10506.85, 10432.54, 10463.35, 10460.89, 10485.74, 10344.62,
        10317.92, 10320.02, 10521.73, 10647.12, 10529.81, 10514.51, 10585.03,
        10432.72, 10433.36, 10772.77, 10684.04, 10737.39, 10633.94, 10625.21,
        10655.3, 10712.99, 10602.2, 10585.18, 10515.65, 10528.23, 10500.32,
        10497.62, 10671.05, 10636.98, 10568.57, 10180.86, 10122.63, 10124.76,
        10122.26, 10213.23, 10188.23, 10213.66, 10262.55, 10243.11, 10200.63,
        10189.76, 10251.34, 10165.53, 10064.33, 10040.26
      ]
    },
    2023: {
      TIMESTAMP: [
        '2023-01-01',
        '2023-01-02',
        '2023-01-03',
        '2023-01-04',
        '2023-01-05',
        '2023-01-06',
        '2023-01-07',
        '2023-01-08',
        '2023-01-09',
        '2023-01-10',
        '2023-01-11',
        '2023-01-12',
        '2023-01-13',
        '2023-01-14',
        '2023-01-15',
        '2023-01-16',
        '2023-01-17',
        '2023-01-18',
        '2023-01-19',
        '2023-01-20',
        '2023-01-21',
        '2023-01-22',
        '2023-01-23',
        '2023-01-24',
        '2023-01-25',
        '2023-01-26',
        '2023-01-27',
        '2023-01-28',
        '2023-01-29',
        '2023-01-30',
        '2023-01-31',
        '2023-02-01',
        '2023-02-02',
        '2023-02-03',
        '2023-02-04',
        '2023-02-05',
        '2023-02-06',
        '2023-02-07',
        '2023-02-08',
        '2023-02-09',
        '2023-02-10',
        '2023-02-11',
        '2023-02-12',
        '2023-02-13',
        '2023-02-14',
        '2023-02-15',
        '2023-02-16',
        '2023-02-17',
        '2023-02-18',
        '2023-02-19',
        '2023-02-20',
        '2023-02-21',
        '2023-02-22',
        '2023-02-23',
        '2023-02-24',
        '2023-02-25',
        '2023-02-26',
        '2023-02-27',
        '2023-02-28',
        '2023-03-01',
        '2023-03-02',
        '2023-03-03',
        '2023-03-04',
        '2023-03-05',
        '2023-03-06',
        '2023-03-07',
        '2023-03-08',
        '2023-03-09',
        '2023-03-10',
        '2023-03-11',
        '2023-03-12',
        '2023-03-13',
        '2023-03-14',
        '2023-03-15',
        '2023-03-16',
        '2023-03-17',
        '2023-03-18',
        '2023-03-19',
        '2023-03-20',
        '2023-03-21',
        '2023-03-22',
        '2023-03-23',
        '2023-03-24',
        '2023-03-25',
        '2023-03-26',
        '2023-03-27',
        '2023-03-28',
        '2023-03-29',
        '2023-03-30',
        '2023-03-31',
        '2023-04-01',
        '2023-04-02',
        '2023-04-03',
        '2023-04-04',
        '2023-04-05',
        '2023-04-06',
        '2023-04-07',
        '2023-04-08',
        '2023-04-09',
        '2023-04-10',
        '2023-04-11',
        '2023-04-12',
        '2023-04-13',
        '2023-04-14',
        '2023-04-15',
        '2023-04-16',
        '2023-04-17',
        '2023-04-18',
        '2023-04-19',
        '2023-04-20',
        '2023-04-21',
        '2023-04-22',
        '2023-04-23',
        '2023-04-24',
        '2023-04-25',
        '2023-04-26',
        '2023-04-27',
        '2023-04-28',
        '2023-04-29',
        '2023-04-30',
        '2023-05-01',
        '2023-05-02',
        '2023-05-03',
        '2023-05-04',
        '2023-05-05',
        '2023-05-06',
        '2023-05-07',
        '2023-05-08',
        '2023-05-09',
        '2023-05-10',
        '2023-05-11',
        '2023-05-12',
        '2023-05-13',
        '2023-05-14',
        '2023-05-15',
        '2023-05-16',
        '2023-05-17',
        '2023-05-18',
        '2023-05-19',
        '2023-05-20',
        '2023-05-21',
        '2023-05-22',
        '2023-05-23',
        '2023-05-24',
        '2023-05-25',
        '2023-05-26',
        '2023-05-27',
        '2023-05-28',
        '2023-05-29',
        '2023-05-30',
        '2023-05-31',
        '2023-06-01',
        '2023-06-02',
        '2023-06-03',
        '2023-06-04',
        '2023-06-05',
        '2023-06-06',
        '2023-06-07',
        '2023-06-08',
        '2023-06-09',
        '2023-06-10',
        '2023-06-11',
        '2023-06-12',
        '2023-06-13',
        '2023-06-14',
        '2023-06-15',
        '2023-06-16',
        '2023-06-17',
        '2023-06-18',
        '2023-06-19',
        '2023-06-20',
        '2023-06-21',
        '2023-06-22',
        '2023-06-23',
        '2023-06-24',
        '2023-06-25',
        '2023-06-26',
        '2023-06-27',
        '2023-06-28',
        '2023-06-29',
        '2023-06-30',
        '2023-07-01',
        '2023-07-02',
        '2023-07-03',
        '2023-07-04',
        '2023-07-05',
        '2023-07-06',
        '2023-07-07',
        '2023-07-08',
        '2023-07-09',
        '2023-07-10',
        '2023-07-11',
        '2023-07-12',
        '2023-07-13',
        '2023-07-14',
        '2023-07-15',
        '2023-07-16',
        '2023-07-17',
        '2023-07-18',
        '2023-07-19',
        '2023-07-20',
        '2023-07-21',
        '2023-07-22',
        '2023-07-23',
        '2023-07-24',
        '2023-07-25',
        '2023-07-26',
        '2023-07-27',
        '2023-07-28',
        '2023-07-29',
        '2023-07-30',
        '2023-07-31',
        '2023-08-01',
        '2023-08-02',
        '2023-08-03',
        '2023-08-04',
        '2023-08-05',
        '2023-08-06',
        '2023-08-07',
        '2023-08-08',
        '2023-08-09',
        '2023-08-10',
        '2023-08-11',
        '2023-08-12',
        '2023-08-13',
        '2023-08-14',
        '2023-08-15',
        '2023-08-16',
        '2023-08-17',
        '2023-08-18',
        '2023-08-19',
        '2023-08-20',
        '2023-08-21',
        '2023-08-22',
        '2023-08-23',
        '2023-08-24',
        '2023-08-25',
        '2023-08-26',
        '2023-08-27',
        '2023-08-28',
        '2023-08-29',
        '2023-08-30',
        '2023-08-31',
        '2023-09-01',
        '2023-09-02',
        '2023-09-03',
        '2023-09-04',
        '2023-09-05',
        '2023-09-06',
        '2023-09-07',
        '2023-09-08',
        '2023-09-09',
        '2023-09-10',
        '2023-09-11',
        '2023-09-12',
        '2023-09-13',
        '2023-09-14',
        '2023-09-15',
        '2023-09-16',
        '2023-09-17',
        '2023-09-18',
        '2023-09-19',
        '2023-09-20',
        '2023-09-21',
        '2023-09-22',
        '2023-09-23',
        '2023-09-24',
        '2023-09-25',
        '2023-09-26',
        '2023-09-27',
        '2023-09-28',
        '2023-09-29',
        '2023-09-30',
        '2023-10-01',
        '2023-10-02',
        '2023-10-03',
        '2023-10-04',
        '2023-10-05',
        '2023-10-06',
        '2023-10-07',
        '2023-10-08',
        '2023-10-09',
        '2023-10-10',
        '2023-10-11',
        '2023-10-12',
        '2023-10-13',
        '2023-10-14',
        '2023-10-15',
        '2023-10-16',
        '2023-10-17',
        '2023-10-18',
        '2023-10-19',
        '2023-10-20',
        '2023-10-21',
        '2023-10-22',
        '2023-10-23',
        '2023-10-24',
        '2023-10-25',
        '2023-10-26',
        '2023-10-27',
        '2023-10-28',
        '2023-10-29',
        '2023-10-30',
        '2023-10-31',
        '2023-11-01',
        '2023-11-02',
        '2023-11-03',
        '2023-11-04',
        '2023-11-05',
        '2023-11-06',
        '2023-11-07',
        '2023-11-08',
        '2023-11-09',
        '2023-11-10',
        '2023-11-11',
        '2023-11-12',
        '2023-11-13',
        '2023-11-14',
        '2023-11-15',
        '2023-11-16',
        '2023-11-17',
        '2023-11-18',
        '2023-11-19',
        '2023-11-20',
        '2023-11-21',
        '2023-11-22',
        '2023-11-23',
        '2023-11-24',
        '2023-11-25',
        '2023-11-26',
        '2023-11-27',
        '2023-11-28',
        '2023-11-29',
        '2023-11-30',
        '2023-12-01',
        '2023-12-02',
        '2023-12-03',
        '2023-12-04',
        '2023-12-05',
        '2023-12-06',
        '2023-12-07',
        '2023-12-08',
        '2023-12-09',
        '2023-12-10',
        '2023-12-11',
        '2023-12-12',
        '2023-12-13',
        '2023-12-14',
        '2023-12-15',
        '2023-12-16',
        '2023-12-17',
        '2023-12-18',
        '2023-12-19',
        '2023-12-20',
        '2023-12-21',
        '2023-12-22',
        '2023-12-23',
        '2023-12-24',
        '2023-12-25',
        '2023-12-26',
        '2023-12-27',
        '2023-12-28',
        '2023-12-29',
        '2023-12-30',
        '2023-12-31'
      ],
      BTC: [
        10000.0, 10049.21, 10107.49, 10094.6, 10185.13, 10191.9, 10264.83,
        10251.51, 10267.75, 10414.02, 10549.16, 10626.83, 11448.54, 12014.78,
        12705.15, 12657.53, 12823.74, 12851.88, 12593.67, 12750.72, 13676.74,
        13776.37, 13726.01, 13876.64, 13773.86, 13880.8, 13924.11, 13926.8,
        13908.28, 14385.43, 13758.28, 14031.87, 14354.31, 14258.47, 14176.66,
        14165.68, 13909.44, 13848.81, 14060.24, 13878.69, 13175.8, 13056.99,
        13210.31, 13192.16, 13098.85, 13430.14, 14618.88, 14523.96, 14938.21,
        14906.83, 14824.42, 14953.58, 14759.63, 14588.08, 14446.09, 13994.39,
        13993.69, 14210.21, 14213.65, 14019.5, 14243.82, 14200.85, 13510.82,
        13501.75, 13578.64, 13579.01, 13380.47, 13196.78, 12313.9, 12164.8,
        12307.84, 13136.39, 14648.87, 14949.85, 14745.7, 15121.29, 16653.71,
        16288.82, 17016.64, 16919.38, 16967.17, 16469.65, 17077.9, 16523.42,
        16613.78, 16972.69, 16403.53, 16475.19, 17184.03, 16886.93, 17293.52,
        17261.82, 17012.36, 16827.26, 17061.66, 17054.41, 16953.07, 16921.66,
        16910.65, 17173.11, 17913.16, 18318.56, 18062.87, 18338.13, 18422.41,
        18371.36, 18359.28, 17857.65, 18383.38, 17599.73, 17099.08, 16504.06,
        16859.51, 16701.19, 16623.8, 17089.49, 17128.84, 17786.77, 17762.64,
        17675.06, 17782.84, 16960.41, 17394.36, 17618.45, 17420.37, 17910.56,
        17453.8, 17395.75, 16738.32, 16693.85, 16748.78, 16284.8, 16152.2,
        16276.39, 16282.95, 16530.76, 16342.01, 16545.04, 16277.49, 16265.04,
        16383.77, 16194.59, 16265.56, 16461.01, 15948.69, 16012.78, 16152.95,
        16223.95, 17003.03, 16760.19, 16790.04, 16454.39, 16270.96, 16502.15,
        16386.31, 16484.7, 15562.61, 16435.41, 15904.71, 16067.22, 16035.95,
        15662.16, 15674.64, 15695.3, 15640.86, 15153.59, 15513.35, 15961.98,
        16082.82, 15981.19, 16194.7, 17077.52, 18228.24, 18115.2, 18548.83,
        18470.84, 18462.09, 18296.63, 18513.87, 18223.03, 18406.67, 18447.44,
        18508.55, 18553.14, 18837.27, 18655.25, 18423.37, 18260.16, 18342.95,
        18294.78, 18264.24, 18369.16, 18512.74, 18382.08, 18951.35, 18296.66,
        18331.68, 18353.24, 18223.53, 18049.75, 18081.91, 18046.31, 18113.55,
        18042.7, 18167.12, 17651.22, 17678.58, 17841.73, 17676.07, 17730.36,
        17766.89, 17648.04, 17680.72, 17707.36, 17666.19, 17696.01, 17588.49,
        17608.45, 17614.14, 17663.27, 18018.48, 17917.95, 17835.08, 17816.65,
        17811.76, 17746.95, 17818.73, 17664.74, 17499.14, 16222.73, 15781.29,
        15770.4, 15864.51, 15817.62, 15621.5, 16013.73, 15810.19, 15770.4,
        15756.01, 15791.75, 15774.88, 16729.36, 16488.89, 15770.37, 15595.06,
        15653.89, 15707.81, 15601.53, 15597.46, 15585.32, 15933.83, 15680.65,
        15682.36, 15648.35, 15222.4, 15719.57, 15884.31, 16101.6, 16207.62,
        16076.69, 16035.52, 16265.61, 16457.68, 16401.08, 16094.87, 16091.46,
        16078.02, 16034.83, 15908.32, 15833.13, 15939.96, 16349.67, 16274.75,
        16349.24, 16946.16, 16678.68, 16587.69, 16798.65, 16606.47, 16930.91,
        16918.68, 16899.3, 16698.15, 16614.12, 16212.36, 16194.76, 16240.04,
        16251.89, 16404.86, 17256.76, 17200.68, 17156.29, 17341.54, 17954.1,
        18156.89, 18182.49, 20166.03, 20674.31, 20947.2, 20717.94, 20462.99,
        20643.27, 21005.28, 20863.72, 20918.53, 21355.03, 21057.55, 20960.32,
        21282.24, 21168.08, 21255.43, 21356.13, 21659.74, 22122.76, 22608.87,
        22330.55, 22490.2, 22073.52, 21553.4, 22823.71, 21922.4, 22120.45,
        22096.96, 22384.06, 22653.72, 22019.23, 22665.88, 22580.58, 22808.89,
        22834.84, 22791.41, 22446.36, 22917.22, 22874.57, 22816.08, 23427.31,
        23817.84, 24248.61, 25392.56, 26805.82, 26491.93, 26110.1, 26790.76,
        26572.63, 26502.01, 24990.45, 24956.5, 26005.0, 26070.77, 25459.48,
        25610.9, 25251.7, 25731.83, 25624.42, 26397.99, 26602.45, 26512.01,
        26479.24, 26014.02, 26439.45, 25706.95, 26223.77, 25801.43, 25277.82,
        25502.8
      ],
      ETH: [
        10000.0, 10040.47, 10158.17, 10137.95, 10477.53, 10456.14, 10614.37,
        10559.45, 10612.79, 11042.75, 11155.95, 11280.18, 11892.27, 12149.86,
        12966.67, 13020.93, 13208.64, 13159.9, 12802.16, 12970.38, 13810.05,
        13617.93, 13615.84, 13605.48, 13059.5, 13258.36, 13390.71, 13316.8,
        13080.73, 13735.48, 13074.52, 13294.9, 13692.57, 13840.98, 13937.7,
        14044.63, 13662.3, 13650.45, 13921.79, 13801.33, 12857.43, 12610.97,
        12843.14, 12651.53, 12469.62, 12995.77, 13925.72, 13761.12, 14201.18,
        14127.98, 14091.02, 14152.63, 13820.06, 13664.84, 13769.48, 13416.63,
        13306.53, 13656.22, 13635.74, 13479.92, 13848.39, 13783.62, 13099.68,
        13054.4, 13088.15, 13119.84, 12985.24, 12920.97, 11975.87, 11905.74,
        12202.84, 13077.47, 14047.13, 14223.53, 13779.53, 13983.5, 14815.3,
        14790.14, 15089.43, 14608.9, 14995.63, 14435.19, 15118.84, 14573.46,
        14528.05, 14847.82, 14291.16, 14801.01, 14968.56, 14917.98, 15268.02,
        15242.41, 14992.71, 15100.56, 15607.84, 15966.97, 15628.22, 15614.85,
        15473.72, 15597.96, 15916.8, 15834.56, 15992.55, 16781.47, 17595.63,
        17516.15, 17776.81, 17385.99, 17493.08, 16435.53, 16243.26, 15424.82,
        15695.12, 15563.76, 15374.44, 15613.01, 15591.2, 15928.34, 15834.31,
        15913.29, 15820.18, 15265.07, 15665.84, 15941.05, 15643.78, 16624.32,
        15845.18, 15946.4, 15441.37, 15415.29, 15402.42, 14961.53, 15068.3,
        15063.1, 15047.98, 15241.58, 15243.42, 15217.66, 15121.03, 15150.95,
        15201.11, 15089.53, 15203.62, 15502.56, 15042.63, 15108.67, 15267.57,
        15299.17, 15939.97, 15818.85, 15920.57, 15628.16, 15611.68, 15935.44,
        15811.65, 15897.25, 15107.75, 15738.51, 15296.25, 15461.36, 15369.49,
        14662.33, 14662.07, 14571.28, 14503.39, 13793.73, 13923.41, 14380.84,
        14467.04, 14399.22, 14490.43, 14923.56, 15827.12, 15637.25, 15803.19,
        15674.3, 15885.11, 15540.88, 15766.9, 15280.22, 15479.08, 16172.97,
        16073.9, 16235.33, 16346.41, 16231.31, 15957.27, 15621.28, 15614.85,
        15557.17, 15605.56, 15685.58, 15708.31, 15644.95, 16646.37, 16104.58,
        16122.89, 16162.92, 15965.64, 15894.65, 15784.81, 15837.64, 15825.94,
        15751.37, 15746.02, 15446.98, 15547.06, 15654.97, 15520.22, 15683.4,
        15714.52, 15514.61, 15519.3, 15476.83, 15403.94, 15363.88, 15286.9,
        15368.32, 15299.77, 15279.71, 15527.66, 15512.36, 15479.24, 15437.53,
        15462.95, 15374.91, 15430.34, 15284.97, 15149.87, 14172.6, 13897.33,
        13931.27, 14076.48, 13936.78, 13540.26, 14012.75, 13831.19, 13826.91,
        13781.68, 13855.94, 13801.93, 14462.1, 14224.19, 13790.97, 13598.95,
        13683.63, 13674.95, 13579.14, 13641.26, 13640.15, 13798.0, 13675.93,
        13672.51, 13538.51, 12940.43, 13351.41, 13433.01, 13626.71, 13789.73,
        13676.69, 13548.78, 13727.52, 13726.95, 13568.02, 13257.54, 13332.01,
        13296.84, 13286.38, 13266.98, 13274.75, 13361.01, 13796.07, 13936.27,
        14002.32, 14500.64, 13888.29, 13857.11, 13781.94, 13507.58, 13751.67,
        13669.84, 13636.22, 13183.47, 13109.48, 13086.66, 12873.56, 12953.49,
        13011.17, 13001.12, 13392.13, 13059.72, 13079.97, 13076.71, 13445.21,
        13630.45, 13932.5, 14925.49, 14994.3, 14987.45, 15108.73, 14855.52,
        14854.44, 15090.76, 15125.88, 15123.22, 15410.28, 15013.69, 15308.64,
        15531.09, 15763.16, 15909.61, 15712.49, 15809.31, 17506.35, 17402.79,
        17095.98, 17228.13, 17210.26, 16609.42, 17161.27, 16451.19, 16406.94,
        16361.88, 16559.51, 16920.92, 16238.41, 17262.01, 17270.79, 17337.82,
        17412.64, 17361.24, 16889.64, 17130.33, 16962.56, 17082.1, 17434.64,
        18039.72, 18433.3, 18626.43, 19074.51, 18674.25, 19644.23, 19761.52,
        19674.24, 19740.95, 18586.46, 18292.43, 18899.13, 19352.73, 18650.92,
        18633.68, 18549.58, 18498.17, 18206.32, 18325.96, 18765.87, 19336.35,
        19197.89, 18890.01, 19102.02, 18625.41, 19768.87, 19705.93, 19074.1,
        19125.92
      ],
      BNB: [
        10000.0, 9939.1, 9987.82, 9987.82, 10462.85, 10430.36, 10548.1,
        10596.82, 11035.31, 11100.27, 11238.31, 11384.48, 11648.38, 11900.11,
        12391.37, 12293.95, 12168.09, 12212.74, 11786.43, 11932.6, 12310.18,
        12228.97, 12245.23, 12415.74, 12273.64, 12379.19, 12362.96, 12468.51,
        12375.14, 12915.13, 12423.86, 12691.82, 12874.53, 13260.25, 13422.65,
        13438.89, 13321.13, 13260.25, 13495.72, 13300.84, 12350.78, 12391.37,
        12618.74, 12833.94, 11827.02, 12017.86, 12829.86, 12480.69, 12732.44,
        12842.05, 12708.08, 12736.49, 12630.92, 12618.74, 12525.36, 12220.86,
        12245.23, 12517.24, 12367.01, 12289.87, 12261.46, 12188.37, 11741.78,
        11697.11, 11733.65, 11680.87, 11676.8, 11668.69, 11213.95, 11246.44,
        11120.58, 11550.94, 12553.78, 12549.73, 12403.56, 13430.76, 13670.3,
        13483.54, 13743.39, 13654.07, 13617.52, 13053.17, 13329.26, 13061.3,
        13065.35, 13345.49, 12533.49, 12675.59, 12748.67, 12817.68, 12870.48,
        12801.45, 12704.0, 12476.64, 12626.86, 12773.03, 12683.72, 12622.81,
        12610.63, 12712.13, 12809.58, 13166.85, 12967.91, 13166.85, 13377.98,
        13552.57, 14198.11, 13832.71, 13922.02, 13183.09, 12882.66, 13016.63,
        13487.61, 13418.58, 13455.12, 13686.56, 13394.22, 13503.84, 13154.67,
        13085.66, 13710.92, 13341.44, 13105.95, 13264.3, 13126.26, 13280.53,
        13101.9, 13101.9, 12724.31, 12647.17, 12801.45, 12444.17, 12476.64,
        12651.23, 12663.41, 12744.62, 12630.92, 12732.44, 12602.5, 12545.67,
        12594.4, 12452.28, 12561.91, 12724.31, 12411.68, 12358.91, 12456.35,
        12448.23, 12740.55, 12622.81, 12659.35, 12431.99, 12411.68, 12484.77,
        12436.04, 12423.86, 11161.17, 11453.51, 10483.14, 10682.09, 10600.89,
        9715.79, 9545.25, 9370.67, 9801.05, 9593.98, 9602.11, 9711.73, 9971.56,
        9878.19, 9853.83, 9926.91, 10142.1, 9752.33, 9935.02, 9585.87, 9707.66,
        9589.92, 9650.83, 9374.74, 9464.06, 9784.8, 10056.83, 10020.29,
        10004.05, 9857.88, 9703.61, 9488.42, 9561.51, 9585.87, 9512.79, 9898.48,
        10101.5, 9898.48, 10328.86, 10000.0, 10211.11, 9930.97, 9894.43,
        9768.56, 9788.87, 9861.96, 9890.37, 9817.29, 9821.34, 9683.3, 9650.83,
        9711.73, 9756.38, 9833.52, 9833.52, 9817.29, 9809.16, 10004.05, 9796.98,
        9788.87, 9805.11, 9870.06, 9870.06, 9821.34, 9959.38, 9898.48, 9792.93,
        9711.73, 9764.51, 9756.38, 9760.44, 9622.39, 9415.34, 8976.85, 8777.9,
        8794.14, 8810.39, 8562.72, 8473.4, 8777.9, 8834.75, 8850.99, 8786.03,
        8863.17, 8871.3, 9220.45, 9062.12, 8810.39, 8656.09, 8700.76, 8692.64,
        8725.13, 8704.82, 8733.25, 8822.57, 8708.89, 8708.89, 8635.81, 8371.9,
        8595.21, 8635.81, 8635.81, 8712.95, 8729.18, 8790.08, 8798.21, 8814.45,
        8684.53, 8558.67, 8578.96, 8534.31, 8518.05, 8522.13, 8591.14, 8599.26,
        8729.18, 8729.18, 8733.25, 8891.58, 8708.89, 8668.27, 8668.27, 8566.77,
        8676.4, 8615.5, 8574.9, 8359.72, 8477.46, 8375.96, 8335.36, 8363.77,
        8384.08, 8501.82, 8737.31, 8578.96, 8558.67, 8538.36, 8635.81, 8721.07,
        8846.93, 9334.13, 9212.34, 9037.75, 9086.48, 9086.48, 9159.56, 9248.88,
        9261.06, 9183.92, 9220.45, 9362.56, 9350.38, 9654.88, 9922.84, 10349.15,
        9991.87, 10008.11, 10081.19, 10203.0, 10194.87, 10089.32, 9805.11,
        9841.65, 10263.9, 9861.96, 9935.02, 9914.73, 9930.97, 10324.81, 9135.2,
        9593.98, 9496.53, 9447.83, 9480.3, 9435.65, 9196.08, 9317.89, 9232.63,
        9228.57, 9265.12, 9281.35, 9281.35, 9415.34, 9358.49, 9334.13, 9427.52,
        9675.19, 9707.66, 9732.02, 9939.1, 10353.22, 10288.27, 10300.45,
        9967.51, 9951.28, 9788.87, 9760.44, 10235.47, 10527.81, 11019.08,
        11047.49, 11035.31, 10706.45, 10856.67, 11940.7, 12898.9, 13207.45,
        12622.81, 12809.58
      ],
      SOL: [
        10000.0, 10020.11, 11336.86, 13437.24, 13618.24, 13497.57, 13437.24,
        13105.58, 13778.95, 16392.17, 16372.06, 16271.5, 16472.44, 18160.89,
        23738.91, 23266.47, 23769.07, 23306.69, 21256.42, 21537.8, 25346.92,
        24934.82, 24331.83, 24502.78, 23105.76, 24321.78, 24432.39, 24231.28,
        23980.07, 26402.22, 23879.51, 24161.07, 25065.54, 24703.71, 24824.38,
        24743.94, 23698.69, 23115.82, 23769.07, 23266.47, 20372.06, 20100.73,
        20884.54, 21688.63, 20542.82, 21809.3, 23889.57, 22522.71, 23296.64,
        23708.74, 25326.81, 26362.0, 24975.04, 24231.28, 23949.9, 22924.76,
        22321.78, 23176.15, 22814.32, 22251.39, 22522.71, 22171.12, 21487.7,
        20824.2, 21156.04, 20814.32, 20150.83, 18462.38, 17216.19, 18181.0,
        18040.4, 19929.79, 20643.38, 20995.15, 19336.86, 19728.85, 21296.64,
        21638.35, 22110.79, 22532.77, 22583.05, 21547.85, 22151.01, 20653.44,
        20442.45, 21135.93, 19909.68, 20542.82, 21226.25, 20532.77, 21507.63,
        21276.53, 20653.44, 20552.88, 21055.48, 21025.32, 20693.66, 20593.1,
        20040.4, 20462.56, 20944.87, 23316.75, 23799.24, 24512.83, 24834.44,
        24241.33, 25548.03, 24914.7, 24864.6, 22995.15, 22341.89, 21346.92,
        21970.01, 21588.07, 21467.58, 21809.3, 21316.75, 22362.0, 23377.08,
        23166.09, 23256.42, 22080.62, 22372.06, 22281.56, 21769.07, 22964.98,
        22020.29, 22271.5, 20753.99, 20713.77, 21145.98, 20271.5, 20814.32,
        21005.2, 21055.48, 21216.19, 20753.99, 21085.65, 20552.88, 20462.56,
        20341.89, 19759.02, 19698.69, 20140.95, 19336.86, 19326.81, 19477.46,
        20472.61, 21055.48, 20683.6, 21346.92, 20874.48, 20663.49, 21427.36,
        21246.36, 22201.11, 20090.68, 20492.55, 18673.55, 19025.32, 17537.8,
        15819.18, 15588.07, 15276.53, 14995.15, 14482.49, 14844.32, 15427.19,
        15809.12, 15457.35, 16050.45, 16603.16, 17346.92, 16703.71, 17125.69,
        16633.32, 17105.58, 16362.0, 16743.94, 16080.62, 17990.12, 19236.31,
        18532.77, 19608.19, 19165.92, 19557.91, 18924.76, 20402.22, 21608.19,
        22020.29, 21507.63, 21377.08, 22120.84, 22090.68, 25909.68, 26462.56,
        27477.64, 27738.91, 26764.05, 25628.3, 26522.89, 25638.35, 25879.69,
        25306.69, 24804.27, 23527.92, 23276.53, 25457.53, 25256.42, 25025.32,
        25387.14, 24221.22, 23899.62, 23789.19, 23336.86, 22834.44, 22924.76,
        22774.1, 23367.03, 23156.04, 24221.22, 24472.61, 24733.88, 24693.66,
        25115.82, 24412.28, 25206.32, 23959.95, 22954.93, 22110.79, 21507.63,
        21899.62, 21909.68, 21356.97, 20442.45, 21638.35, 21045.43, 20462.56,
        20422.33, 20904.65, 20623.27, 21959.95, 20944.87, 19889.57, 19356.97,
        19598.13, 19608.19, 19437.42, 20321.78, 19688.63, 20030.34, 19698.69,
        19588.07, 18432.39, 17658.46, 18100.73, 18512.66, 19035.37, 19246.36,
        19165.92, 18884.54, 19909.68, 20160.89, 20392.17, 19648.41, 19567.96,
        19507.63, 19608.19, 19467.58, 19035.37, 19155.86, 19939.84, 20382.11,
        21527.74, 24502.78, 23457.53, 23678.57, 23316.75, 22874.48, 23628.3,
        23417.31, 23356.97, 22201.11, 22261.44, 22070.57, 21447.47, 21899.62,
        22140.95, 21980.07, 24261.44, 24100.73, 23748.96, 24934.82, 27467.58,
        29879.69, 29407.25, 32573.17, 30472.61, 32332.01, 33025.32, 31738.91,
        31789.19, 33125.87, 35125.87, 38130.9, 42522.89, 40231.45, 39940.02,
        42623.45, 41136.1, 41497.75, 43276.7, 43467.76, 45628.47, 56905.0,
        58492.9, 57367.38, 52502.95, 57457.7, 65437.77, 58372.41, 59015.61,
        58633.68, 61025.67, 56724.0, 52080.8, 58291.96, 57126.05, 57156.21,
        58945.22, 58121.02, 55015.61, 58121.02, 59457.7, 59558.26, 60412.63,
        63176.33, 64060.86, 61236.66, 60744.29, 63045.78, 68251.74, 73900.15,
        74261.97, 74553.41, 70080.97, 68080.97, 71658.82, 76101.08, 75065.89,
        74352.3, 72563.46, 74915.23, 73196.61, 82040.93, 95045.96, 97548.56,
        109357.68, 112523.6, 120342.77, 112222.1, 107749.67, 104272.21,
        105508.34, 102322.48
      ],
      XRP: [
        10000.0, 9938.35, 10231.86, 10102.72, 10179.03, 9958.89, 10155.54,
        10093.92, 10044.03, 10270.03, 10308.17, 10912.82, 11024.34, 11262.11,
        11584.96, 11317.88, 11364.82, 11414.73, 11206.34, 11543.88, 12034.05,
        11840.31, 11752.28, 12521.27, 11987.08, 12142.65, 12022.3, 12042.85,
        11940.11, 12119.16, 11537.99, 11954.79, 12163.19, 12057.53, 12092.73,
        12160.25, 11708.25, 11637.79, 11831.51, 11675.96, 11053.71, 11176.97,
        11253.28, 11027.28, 10889.34, 11132.96, 11740.53, 11367.76, 11602.56,
        11584.96, 11367.76, 11655.39, 11488.11, 11587.91, 11414.73, 11059.57,
        11062.51, 11056.65, 11144.68, 11080.11, 11250.36, 11106.54, 11015.54,
        10909.88, 10810.08, 10895.2, 11191.65, 11361.88, 10977.4, 10877.6,
        10613.43, 10751.4, 10971.51, 10971.51, 10540.06, 10710.28, 11118.28,
        10983.25, 11493.97, 11047.83, 13554.44, 12353.96, 13073.07, 12400.93,
        13034.93, 13216.9, 14000.58, 15312.57, 15999.4, 15685.34, 15817.43,
        15010.26, 15271.49, 14637.49, 14790.12, 14884.06, 14743.17, 15054.29,
        14840.03, 14863.52, 15133.54, 15171.69, 14796.0, 15036.69, 15441.72,
        15294.97, 15312.57, 15051.35, 15526.85, 14567.06, 13880.24, 13087.75,
        13836.21, 13583.78, 13539.75, 13756.95, 13460.52, 13639.55, 14009.38,
        14026.98, 13891.98, 13621.95, 13645.44, 13642.49, 13504.53, 13721.75,
        13419.41, 13328.44, 12497.79, 12521.27, 12641.61, 12318.73, 12626.93,
        12456.7, 12503.65, 12579.96, 12800.1, 13111.24, 13563.24, 13748.15,
        13795.12, 13457.58, 13551.5, 13665.98, 13322.55, 13319.61, 13715.87,
        13850.89, 14194.29, 14479.0, 15315.51, 15233.34, 14939.83, 15397.69,
        15248.0, 15887.86, 14834.15, 15585.54, 15203.97, 15438.8, 15779.26,
        14954.49, 15186.37, 15391.83, 15236.26, 14047.55, 14079.83, 13894.92,
        14117.98, 14297.03, 14517.15, 14440.83, 14740.23, 14508.35, 14561.18,
        14285.29, 14399.75, 14082.75, 14150.26, 13648.35, 13918.38, 13968.29,
        13871.44, 14235.38, 14343.98, 14346.92, 14029.92, 13733.47, 13730.55,
        13798.04, 13759.89, 14003.52, 13980.04, 13827.41, 23780.44, 21085.97,
        20956.83, 22145.56, 21646.59, 22574.1, 23988.84, 23489.84, 22612.25,
        21969.45, 21576.14, 20654.51, 20871.71, 21103.6, 20956.83, 20895.2,
        20915.74, 20589.94, 20510.69, 20657.46, 20158.49, 19571.46, 18693.84,
        18497.18, 18353.38, 18259.44, 18911.04, 18808.32, 18573.5, 18570.58,
        18438.5, 18385.67, 18567.64, 17869.07, 17240.96, 14977.97, 14869.37,
        15224.52, 15917.22, 15383.03, 15195.17, 15500.43, 15198.11, 15450.54,
        15391.83, 15341.94, 15350.74, 15911.34, 15494.57, 15024.94, 14614.01,
        14640.43, 14798.92, 14913.4, 14840.03, 14751.97, 14828.29, 14828.29,
        14787.2, 14634.57, 13883.18, 14164.95, 14211.89, 14429.09, 14757.83,
        14684.46, 14458.46, 14831.23, 15077.77, 15289.09, 14919.26, 15092.43,
        14884.06, 14878.17, 14816.55, 14687.4, 14693.26, 14919.26, 15297.91,
        15154.09, 15415.31, 15022.0, 15497.49, 15656.0, 15383.03, 15450.54,
        15315.51, 15177.57, 14754.92, 14649.23, 14314.63, 14223.64, 14276.49,
        14273.55, 14308.78, 14643.37, 14417.37, 14352.78, 14928.09, 15268.54,
        15330.2, 15324.31, 16149.08, 16498.37, 16278.23, 16257.68, 16014.08,
        16025.82, 16354.54, 16973.87, 17557.96, 17898.44, 17722.33, 17986.47,
        18092.15, 19134.12, 21179.91, 20023.46, 20176.09, 19474.6, 19410.03,
        19533.29, 19515.69, 19195.75, 18476.64, 19031.38, 18053.99, 18074.53,
        17913.1, 18277.07, 17986.47, 17038.45, 18007.04, 18209.56, 18212.5,
        18215.41, 18159.67, 17643.07, 17916.04, 17857.33, 17772.22, 17957.13,
        18133.24, 18359.24, 18282.93, 18194.87, 18652.75, 18825.92, 19618.41,
        19697.66, 19448.18, 18200.75, 18133.24, 18476.64, 18573.5, 18165.53,
        18186.07, 17995.3, 17960.07, 17804.5, 18156.73, 18306.41, 18324.01,
        18183.13, 17913.1, 18896.38, 18221.3, 18567.64, 18594.07, 18180.21,
        18233.04
      ],
      USDT: [
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0
      ],
      HODLIE: [
        10000.0, 9992.61, 10398.82, 10998.54, 11041.13, 11009.34, 10990.47,
        10993.62, 11027.57, 11682.69, 11665.16, 11637.45, 11691.35, 11962.19,
        12438.97, 12361.03, 12429.91, 12344.58, 12151.55, 12139.05, 12274.87,
        12181.56, 12081.87, 12100.76, 11989.39, 12032.53, 12020.06, 12021.61,
        11946.91, 12083.56, 11730.47, 11776.89, 11747.07, 11698.32, 11739.13,
        11783.14, 11682.95, 11674.4, 11675.63, 11601.51, 11209.21, 11230.55,
        11231.44, 11232.26, 11040.79, 11195.42, 11538.54, 11475.3, 11567.59,
        11561.55, 11528.44, 11687.06, 11479.79, 11374.59, 11337.58, 11251.53,
        11260.28, 11261.39, 11260.74, 11185.66, 11335.16, 11292.69, 10995.88,
        10977.4, 10977.52, 10985.73, 10907.23, 10966.78, 10876.86, 10790.01,
        10789.5, 10791.87, 10950.17, 11052.42, 10978.1, 11117.62, 11665.87,
        11540.2, 11792.19, 11653.97, 11649.2, 11359.96, 11522.5, 11381.91,
        11383.31, 11459.05, 11629.83, 11895.51, 12033.96, 11976.2, 12002.61,
        11963.57, 11861.95, 11772.86, 11771.43, 11904.6, 11780.65, 11766.39,
        11747.18, 11754.52, 12002.43, 12136.3, 12238.42, 12348.37, 12420.82,
        12394.12, 12486.83, 12327.69, 12259.47, 12012.82, 12011.33, 12011.6,
        12013.55, 12001.1, 12007.52, 12029.48, 12034.79, 12259.67, 12250.51,
        12196.03, 12211.18, 11998.94, 11954.94, 11979.67, 11865.18, 12173.65,
        11892.32, 11890.91, 11889.03, 11888.67, 11889.31, 11887.49, 11903.25,
        11898.07, 11907.26, 11955.03, 11887.52, 11914.74, 12016.13, 12062.29,
        12071.84, 11998.38, 12032.52, 12148.78, 11970.47, 11967.36, 11976.16,
        12003.31, 12098.74, 11987.78, 12122.68, 12108.0, 12053.47, 12149.75,
        12094.43, 12243.27, 11984.96, 12004.29, 11928.95, 11928.8, 11922.45,
        11677.02, 11676.11, 11675.17, 11460.94, 11274.75, 11272.4, 11240.17,
        11284.13, 11248.57, 11339.5, 11591.02, 11970.96, 11931.76, 12071.96,
        12045.17, 12043.15, 11853.39, 11817.33, 11739.95, 11708.29, 11967.45,
        11829.51, 12048.19, 12023.42, 12038.36, 11877.78, 11953.46, 12191.67,
        12272.94, 12166.16, 12141.88, 12168.0, 12112.41, 12791.67, 12452.75,
        12434.98, 12607.03, 12526.12, 12655.13, 12846.99, 12782.69, 12669.69,
        12644.33, 12633.1, 12455.89, 12455.7, 12456.33, 12440.87, 12404.74,
        12458.32, 12359.73, 12329.86, 12348.62, 12251.13, 12246.53, 12219.49,
        12227.03, 12240.96, 12201.48, 12373.26, 12411.24, 12450.21, 12436.53,
        12432.14, 12330.97, 12408.39, 12211.46, 12217.07, 12214.08, 12212.7,
        12212.7, 12212.58, 12134.99, 12101.52, 12109.38, 11951.13, 11946.7,
        11942.66, 11907.58, 11916.13, 12164.13, 12043.29, 11785.46, 11686.0,
        11690.69, 11690.48, 11664.63, 11649.32, 11524.06, 11483.57, 11404.11,
        11404.52, 11391.55, 11383.4, 11353.05, 11326.43, 11380.98, 11358.24,
        11335.14, 11290.0, 11252.41, 11304.87, 11346.28, 11233.53, 11270.26,
        11257.75, 11233.4, 11206.04, 11191.75, 11120.66, 11297.51, 11353.63,
        11379.78, 11865.95, 11694.84, 11732.67, 11679.1, 11616.14, 11632.7,
        11627.1, 11620.35, 11523.17, 11523.74, 11523.12, 11522.78, 11522.89,
        11522.94, 11513.83, 11903.01, 11867.33, 11813.8, 12005.21, 12398.77,
        12753.17, 12692.37, 13138.71, 12983.01, 13065.68, 12995.98, 12831.78,
        12846.92, 12933.18, 12918.31, 13272.9, 13756.72, 13535.33, 13498.29,
        13787.03, 13690.9, 13827.7, 13643.1, 13669.22, 13910.21, 14336.08,
        14467.91, 14380.0, 13987.45, 13831.04, 14476.57, 13960.89, 14011.18,
        13970.02, 13976.56, 13894.71, 13611.99, 13736.99, 13739.34, 13765.63,
        13815.56, 13687.42, 13460.63, 13461.34, 13395.27, 13399.55, 13459.22,
        13617.72, 13762.85, 13915.62, 14294.65, 14211.12, 14111.43, 14521.27,
        14547.23, 14572.52, 14391.74, 14516.34, 14492.84, 14608.83, 14548.61,
        14507.51, 14444.69, 14444.41, 14350.88, 14641.79, 15381.1, 15509.6,
        16125.58, 16271.88, 16640.78, 16281.45, 16556.29, 16641.76, 16240.33,
        16243.68
      ]
    },
    2024: {
      TIMESTAMP: [
        '2024-01-01',
        '2024-01-02',
        '2024-01-03',
        '2024-01-04',
        '2024-01-05',
        '2024-01-06',
        '2024-01-07',
        '2024-01-08',
        '2024-01-09',
        '2024-01-10',
        '2024-01-11',
        '2024-01-12',
        '2024-01-13',
        '2024-01-14',
        '2024-01-15',
        '2024-01-16',
        '2024-01-17',
        '2024-01-18',
        '2024-01-19',
        '2024-01-20',
        '2024-01-21',
        '2024-01-22',
        '2024-01-23',
        '2024-01-24',
        '2024-01-25',
        '2024-01-26',
        '2024-01-27',
        '2024-01-28',
        '2024-01-29',
        '2024-01-30',
        '2024-01-31',
        '2024-02-01',
        '2024-02-02',
        '2024-02-03',
        '2024-02-04',
        '2024-02-05',
        '2024-02-06',
        '2024-02-07',
        '2024-02-08',
        '2024-02-09',
        '2024-02-10',
        '2024-02-11',
        '2024-02-12',
        '2024-02-13',
        '2024-02-14',
        '2024-02-15',
        '2024-02-16',
        '2024-02-17',
        '2024-02-18',
        '2024-02-19',
        '2024-02-20',
        '2024-02-21',
        '2024-02-22',
        '2024-02-23',
        '2024-02-24',
        '2024-02-25',
        '2024-02-26',
        '2024-02-27',
        '2024-02-28',
        '2024-02-29',
        '2024-03-01',
        '2024-03-02',
        '2024-03-03',
        '2024-03-04',
        '2024-03-05',
        '2024-03-06',
        '2024-03-07',
        '2024-03-08',
        '2024-03-09',
        '2024-03-10',
        '2024-03-11',
        '2024-03-12',
        '2024-03-13',
        '2024-03-14',
        '2024-03-15',
        '2024-03-16',
        '2024-03-17',
        '2024-03-18',
        '2024-03-19',
        '2024-03-20',
        '2024-03-21',
        '2024-03-22',
        '2024-03-23',
        '2024-03-24',
        '2024-03-25',
        '2024-03-26',
        '2024-03-27',
        '2024-03-28',
        '2024-03-29',
        '2024-03-30',
        '2024-03-31',
        '2024-04-01',
        '2024-04-02',
        '2024-04-03',
        '2024-04-04',
        '2024-04-05',
        '2024-04-06',
        '2024-04-07',
        '2024-04-08',
        '2024-04-09',
        '2024-04-10',
        '2024-04-11',
        '2024-04-12',
        '2024-04-13',
        '2024-04-14',
        '2024-04-15',
        '2024-04-16',
        '2024-04-17',
        '2024-04-18',
        '2024-04-19',
        '2024-04-20',
        '2024-04-21',
        '2024-04-22',
        '2024-04-23',
        '2024-04-24',
        '2024-04-25',
        '2024-04-26',
        '2024-04-27',
        '2024-04-28',
        '2024-04-29',
        '2024-04-30',
        '2024-05-01',
        '2024-05-02',
        '2024-05-03',
        '2024-05-04',
        '2024-05-05',
        '2024-05-06',
        '2024-05-07',
        '2024-05-08',
        '2024-05-09',
        '2024-05-10',
        '2024-05-11',
        '2024-05-12',
        '2024-05-13',
        '2024-05-14',
        '2024-05-15',
        '2024-05-16',
        '2024-05-17',
        '2024-05-18',
        '2024-05-19',
        '2024-05-20',
        '2024-05-21',
        '2024-05-22',
        '2024-05-23',
        '2024-05-24',
        '2024-05-25',
        '2024-05-26',
        '2024-05-27',
        '2024-05-28',
        '2024-05-29',
        '2024-05-30',
        '2024-05-31',
        '2024-06-01',
        '2024-06-02',
        '2024-06-03',
        '2024-06-04',
        '2024-06-05',
        '2024-06-06',
        '2024-06-07',
        '2024-06-08',
        '2024-06-09',
        '2024-06-10',
        '2024-06-11',
        '2024-06-12',
        '2024-06-13',
        '2024-06-14',
        '2024-06-15',
        '2024-06-16',
        '2024-06-17',
        '2024-06-18',
        '2024-06-19',
        '2024-06-20',
        '2024-06-21',
        '2024-06-22',
        '2024-06-23',
        '2024-06-24',
        '2024-06-25',
        '2024-06-26',
        '2024-06-27',
        '2024-06-28',
        '2024-06-29',
        '2024-06-30',
        '2024-07-01',
        '2024-07-02',
        '2024-07-03',
        '2024-07-04',
        '2024-07-05',
        '2024-07-06',
        '2024-07-07',
        '2024-07-08',
        '2024-07-09',
        '2024-07-10',
        '2024-07-11',
        '2024-07-12',
        '2024-07-13',
        '2024-07-14',
        '2024-07-15',
        '2024-07-16',
        '2024-07-17',
        '2024-07-18',
        '2024-07-19',
        '2024-07-20',
        '2024-07-21',
        '2024-07-22',
        '2024-07-23',
        '2024-07-24',
        '2024-07-25',
        '2024-07-26',
        '2024-07-27',
        '2024-07-28',
        '2024-07-29',
        '2024-07-30',
        '2024-07-31',
        '2024-08-01',
        '2024-08-02',
        '2024-08-03',
        '2024-08-04',
        '2024-08-05',
        '2024-08-06',
        '2024-08-07',
        '2024-08-08',
        '2024-08-09',
        '2024-08-10',
        '2024-08-11',
        '2024-08-12',
        '2024-08-13',
        '2024-08-14',
        '2024-08-15',
        '2024-08-16',
        '2024-08-17',
        '2024-08-18',
        '2024-08-19',
        '2024-08-20',
        '2024-08-21',
        '2024-08-22',
        '2024-08-23',
        '2024-08-24',
        '2024-08-25',
        '2024-08-26',
        '2024-08-27',
        '2024-08-28',
        '2024-08-29',
        '2024-08-30',
        '2024-08-31',
        '2024-09-01',
        '2024-09-02',
        '2024-09-03',
        '2024-09-04',
        '2024-09-05',
        '2024-09-06',
        '2024-09-07',
        '2024-09-08',
        '2024-09-09',
        '2024-09-10',
        '2024-09-11',
        '2024-09-12',
        '2024-09-13',
        '2024-09-14',
        '2024-09-15',
        '2024-09-16',
        '2024-09-17',
        '2024-09-18',
        '2024-09-19',
        '2024-09-20',
        '2024-09-21',
        '2024-09-22',
        '2024-09-23',
        '2024-09-24',
        '2024-09-25',
        '2024-09-26',
        '2024-09-27',
        '2024-09-28',
        '2024-09-29',
        '2024-09-30'
      ],
      BTC: [
        10000.0, 10301.02, 10644.57, 10116.38, 10487.51, 10404.55, 10383.54,
        10379.8, 11122.18, 10945.65, 11099.16, 10980.2, 10059.66, 10173.41,
        10003.11, 10087.58, 10224.37, 10109.86, 9786.26, 9864.92, 9887.28,
        9852.22, 9426.12, 9339.96, 9436.35, 9455.35, 9916.8, 9975.78, 9899.7,
        10201.91, 10253.18, 10094.23, 10158.37, 10223.55, 10183.03, 10074.24,
        10058.42, 10222.94, 10450.28, 10713.39, 11186.7, 11317.63, 11391.01,
        11837.11, 11718.89, 12256.28, 12221.25, 12300.16, 12236.89, 12362.14,
        12290.35, 12345.31, 12189.71, 12176.67, 12018.59, 12200.47, 12234.88,
        12934.81, 13463.27, 14500.23, 14488.19, 14787.79, 14597.29, 14867.63,
        16074.9, 15002.64, 15598.64, 15879.21, 16115.83, 16178.05, 16190.72,
        17142.63, 16839.94, 17263.48, 16929.2, 16352.99, 15703.84, 16145.63,
        16018.79, 14821.84, 16016.4, 15474.55, 14913.66, 15322.08, 15843.49,
        16638.32, 16623.93, 16432.48, 16765.63, 16519.52, 16498.91, 16794.51,
        16496.75, 15574.83, 15651.52, 16076.77, 16003.76, 16341.57, 16375.64,
        17018.39, 16405.38, 16689.91, 16600.75, 15867.65, 15214.54, 15459.91,
        15017.31, 15093.66, 14598.45, 15026.72, 15148.06, 15290.29, 15388.94,
        15861.65, 15704.25, 15167.82, 15287.79, 15102.96, 14969.99, 14944.21,
        15119.07, 14338.1, 13696.99, 14039.52, 14856.98, 15053.74, 15111.98,
        15025.76, 14873.19, 14494.57, 14974.84, 14400.63, 14413.88, 14512.32,
        14854.04, 14602.91, 15645.91, 15480.89, 15800.98, 15838.94, 15704.43,
        16468.12, 16617.54, 16361.75, 16017.48, 16278.15, 16358.84, 16224.2,
        16468.31, 16237.3, 16015.95, 16166.2, 15975.61, 16036.16, 16074.63,
        16338.37, 16697.78, 16835.87, 16778.17, 16446.41, 16408.33, 16478.43,
        16445.68, 15940.27, 16173.51, 15830.42, 15621.7, 15665.72, 15797.29,
        15773.62, 15448.0, 15342.94, 15379.36, 15181.78, 15219.83, 15064.17,
        14210.12, 14650.52, 14417.34, 14586.63, 14285.62, 14428.08, 14885.04,
        14902.57, 14693.12, 14273.32, 13734.45, 13398.94, 13750.44, 13345.56,
        13413.94, 13710.1, 13656.79, 13568.73, 13676.03, 14071.83, 14402.38,
        15226.42, 15291.09, 15208.8, 15152.68, 15789.05, 15882.0, 16094.51,
        16005.3, 15598.99, 15449.9, 15534.14, 16074.16, 16238.39, 16094.09,
        15908.83, 15670.92, 15377.01, 15425.38, 14526.52, 14347.58, 13894.65,
        12943.35, 13299.36, 13082.53, 14750.38, 14379.23, 14418.67, 13960.95,
        13974.12, 14337.01, 13958.57, 13634.71, 13953.98, 14036.24, 14044.71,
        14018.92, 14048.97, 14476.94, 14301.49, 15165.21, 15105.81, 15259.17,
        14902.07, 14081.86, 14008.15, 14012.05, 14018.1, 13967.57, 13561.73,
        14044.71, 13693.3, 13756.25, 13276.72, 12694.44, 12767.8, 12904.11,
        13539.72, 13634.34, 13599.36, 13759.17, 14342.42, 14200.18, 14063.73,
        13718.16, 14255.33, 14360.58, 14894.27, 14951.11, 14962.96, 15117.06,
        14960.29, 15282.34, 14970.46, 15402.17, 15587.62, 15542.67, 15541.34
      ],
      ETH: [
        10000.0, 10261.29, 10370.41, 9695.0, 9979.02, 9881.21, 9835.93, 9743.36,
        10276.32, 10290.7, 11199.36, 11497.51, 10983.97, 11333.75, 11028.98,
        11051.58, 11401.37, 11115.89, 10860.56, 10950.12, 10866.36, 10823.85,
        10201.55, 9787.4, 9765.29, 9765.42, 9970.27, 9963.47, 9888.43, 10161.15,
        10396.48, 10048.26, 10097.94, 10149.98, 10103.87, 10058.86, 10087.31,
        10470.29, 10662.83, 10657.25, 10969.1, 10972.48, 10991.78, 11671.81,
        11587.46, 12185.93, 12367.88, 12260.75, 12245.32, 12660.61, 13018.94,
        13166.38, 12898.87, 13134.9, 12849.24, 13137.01, 13638.3, 14001.33,
        14276.82, 14684.24, 14606.35, 15092.55, 14964.15, 15271.08, 15863.05,
        15414.78, 16763.98, 16955.59, 17042.95, 17149.32, 16809.16, 17882.2,
        17363.47, 17509.94, 17046.15, 16396.37, 15641.09, 16025.74, 15484.24,
        14116.98, 15476.11, 15312.31, 14450.68, 14845.89, 15129.16, 15877.42,
        15847.84, 15452.76, 15679.34, 15482.39, 15414.3, 15956.76, 15404.67,
        14499.66, 14588.55, 14560.94, 14571.32, 14851.61, 15038.87, 16358.74,
        15468.36, 15554.14, 15410.92, 14150.66, 13270.83, 13876.96, 13745.69,
        13599.62, 13225.91, 13497.39, 13495.16, 13807.98, 13872.21, 14149.94,
        14135.1, 13769.3, 13890.62, 13770.18, 14266.0, 14465.68, 14135.1,
        13232.59, 13054.32, 13176.76, 13654.13, 13660.28, 13768.37, 13547.69,
        13349.21, 13052.66, 13427.02, 12772.98, 12818.83, 12889.07, 12925.57,
        12702.78, 13246.82, 12922.35, 13588.14, 13706.52, 13526.86, 15878.34,
        16765.65, 16462.98, 16753.33, 16429.7, 16430.93, 16872.28, 17091.21,
        16981.92, 16614.16, 16473.93, 16574.76, 16801.08, 16627.16, 16610.51,
        16768.23, 16928.11, 16757.41, 16243.65, 16162.91, 16299.39, 16153.84,
        15388.8, 15700.49, 15306.11, 15320.31, 15696.65, 15951.54, 15491.71,
        15299.51, 15636.78, 15472.01, 15489.78, 15397.15, 15058.27, 14650.58,
        14960.36, 14860.14, 15171.89, 14833.68, 14843.99, 15169.22, 15137.96,
        15055.1, 14515.71, 13751.24, 13088.52, 13471.11, 12913.82, 13296.68,
        13450.67, 13631.88, 13640.85, 13747.67, 14031.82, 14269.08, 15266.33,
        15072.58, 14925.37, 15094.22, 15422.83, 15448.58, 15533.39, 15158.36,
        15308.74, 14635.55, 13913.67, 14417.53, 14397.89, 14329.93, 14636.15,
        14414.82, 14257.87, 14107.83, 13093.54, 12789.73, 11966.78, 10782.57,
        10881.98, 10359.11, 11903.04, 11422.69, 11457.72, 11287.12, 11853.14,
        11928.62, 11737.89, 11326.2, 11411.69, 11468.75, 11634.53, 11529.91,
        11372.53, 11564.99, 11536.81, 12132.47, 12097.04, 12181.63, 11810.42,
        10815.98, 11132.72, 11096.51, 11104.94, 11064.84, 10578.29, 11188.55,
        10786.1, 10826.18, 10425.97, 9712.98, 9956.3, 10082.16, 10363.06,
        10509.01, 10286.7, 10379.03, 10756.24, 10624.94, 10314.97, 10042.51,
        10277.04, 10285.65, 10867.03, 11212.46, 11323.73, 11385.46, 11633.43,
        11720.66, 11308.43, 11595.49, 11893.51, 11759.42, 11675.11
      ],
      BNB: [
        10000.0, 10090.11, 10035.4, 10186.62, 10350.72, 10183.41, 9890.6,
        9700.77, 9842.36, 9671.81, 9864.88, 9887.39, 9498.08, 9732.96, 9758.69,
        10176.97, 10157.66, 10000.0, 10057.92, 10080.45, 10196.27, 10289.59,
        9855.22, 9565.65, 9420.85, 9401.55, 9723.31, 9839.13, 9790.86, 9977.48,
        9958.18, 9694.35, 9642.87, 9694.35, 9652.51, 9835.92, 9665.39, 9761.92,
        9909.91, 10267.06, 10408.64, 10411.85, 10321.76, 10566.28, 10444.02,
        10740.04, 11303.1, 11566.93, 11357.79, 11367.45, 11367.45, 11412.5,
        12065.64, 12380.96, 12043.12, 12290.87, 12557.93, 12918.28, 12741.32,
        13256.12, 12747.76, 13072.73, 13156.38, 13352.65, 13481.35, 12612.62,
        13725.87, 15112.62, 15424.72, 15704.65, 16750.34, 16801.81, 17265.14,
        19797.32, 19430.52, 19642.87, 18857.8, 18545.71, 17815.33, 16389.98,
        17892.55, 17799.23, 17426.01, 17960.12, 18359.09, 18938.24, 18677.62,
        18507.1, 18822.4, 19890.62, 19430.52, 19491.65, 18593.96, 17947.24,
        18121.0, 18719.45, 18680.84, 18922.14, 18725.89, 18976.85, 18751.62,
        19562.44, 19510.96, 19134.51, 17853.94, 18217.53, 17927.94, 17400.27,
        17310.18, 17728.45, 17911.86, 18301.17, 18667.97, 19462.7, 19601.05,
        19504.52, 19729.75, 19250.33, 19128.07, 19321.13, 19131.28, 18590.75,
        18008.37, 18104.9, 18893.2, 18825.63, 18963.99, 19009.02, 18703.35,
        18912.49, 19263.21, 18854.59, 19070.15, 19150.59, 19047.63, 18214.3,
        18809.54, 18301.17, 18674.41, 18597.19, 18519.96, 19050.84, 19893.83,
        19787.67, 19240.69, 19372.61, 19350.09, 19333.99, 19430.52, 19391.91,
        19192.43, 19144.16, 19131.28, 19340.44, 19372.61, 20205.94, 21956.26,
        22387.4, 22898.99, 22039.91, 21965.92, 21666.69, 19887.41, 19417.64,
        19954.96, 19359.72, 19356.51, 19575.3, 19649.31, 19504.52, 18960.76,
        19243.91, 19041.19, 18861.01, 18992.94, 18661.53, 18149.95, 18574.65,
        18426.66, 18667.97, 18252.91, 18317.27, 18812.75, 18606.84, 18658.32,
        17924.72, 16875.81, 15974.92, 17001.3, 15855.87, 16444.67, 16589.47,
        16850.08, 16888.69, 17146.09, 17168.61, 17525.76, 18725.89, 18420.22,
        18314.04, 18410.57, 19095.9, 19089.46, 19321.13, 19021.89, 18745.2,
        18455.6, 18407.36, 18722.66, 18790.23, 18735.54, 18577.88, 18738.75,
        18616.49, 18526.41, 17451.74, 16985.22, 16206.57, 15038.63, 15675.69,
        15318.55, 16698.86, 16386.75, 16846.87, 16306.32, 16550.85, 16879.04,
        16862.94, 16734.24, 16731.03, 17419.57, 17255.49, 17982.64, 18458.83,
        18336.57, 18870.67, 19066.94, 18571.44, 18597.19, 17651.24, 17084.96,
        17290.87, 17252.26, 17229.74, 17168.61, 16367.45, 16981.99, 16846.87,
        16438.23, 16148.66, 15575.95, 15820.47, 16122.93, 16705.28, 16750.34,
        17052.78, 17445.32, 17956.89, 17779.93, 18034.12, 17062.43, 17548.27,
        17770.29, 18211.08, 18281.87, 18909.28, 18931.8, 19507.73, 19559.23,
        18889.97, 19176.33, 19591.4, 19324.34, 19214.95
      ],
      SOL: [
        10000.0, 10752.0, 10741.06, 9828.91, 10422.75, 9868.7, 9290.76, 8881.92,
        9748.33, 9900.53, 10272.54, 9977.11, 8979.4, 9566.3, 9616.03, 9371.32,
        9762.26, 10066.65, 9416.09, 9294.74, 9262.91, 9100.75, 8352.73, 8224.41,
        8703.86, 8680.99, 9181.33, 9319.6, 9434.0, 10037.78, 10291.45, 9718.49,
        9660.8, 9980.1, 9743.35, 9526.51, 9425.04, 9694.62, 10042.76, 10208.88,
        10731.12, 10881.32, 10697.3, 11099.17, 11144.92, 11650.24, 11142.94,
        10913.15, 10865.41, 11220.52, 11146.91, 10827.61, 10406.83, 10242.7,
        9930.37, 10357.1, 10296.43, 10909.17, 10788.81, 11521.92, 12299.8,
        12997.1, 12762.35, 13025.95, 13010.04, 12125.72, 12999.09, 14397.68,
        14334.01, 14417.58, 14108.22, 14708.04, 14757.78, 16259.81, 17200.83,
        18316.9, 18005.56, 20008.94, 19710.52, 16835.77, 18891.86, 17736.99,
        16941.19, 17382.85, 18399.47, 18996.3, 19123.63, 18598.41, 18892.85,
        19122.63, 19514.56, 19956.23, 19182.33, 18312.94, 18515.86, 18132.88,
        17348.04, 17765.83, 17762.85, 18018.48, 17307.27, 17304.28, 17183.91,
        15071.11, 14026.64, 14981.59, 13920.22, 13686.46, 13387.05, 14213.66,
        14290.26, 14838.34, 14816.46, 15719.68, 15446.12, 14614.53, 14451.4,
        13847.6, 13993.82, 13833.67, 13691.42, 12670.84, 13267.67, 13800.84,
        14261.4, 14523.02, 14439.46, 15377.49, 14922.9, 14078.38, 15214.35,
        14505.11, 14503.12, 14247.48, 14614.53, 14230.56, 15505.8, 15802.23,
        16776.07, 17111.29, 17051.61, 18462.14, 17755.88, 17656.41, 17530.08,
        16636.82, 16704.46, 16289.65, 17024.76, 16813.87, 16874.54, 16631.84,
        16527.4, 16583.1, 16242.9, 16457.76, 16990.94, 17226.69, 16965.08,
        16223.01, 15766.44, 16106.62, 15852.97, 14869.18, 15477.95, 14738.87,
        14256.44, 14470.29, 14960.71, 14306.17, 13691.42, 13399.97, 13321.4,
        13411.91, 13356.19, 12853.86, 12952.35, 13617.82, 13667.55, 14941.8,
        13929.16, 13932.15, 14626.48, 14601.6, 15390.42, 14061.46, 13176.16,
        13353.23, 14311.14, 13234.84, 13956.03, 14059.47, 14082.36, 13604.88,
        13804.82, 14145.02, 14640.39, 15794.28, 15907.67, 15517.75, 15912.65,
        16790.0, 17246.57, 18298.01, 17757.87, 17199.83, 17643.49, 17138.16,
        18227.38, 18471.09, 18246.29, 18234.35, 17838.45, 17241.62, 16658.7,
        15199.42, 14208.68, 13999.79, 13181.14, 14436.47, 14507.1, 16064.85,
        15493.88, 15384.45, 14144.03, 14337.0, 14632.43, 14358.9, 14293.24,
        13886.4, 14078.38, 14382.76, 14283.29, 14248.48, 14214.66, 14244.5,
        15103.93, 15823.13, 15977.3, 15660.99, 14670.23, 14278.32, 13908.28,
        13697.39, 13467.61, 12842.93, 13408.93, 12880.73, 13336.31, 12888.67,
        12406.24, 12614.14, 12871.77, 13374.11, 13526.29, 13188.09, 13564.1,
        13863.51, 13643.68, 13118.47, 13003.07, 13101.55, 13074.7, 14166.9,
        14635.42, 14776.67, 14471.29, 14363.85, 15254.15, 14838.34, 15551.57,
        15722.66, 15589.37, 15818.15
      ],
      XRP: [
        10000.0, 10253.51, 10260.06, 9537.14, 9612.37, 9388.29, 9291.79,
        9005.56, 9486.43, 9352.31, 9939.48, 9852.79, 9280.34, 9425.91, 9574.74,
        9458.63, 9458.63, 9329.41, 9039.92, 8941.78, 8997.39, 8967.94, 8653.91,
        8428.21, 8446.2, 8420.02, 8706.26, 8678.44, 8562.31, 8771.68, 8339.88,
        8212.3, 8269.55, 8357.87, 8508.34, 8240.11, 8305.53, 8303.89, 8377.5,
        8410.2, 8601.57, 8572.14, 8585.22, 8688.26, 8603.21, 8819.11, 9172.4,
        9241.08, 9000.66, 9160.95, 9257.45, 9231.27, 8958.13, 8891.07, 8735.69,
        8925.41, 8902.52, 8994.11, 9596.01, 9370.3, 9555.13, 9824.99, 10392.54,
        10282.97, 10657.51, 9633.64, 9983.65, 10328.76, 10109.59, 10168.46,
        9816.81, 11871.13, 11239.79, 11220.15, 10945.37, 10356.56, 9913.32,
        10140.66, 10353.29, 9589.47, 10022.9, 10441.61, 9952.57, 10179.92,
        10358.2, 10521.75, 10354.93, 10049.07, 10224.08, 10279.69, 10140.66,
        10279.69, 10008.18, 9627.09, 9404.64, 9646.71, 9597.65, 9718.69,
        9692.51, 10122.67, 10181.55, 10094.87, 9947.67, 8913.97, 7778.88,
        8254.83, 8164.87, 8127.25, 8130.53, 8225.39, 8271.18, 8608.12, 8599.93,
        9138.04, 8941.78, 8599.93, 8603.21, 8572.14, 8460.91, 8395.49, 8433.11,
        8173.05, 8418.38, 8496.89, 8722.61, 8653.91, 8652.28, 8864.9, 8644.1,
        8451.1, 8521.43, 8205.76, 8274.46, 8168.14, 8248.28, 8187.77, 8470.73,
        8428.21, 8572.14, 8511.62, 8339.88, 8748.78, 8802.75, 8619.56, 8614.66,
        8758.59, 8856.73, 8662.09, 8758.59, 8662.09, 8581.94, 8500.17, 8490.35,
        8505.08, 8405.3, 8523.07, 8593.39, 8596.67, 8536.15, 8191.04, 8070.01,
        8150.14, 8123.98, 7855.74, 8034.03, 7839.39, 7765.79, 8052.02, 8002.95,
        8290.81, 8034.03, 8066.73, 8001.31, 8016.04, 7970.23, 7849.2, 7724.9,
        7780.5, 7708.54, 7782.14, 7715.08, 7723.26, 7800.13, 7809.95, 7930.99,
        7636.57, 7268.57, 6951.26, 7345.44, 6879.29, 7055.94, 7123.0, 7168.79,
        7322.54, 7759.24, 8563.95, 8637.56, 8760.23, 9432.46, 10358.2, 9308.14,
        9332.68, 9753.03, 9774.29, 9934.58, 9793.92, 9972.2, 9756.29, 9864.25,
        9826.63, 9793.92, 9854.43, 10309.13, 10371.28, 9787.37, 9174.02, 9080.8,
        8639.2, 8112.54, 8302.26, 10035.98, 10003.28, 9509.32, 9600.92, 9030.09,
        9254.17, 9421.01, 9298.33, 9169.12, 9228.01, 9250.91, 9345.76, 9764.48,
        9728.49, 9823.35, 9775.92, 9991.83, 9972.2, 9862.61, 9630.36, 9265.62,
        9327.77, 9167.49, 9262.35, 9280.34, 8920.51, 9286.89, 9208.38, 9156.03,
        8910.7, 8506.71, 8578.68, 8614.66, 8820.75, 8863.27, 8758.59, 9213.28,
        9362.12, 9744.85, 9425.91, 9514.23, 9576.38, 9509.32, 9643.44, 9605.82,
        9790.65, 9641.8, 9576.38, 9689.23, 9568.2, 9663.07, 9618.91, 10071.96,
        10474.32
      ],
      USDT: [
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0
      ],
      HODLIE: [
        10000.0, 10078.82, 10164.8, 9917.35, 10094.98, 10041.04, 9965.91,
        9952.57, 10210.96, 10130.3, 10345.59, 10483.07, 10253.7, 10412.09,
        10329.02, 10353.27, 10411.66, 10344.42, 10124.08, 10126.8, 10138.26,
        10158.83, 10110.73, 10109.26, 10108.53, 10040.16, 10232.67, 10282.35,
        10216.59, 10429.16, 10518.86, 10338.86, 10334.19, 10321.97, 10284.33,
        10189.23, 10102.17, 10230.0, 10322.96, 10322.12, 10529.56, 10589.85,
        10623.01, 10828.15, 10764.64, 11036.7, 11119.94, 11074.33, 11024.62,
        11026.69, 11148.86, 11210.86, 11104.5, 11198.88, 11129.17, 11215.6,
        11424.79, 11576.97, 11732.65, 12172.16, 12168.61, 12088.82, 12017.23,
        12046.99, 12028.4, 11512.55, 12008.73, 12082.25, 12090.99, 12116.5,
        12075.65, 12155.98, 11977.01, 11979.71, 12203.26, 12469.95, 12406.79,
        12846.42, 12787.02, 12350.98, 12356.43, 12452.76, 12398.89, 12529.72,
        12521.41, 12539.36, 12523.3, 12452.22, 12576.33, 12523.67, 12429.39,
        12522.29, 12360.48, 12084.08, 12084.59, 12058.97, 12055.83, 12147.26,
        12158.88, 12625.03, 12284.86, 12321.0, 12286.27, 12244.05, 12061.64,
        12053.0, 12051.96, 12050.19, 12015.82, 12003.1, 12044.07, 12197.57,
        12194.79, 12436.13, 12365.6, 12102.41, 12132.24, 12081.44, 12081.07,
        12140.87, 12005.89, 11620.37, 11618.76, 11608.46, 11738.08, 11807.64,
        11801.87, 11769.19, 11665.67, 11546.16, 11558.78, 11320.67, 11350.01,
        11288.51, 11290.82, 11176.35, 11566.44, 11481.07, 11710.49, 11787.36,
        11774.96, 12089.38, 12355.9, 12247.06, 12354.98, 12195.15, 12194.02,
        12138.26, 12222.64, 12184.27, 12130.96, 12046.81, 12046.7, 12047.23,
        11991.18, 12018.73, 12244.23, 12294.28, 12355.07, 12248.27, 12241.87,
        12241.09, 12236.06, 11998.01, 11999.64, 11997.76, 11955.79, 12033.78,
        12129.69, 12007.09, 11912.61, 11921.34, 11831.81, 11838.18, 11784.28,
        11783.29, 11781.6, 11755.73, 11770.93, 12127.79, 11854.89, 11854.97,
        12047.77, 12049.93, 12162.9, 11813.73, 11793.85, 11790.86, 11794.59,
        11516.03, 11725.5, 11720.48, 11798.57, 11802.87, 11757.67, 12052.51,
        12078.62, 12307.05, 12332.7, 12432.45, 12117.55, 12181.34, 12290.76,
        12536.54, 12414.62, 12287.87, 12348.85, 12280.93, 12390.62, 12445.58,
        12352.49, 12352.98, 12364.5, 12380.81, 12210.56, 12187.06, 12185.33,
        12182.6, 12178.9, 12181.44, 12221.48, 12451.13, 12336.37, 12335.29,
        12218.24, 12232.29, 12273.57, 12178.8, 12081.81, 12075.35, 12133.66,
        12047.53, 12011.94, 12008.39, 12037.66, 11962.11, 11994.07, 11965.04,
        11989.89, 11909.55, 11825.65, 11826.09, 11825.93, 11825.84, 11800.64,
        11747.94, 11812.68, 11695.3, 11662.74, 11543.07, 11533.78, 11534.63,
        11535.75, 11578.01, 11585.41, 11572.36, 11640.02, 11690.42, 11641.48,
        11543.53, 11398.15, 11420.23, 11362.13, 11465.18, 11518.1, 11575.8,
        11607.51, 11733.07, 11776.19, 11640.39, 11821.64, 11865.28, 11842.08,
        11912.2
      ]
    }
  }
};
